function isEmpty(obj) {
  if (obj) {
    if (Object.keys(obj).length === 0 ) {
      return true;
    } else return false;
  }
  return true;
}

function isOfType(v) {
  let typeCheck = Object.prototype.toString;
  return typeCheck.call(v);
}
export function GenerateQueryParameterString(obj, isStartOfQueryString = true, isEndOfQueryString = true) {
  if (isEmpty(obj)) {
    return "";
  }
  let arr = Object.keys(obj)
    .filter((item) => {
      if(obj[item] === null || obj[item] === undefined) return "";
      let keyStr = obj[item].toString();
      return keyStr.trim() !== "" && keyStr;
    })
    .map((item) => {
      let keyStr = item.toString();
      if (isOfType(obj[item]) === "[object String]") {
        let checkHowManyCommaSepartedItems = obj[item].split(",");
        if(checkHowManyCommaSepartedItems.length > 1){
          return keyStr + "__in=" + obj[item];
        }
        return keyStr + "=" + obj[item];
      }
      if (isOfType(obj[item]) === "[object Array]") {
        if (obj[item].length > 1) {
          return keyStr + "__in=" + obj[item].join(",");
        }
      }
      return keyStr + "=" + obj[item];
    });

  let queryStartSymbol = isStartOfQueryString === true ? "?" : "&";
  let queryEndSymbol = isEndOfQueryString === true ? "" : "&";

  return arr.length > 0 ? queryStartSymbol + arr.join("&") + queryEndSymbol : "";
}
