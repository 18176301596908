import React from "react";
import { ENTITY_TYPES } from "../../../../../../../../globalConstants";
import Colors from "../../../../../../../global/Colors";
import FormatPhoneNumber from "../../../../../../../global/FormatPhoneNumber";
import NotificationTooltip from "./NotificationTooltip";
import TooltipTitle from "./TooltipTitle";
import TooltipLineItem from "./TooltipLineItem";

export default function NotificationAvatar({ item }) {
  const getBGColor = (senderType) => {
    switch (senderType) {
      case ENTITY_TYPES.CLIENT_CASE_MANAGEMENT:
        return Colors.purple[600];
      case ENTITY_TYPES.LAW_FIRM:
        return Colors.teal[500];
      case ENTITY_TYPES.PROVIDER:
        return Colors.blue[600];
      case ENTITY_TYPES.PAYMENT_PROCESSOR:
        return Colors.orange[500];
      case ENTITY_TYPES.ORGANIZATION:
        return Colors.pink[500];
      default:
        return Colors.gray[400];
    }
  };
  const createInitials = (name) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
    return initials;
  };

  return (
    <NotificationTooltip
      title={
        <div id={`Tooltip-Sender-` + item.id}>
          <TooltipTitle value={item.sender.name} />
          <hr />
          <TooltipLineItem
            label={"Entity:"}
            value={item?.sender?.entity?.name}
          />
          <TooltipLineItem
            label={"Phone:"}
            value={FormatPhoneNumber(item?.sender?.phone, false)}
          />
          <TooltipLineItem label={"Email:"} value={item?.sender?.email} />
        </div>
      }
    >
      <div
        className="flex w-[50px] h-[50px] rounded-[100px] text-white mx-2"
        style={{
          background: getBGColor(item?.sender?.entity?.entity_type),
          ...( item?.sender?.profile_picture ? {backgroundImage:`url(${item?.sender?.profile_picture})`}: {})
        }}
      >
        <span style={{ margin: "auto" }}>
          {createInitials(item?.sender?.name)}{" "}
        </span>
      </div>
    </NotificationTooltip>
  );
}
