import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../../../../api/axios";
import DataContext from "../../../../../../../../context/DataContext";
import ResponseModal from "../../../../../../../global/ResponseModal";
import ConfirmationModal from "../../../../../../../global/Modal/ConfirmationModal";
import { PDStateContext } from "../../../context/ProviderDetailProvider";

const columns = [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <div>{row?.user?.name}</div>,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: false,
    flex: 2,
    renderCell: ({ row }) => <div>{row?.user?.email}</div>,
  },
  {
    field: "phone",
    headerName: "Phone",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <div>{row?.user?.phone}</div>,
  },
  {
    field: "department",
    headerName: "Department",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <div>{row?.user?.department_name}</div>,
  },
];

const initPagination = { page: 0, pageSize: 25 }

const Employees = () => {
  const { accessToken } = useContext(DataContext);
  const { provider } = useContext(PDStateContext);

  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState(initPagination);
  const [response, setResponse] = useState({ message: "", isError: false });
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    const { page, pageSize } = pagination;

    axios
      .get(
        `/api/user_provider/?provider=${provider?.pk}&limit=${pageSize}&offset=${
          page * pageSize
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(({ data }) => {
        setEmployees(data.results || []);
        setTotalCount(data.count || 0);
      })
      .catch(() => {
        setResponse({ message: "Error loading employees", isError: true });
        setShowResponseModal(true);
      })
      .finally(() => setLoading(false));
  }, [accessToken, pagination, provider?.pk, refresh]);

  const generateErrorMessage = (error) => {
    const defaultErrorMessage =
      "There was an error removing users from your team";
    const errorMsg = error?.response?.data?.detail;

    if (errorMsg?.includes("To many records")) {
      return "Too many records. Please reduce your selection to 25 or less.";
    }
    return defaultErrorMessage;
  };

  const handleDeleteEmployees = () => {
    if (selectedRows.length === 0) return;

    axios
      .post("/api/user_provider/bulk_delete/", selectedRows, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponse({
          message: "Employees deleted successfully",
          isError: false,
        });
        setSelectedRows([]);
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        const errorMessage = generateErrorMessage(error);
        setResponse({ message: errorMessage, isError: true });
      })
      .finally(() => {
        setShowResponseModal(true);
        setShowDeleteModal(false);
      });
  };

  return (
    <div
      id="Provider-Details-Info-Employees"
      data-testid="Provider-Details-Info-Employees"
    >
      <div className="px-8 py-2">
        <div className="flex items-center justify-between">
          <span className="text-xl">Employees</span>
          <Button
            variant="contained"
            color="error"
            onClick={() => setShowDeleteModal(true)}
            sx={{ visibility: selectedRows.length > 0 ? "visible" : "hidden" }}
          >
            Delete Employee
          </Button>
        </div>
        <DataGrid
          rows={employees}
          loading={loading}
          columns={columns}
          checkboxSelection
          disableColumnMenu
          disableColumnFilter
          pagination
          paginationMode="server"
          rowCount={totalCount}
          getRowId={(row) => row?.id}
          sx={{ border: 0 }}
          pageSizeOptions={[initPagination.pageSize]}
          localeText={{ noRowsLabel: "No Managed Employees" }}
          onRowSelectionModelChange={(selection) => setSelectedRows(selection)}
          rowSelectionModel={selectedRows}
          keepNonExistentRowsSelected
          onPaginationModelChange={(model) =>
            setPagination({ page: model.page, pageSize: model.pageSize })
          }
          initialState={{
            pagination: {
              paginationModel: initPagination
            }
          }}
        />
      </div>
      <ResponseModal
        title={response.isError ? "Failed" : "Success"}
        isError={response.isError}
        errorMessage={response.message}
        openBool={showResponseModal}
        setOpenBool={setShowResponseModal}
        description={response.message}
      />
      <ConfirmationModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteEmployees}
        title="Confirm Delete"
        description="Are you sure you want to delete the selected employee(s)?"
      />
    </div>
  );
};

export default Employees;
