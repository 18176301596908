import { useContext, useEffect, useState } from "react";

import InlineEditRow from "./InlineEditRow";
import {
  PDDispatchContext,
  PDStateContext,
} from "../../../context/ProviderDetailProvider";
import { setTrigger } from "../../../state/actions";
import axios from "../../../../../../../api/axios";
import DataContext from "../../../../../../../../context/DataContext";
import GlobalInlineEditRow from "../../../../../../../global/InlineEditField/InlineEditRow";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import { Notes } from "./Notes";

export default function InfoContent() {
  const { userRoles, accessToken } = useContext(DataContext);
  const dispatch = useContext(PDDispatchContext);
  const { provider, trigger } = useContext(PDStateContext);

  const [emailTypeOptions, setEmailTypeOptions] = useState([]);

  useEffect(() => {
    if (!userRoles.permissions.includes("utilities.view_fieldoptions")) {
      return;
    } else {
      axios
        .get(
          `/api/field_options/?content_type=provideremail&field_name=email_type`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((resp) => {
          const data = resp.data.results;
          setEmailTypeOptions(data.sort((a, b) => a.order - b.order));
        })
        .catch((error) => console.error("error", error));
    }
  }, [accessToken, setEmailTypeOptions, userRoles.permissions]);

  const handleTrigger = (val) => {
    setTrigger(dispatch, val);
  };

  const getEmailTypeLabel = (type) => {
    switch (type.key) {
      case 1:
        return "Referral Updates";
      case 2:
        return "Appointment Updates";

      default:
        return type.label;
    }
  };

  if (!provider) return null;
  return (
    <div className="flex flex-col px-8 py-6 justify-evenly h-full w-full xl:gap-6">
      <div className="flex h-full w-full xl:gap-6">
        <div className="xl:w-6/12">
          <div id="provider-name" className="w-full">
            <GlobalInlineEditRow
              title="Name"
              type="text"
              keyVal="name"
              apiUrl={`/api/provider/${provider.pk}/`}
              displayNameKey="Name"
              trigger={trigger}
              setTrigger={handleTrigger}
              displayDetail={provider}
              valueLabels="name"
              nullable={false}
            />
          </div>
          <div id="provider-address" className="w-full">
            <h2 className="text-gray-400">Address:</h2>
            <p className="py-[6px] px-2">{provider?.address?.formatted || provider?.address?.raw}</p>
          </div>
          <div id="provider-phone-number" className="w-full">
            <GlobalInlineEditRow
              title="Phone"
              type="text"
              keyVal="phone"
              apiUrl={`/api/provider/${provider.pk}/`}
              displayNameKey="Phone"
              trigger={trigger}
              setTrigger={handleTrigger}
              displayDetail={provider}
              valueLabels="phone"
              nullable={false}
            />
          </div>
          <div id="provider-email" className="w-full">
            <GlobalInlineEditRow
              title="Email"
              type="text"
              keyVal="email"
              displayNameKey="Email"
              apiUrl={`/api/provider/${provider.pk}/`}
              trigger={trigger}
              setTrigger={handleTrigger}
              displayDetail={provider}
              valueLabels="email"
              nullable={false}
            />
          </div>
          <div id="provider-website" className="w-full">
            <GlobalInlineEditRow
              title="Homepage"
              type="text"
              keyVal="homepage"
              displayNameKey="Homepage"
              apiUrl={`/api/provider/${provider.pk}/`}
              trigger={trigger}
              setTrigger={handleTrigger}
              displayDetail={provider}
              valueLabels="homepage"
              nullable={false}
            />
          </div>
        </div>
        <PermissionWrapper
          permission={
            userRoles.permissions.includes("provider.view_provideremail") &&
            !!emailTypeOptions?.length
          }
        >
          <div className="xl:w-6/12">
            <h2 className="text-xl font-bold">Additional Emails</h2>
            <div className="w-full">
              {emailTypeOptions.map((type) => {
                let detail = {
                  provider: provider.pk,
                  email_type: type.key,
                  email: "",
                };

                const emailType = provider?.emails.find(
                  (i) => i.email_type === type.key
                );
                if (emailType) {
                  detail = { email_type: type.key, email: emailType.email };
                }
                return (
                  <InlineEditRow
                    key={type.key}
                    title={getEmailTypeLabel(type)}
                    type="text"
                    keyVal="email"
                    displayNameKey={type.label}
                    apiUrl={`/api/provider_email/${emailType?.id}/`}
                    postApiUrl="/api/provider_email/"
                    deleteApiUrl={`/api/provider_email/${emailType?.id}/`}
                    trigger={trigger}
                    setTrigger={handleTrigger}
                    displayDetail={detail}
                    valueLabels="email"
                    nullable={false}
                    disabled={
                      !userRoles.permissions.includes(
                        "provider.add_provideremail"
                      ) &&
                      !userRoles.permissions.includes(
                        "provider.change_provideremail"
                      ) &&
                      !userRoles.permissions.includes(
                        "provider.delete_provideremail"
                      )
                    }
                    isPostRequest={!emailType}
                    isCanDelete={
                      userRoles.permissions.includes(
                        "provider.delete_provideremail"
                      ) && !!emailType
                    }
                  />
                );
              })}
            </div>
          </div>
        </PermissionWrapper>
      </div>
      <Notes />
    </div>
  );
}
