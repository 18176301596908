const OutOfNetworkIcon = ({strokeColor = "black"}) => {
  return (
    <div>
      <svg
        width="29"
        height="26"
        viewBox="0 0 29 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.3102 21.003L16.6518 2.35081C16.4194 1.94074 16.0824 1.59965 15.6752 1.36234C15.2679 1.12503 14.805 1 14.3336 1C13.8623 1 13.3994 1.12503 12.9921 1.36234C12.5849 1.59965 12.2478 1.94074 12.0154 2.35081L1.35705 21.003C1.12214 21.4098 0.998966 21.8715 1.00001 22.3413C1.00105 22.8111 1.12627 23.2722 1.36297 23.678C1.59968 24.0838 1.93946 24.4198 2.34788 24.6519C2.75629 24.8841 3.21881 25.0041 3.68857 24.9999H25.0054C25.4729 24.9994 25.932 24.8759 26.3367 24.6419C26.7414 24.4078 27.0774 24.0714 27.3109 23.6664C27.5445 23.2614 27.6673 22.8021 27.6672 22.3346C27.6671 21.8671 27.544 21.4079 27.3102 21.003Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3467 9.01172V14.3409"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3467 19.6699H14.3613"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default OutOfNetworkIcon;
