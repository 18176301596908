import { createContext, useReducer } from "react";
import { initialState, eventsReducer } from "../state/eventsReducer";

export const EventsDispatchContext = createContext();
export const EventsStateContext = createContext();

const EventsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(eventsReducer, initialState);

  return (
    <EventsDispatchContext.Provider value={dispatch}>
      <EventsStateContext.Provider value={state}>
        {children}
      </EventsStateContext.Provider>
    </EventsDispatchContext.Provider>
  );
};



export default EventsProvider;
