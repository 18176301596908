import {Button, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {format, parseISO} from 'date-fns';
import axios from "../../../../../../../../api/axios";
import {DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF} from '@mui/x-data-grid';
import ConfirmationModal from "../../../../../../../../global/Modal/ConfirmationModal";
import AddUnavailabilityModal from "./AddUnavailabilityModal";
import ResponseModal from "../../../../../../../../global/ResponseModal";
import DataContext from "../../../../../../../../../context/DataContext";

const columns = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderHeader: () => <></>,
    sortable: false,
  },
  {
    field: 'start_date',
    renderHeader: () => <Typography className={'font-bold'}>{'First Day Unavailable'}</Typography>,
    valueFormatter: params => format(parseISO(params), "MM/dd/yyyy"),
    sortable: false,
    flex: 1
  },
  {
    field: 'end_date',
    renderHeader: () => <Typography className={'font-bold'}>{'Last Day Unavailable'}</Typography>,
    valueFormatter: params => params == null ? "-" : format(parseISO(params), "MM/dd/yyyy"),
    sortable: false,
    flex: 1
  }
];

const Unavailability = ({userId}) => {
  const {accessToken} = useContext(DataContext);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [unavailabilityData, setUnavailabilityData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [responseState, setResponseState] = useState({
    message: "",
    isError: false
  });

  useEffect(() => {
    const fetchUnavailabilityData = async () => {
      if (!userId) return;

      setLoading(true);
      const request1 = axios.get(`/api/user_unavailability/?end_date__isnull=true&user=${userId}`, {
        headers: {Authorization: `Token ${accessToken}`},
      });
      const request2 = axios.get(`/api/user_unavailability/?user=${userId}&end_date__gte=${format(new Date(), 'yyyy-MM-dd')}`, {
        headers: {Authorization: `Token ${accessToken}`},
      });

      Promise.all([request1, request2])
        .then(([response1, response2]) => {

          const combinedData = [...response1.data.results, ...response2.data.results];
          setUnavailabilityData(combinedData);
        })
        .catch(() => {
          setResponseState({
            message: "Failed to load unavailability data.",
            isError: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchUnavailabilityData();
  }, [userId, accessToken, refreshKey]);

  const handleBulkDelete = () => {
    setLoading(true);
    axios.post('/api/user_unavailability/bulk_delete/', rowSelectionModel, {
      headers: {Authorization: `Token ${accessToken}`},
    })
      .then(() => {
        setResponseState({
          message: "Unavailability deleted successfully.",
          isError: false,
        });
        setRowSelectionModel([]);
        setShowConfirmDeleteModal(false);
        setRefreshKey(prevKey => prevKey + 1);
        return true;
      })
      .catch((error) => {
        setResponseState({
          message: "Failed to delete unavailability.",
          isError: true,
        });
        return false;
      })
      .finally(() => {
        setLoading(false);
        setIsResponseModalVisible(true);
      });
  }

  return <div
    id="Unavailability-Organization"
    data-testid="Unavailability-Organization"
    className="px-6 py-4"
  >
    <div className="flex items-center">
      <div className="w-[50%]">
        <span className="text-xl">Unavailability</span>
      </div>
      <div className="w-[50%] flex justify-end">
        {rowSelectionModel.length > 0 && (<Button
          className="m-2 capitalize"
          variant="contained"
          color="error"
          onClick={() => setShowConfirmDeleteModal(true)}
        >
          Delete
        </Button>)}
        <Button
          className="m-2 capitalize"
          variant="outlined"
          onClick={() => setShowDialog(true)}
        >
          Add Unavailability
        </Button>
      </div>
    </div>
    <DataGrid
      rows={unavailabilityData}
      loading={isLoading}
      rowHeight={50}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      hideFooterPagination
      checkboxSelection
      localeText={{noRowsLabel: "No Unavailability for user"}}
      sx={{
          border: 0,
          '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-cell:focus': {
              outline: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
      }}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
      }
    />


    {showDialog && (
      <AddUnavailabilityModal
        userId={userId}
        handleClose={() => {
          setShowDialog(false);
          setRefreshKey(prevKey => prevKey + 1);
        }}
        setIsResponseModalVisible={setIsResponseModalVisible}
        setResponseState={setResponseState}
      />)}

    <ConfirmationModal
      open={showConfirmDeleteModal}
      onClose={() => setShowConfirmDeleteModal(false)}
      onConfirm={handleBulkDelete}
      title="Confirm Delete"
      description="Are you sure you want to delete the selected unavailability?"
    />

    <ResponseModal
      title={responseState.isError ? "Failed" : "Successful"}
      isError={responseState.isError}
      description={responseState.message}
      errorMessage={responseState.message}
      openBool={isResponseModalVisible}
      setOpenBool={setIsResponseModalVisible}

    />
  </div>
};

export default Unavailability;
