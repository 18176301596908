import {
  FETCH_NOTIFICATIONS_DONE,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_NOTIFICATIONS_IN_PROGRESS,
  FETCH_NOTIFICATIONS_IS_LOADING,
  FETCH_NOTIFICATIONS_SUCCESS,
  SHOULD_FETCH_NOTIFICATIONS,
  SET_NOTIFICATION_COUNT,
} from "./actions";

export const initialState = {
  shouldFetchNotifications:true,
  loading: false,
  success: null,
  error: null,
  errorArray: [],
  notifications: {
    count:0,
    data:null,
  },
  announcements: null,
};

export function eventsReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_NOTIFICATIONS: {
      return { ...state, shouldFetchNotifications: action.payload };
    }
    case SET_NOTIFICATION_COUNT: {
      return { ...state, notifications: {
        ...state.notifications, count: action.payload} };
    }
    case FETCH_NOTIFICATIONS_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_NOTIFICATIONS_IN_PROGRESS: {
      return { ...state, notifications: action.payload };
    }
    case FETCH_NOTIFICATIONS_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: [] };
    }
    case FETCH_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        success: null,
        error: true,
        errorArray: action.payload,
      };
    }
    case FETCH_NOTIFICATIONS_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    default:
      return state;
  }
}
