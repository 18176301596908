const baseStyles = {
  borderRadius: "5px",
  // border: "1px solid",
  // borderColor: "#505050",
  backgroundColor: "#fff",
  color: "#505050",
  fontSize: "14px",
  lineHeight: "38px",
  height: "38px",
  textAlign: "center",
  // "&:hover": {
  //   borderColor: "#999",
  // },
  "& fieldset": {
    borderColor: "#505050",
  },
  // "&:hover fieldset": {
  //   borderColor: "#B2BAC2",
  // },
  // "&.Mui-focused fieldset": {
  //   borderColor: "#6F7E8C",
  // },
  // "& label.Mui-focused": {
  //   color: "#A0AAB4",
  // },
  // "&.Mui-disabled": {
  //   backgroundColor: "#f5f5f5",
  //   color: "#999",
  //   borderColor: "#ddd",
  // },
  "& input": {
    padding: "0 !important",
  },
};

export const inputStyles = {
  "& .MuiOutlinedInput-root": {
    ...baseStyles,
    padding: "11px 8px",
  },
};

export const selectStyles = {
  ...baseStyles,
  "& .MuiOutlinedInput-input": {
    padding: "11px 8px",
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
  },
};

export const autoCompleteStyles = {
  "& .MuiOutlinedInput-root": {
    ...baseStyles,
    paddingLeft: "8px",
  },
};

export const dateTimeStyles = {
  ...inputStyles,
  "& input": {
    marginLeft: "-8px",
  },
};

export const MAX_TYPE_PER_PROVIDER = 5;
