import { Cancel, Check, Close, Delete, Edit } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "../../../../../../../api/axios";
import DynamicInput from "../../../../../../../global/InlineEditField/DynamicInput";
import DataContext from "../../../../../../../../context/DataContext";
import { truncate } from "../../../../../../../global/helpers";
import ApiRequestErrorHandler from "../../../../../../../global/ApiRequestErrorHandler";
import ModalShell from "../../../../../../../global/ModalShell";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";

InlineEditRow.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  keyVal: PropTypes.string,
  apiUrl: PropTypes.string,
  postApiUrl: PropTypes.string,
  deleteApiUrl: PropTypes.string,
  displayNameKey: PropTypes.string,
  trigger: PropTypes.bool,
  setTrigger: PropTypes.func,
  displayDetail: PropTypes.object,
  valueLabels: PropTypes.string,
  disabled: PropTypes.bool,
  nullable: PropTypes.bool,
  isPostRequest: PropTypes.bool,
  isCanDelete: PropTypes.bool,
};

export default function InlineEditRow({
  title,
  type,
  keyVal,
  apiUrl,
  postApiUrl,
  deleteApiUrl,
  displayNameKey,
  trigger,
  setTrigger,
  displayDetail,
  valueLabels,
  disabled,
  nullable,
  isPostRequest,
  isCanDelete,
}) {
  const { accessToken } = useContext(DataContext);
  const [val, setVal] = useState("");
  const [open, setOpen] = useState(false);
  const [hoverActive, setHoverActive] = useState(false);
  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(25);
  const [activeEditObj, setActiveEditObj] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    const value = displayDetail[keyVal];
    if (value !== "" && value !== null && valueLabels) {
      setVal(value);
      let result = "";
      if (valueLabels[keyVal] && displayNameKey && value) {
        result = { [displayNameKey]: valueLabels[keyVal][val] };
      } else if (valueLabels[keyVal] && value) {
        result = { label: valueLabels[keyVal][val] };
      }
      setActiveEditObj(result);
    } else {
      setVal("");
      setActiveEditObj("");
    }
    // eslint-disable-next-line
  }, [displayDetail, valueLabels, keyVal, trigger]);

  const handleApiError = (error) => {
    setLoading(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr[0]);
    setResponseSuccess(false);
  };

  const handleApiSuccess = (response, valueName, msg = "") => {
    setLoading(false);
    const res = response;
    setTrigger(!trigger);
    setResponseBreakdown(msg || `${valueName} was successfully updated`);
    setResponseSuccess(true);
    setOpen(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  const handlePostRequest = (postData) => {
    axios
      .post(
        postApiUrl,
        { ...displayDetail, ...postData },
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        handleApiSuccess(response, title);
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const submitData = (title) => {
    const ids = typeof val[0] === "object" ? val.map(({ pk }) => pk) : [];

    const postVal = val === "No Selection" || val === "" ? ids : val;
    const postData = { [keyVal]: postVal };

    if (isPostRequest) {
      handlePostRequest(postData);
      return;
    }

    axios
      .patch(
        apiUrl,
        { ...displayDetail, ...postData },
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        handleApiSuccess(response, title);
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const deleteExistingEmail = () => {
    axios
      .delete(deleteApiUrl, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setConfirmDelete(false);
        handleApiSuccess(response, title, "Provider Email Deleted");
      })
      .catch((error) => {
        setConfirmDelete(false);
        handleApiError(error);
      });
  };

  const cancelEdit = () => {
    const value = displayDetail[keyVal];

    setVal(value || "");
    setOpen(false);
    setResponseBreakdown("");
    setResponseSuccess(null);
  };

  const typeDisplayFormat = (value) => {
    if (value === "" || value === null) {
      return "Add Value";
    } else if (typeof val === "object") {
      return "Add Value";
    } else {
      return truncate(value, 30);
    }
  };

  return (
    <>
      {open ? (
        <div className="w-full mb-2">
          <div className="flex items-center w-full justify-between mt-2">
            <div className="w-[70%] self-center">
              <DynamicInput
                setVal={setVal}
                val={val}
                title={title}
                type={type}
                keyVal={keyVal}
                caseDetail={displayDetail}
                activeEditObj={activeEditObj}
                setSearch={setSearch}
                search={search}
                responseSuccess={responseSuccess}
                responseBreakdown={responseBreakdown}
                displayNameKey={displayNameKey}
                setResLimit={setResLimit}
                resLimit={resLimit}
                nullable={nullable}
              />
            </div>
            {loading && <CircularProgress color="secondary" />}
            {!loading && responseSuccess === false && (
              <Cancel className="text-red-600" />
            )}
            {responseSuccess ? (
              ""
            ) : (
              <div className="flex flex-wrap justify-end w-[30%]">
                <Tooltip title="Submit">
                  <IconButton
                    data-test-id="submit-data-button"
                    className="text-green-600"
                    onClick={() => submitData(title)}
                    size="small"
                  >
                    <Check className="text-[20px]" />
                  </IconButton>
                </Tooltip>
                <PermissionWrapper permission={isCanDelete}>
                  <Tooltip title="Delete email">
                    <IconButton
                      data-test-id="delete-button"
                      onClick={() => setConfirmDelete(true)}
                      className="text-red-600"
                      size="small"
                    >
                      <Delete className="text-[20px]" />
                    </IconButton>
                  </Tooltip>
                </PermissionWrapper>
                <Tooltip title="Cancel">
                  <IconButton
                    data-test-id="cancel-button"
                    className="text-red-600"
                    onClick={() => cancelEdit()}
                    size="small"
                  >
                    <Close className="text-[20px]" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          {responseSuccess === false && responseBreakdown ? (
            <p className="text-red-600 text-[10px]">{responseBreakdown}</p>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          <div>
            <h2 className="text-gray-400">{title}: </h2>
            <Tooltip title={disabled ? "" : "Click to edit"}>
              <span>
                <Button
                  data-test-id="edit-button"
                  onClick={() => {
                    setOpen(true);
                    setHoverActive(false);
                  }}
                  disabled={disabled}
                  className="text-black hover:bg-gray-200 text-left break-all normal-case"
                  onMouseEnter={() => setHoverActive(true)}
                  onMouseLeave={() => setHoverActive(false)}
                  sx={{ "&.Mui-disabled": { color: "#000" } }}
                >
                  <span className="mr-1">{typeDisplayFormat(val)}</span>
                  <span className="w-[20px] h-[20px]">
                    {hoverActive ? <Edit className="text-gray-400" /> : ""}
                  </span>
                </Button>
              </span>
            </Tooltip>
          </div>
          <p className="text-[12px]">
            {responseBreakdown}
            {responseSuccess ? <Check className="ml-1 text-green-600" /> : ""}
          </p>
        </div>
      )}
      <ModalShell
        open={confirmDelete}
        setOpen={setConfirmDelete}
        color={"red"}
        title="Confirm email removal"
      >
        <div>
          <p className="text-center">
            Are you sure you want to remove this email?
          </p>
          <p className="text-center font-bold">
            <span className="font-bold">{title}</span>: {val}
          </p>
          <div className="w-fit mx-auto my-2">
            <Button
              onClick={() => deleteExistingEmail()}
              endIcon={<Delete />}
              variant="outlined"
              className="text-red-600 border-red-600"
              disabled={loading}
            >
              Confirm Delete
            </Button>
          </div>
        </div>
      </ModalShell>
    </>
  );
}
