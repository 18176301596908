import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UnauthAccess from "../../ErrorPage";
import DataContext from "../../context/DataContext";
import { NetworkBackground } from "../NetworkBackground";
import axios from "../api/axios";
import PatchUpdateDialog from "../global/PatchUpdateDialog";
import "./App.css";
import SideBar from "./SideBar";
import useForceLogout from "../hooks/forceLogout";
import FreshDeskWidget from "../global/FreshDeskWidget";
import { useLocation } from "react-router-dom";
import { Userpilot } from "userpilot";
import { Banner } from "./Banner";
import {
  EventsDispatchContext,
} from "../../context/EventsProvider";
import { setNotificationCount } from "../../state/actions";

export default function App() {
  const {
    accessToken,
    getAccessToken,
    setUserType,
    setCaseManagerPk,
    setRefreshDialog,
    needsUpdate,
    setActiveLawfirm,
    setLawFirmLink,
    setUserRoles,
    setErrorMessage,
    casePk,
    userRoles,
    setPatchNotifs,
    patchNotifs,
    loggedInUser,
    setLoggedInUser,
    setLanguageObj,
  } = useContext(DataContext);

  const [delay, setDelay] = useState(true);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const globalEventDispatch = useContext(EventsDispatchContext);

  useEffect(()=>{
    if(!localStorage.getItem('notificationCount')) {
      localStorage.setItem('notificationCount', 0);
    }else{
      setNotificationCount(globalEventDispatch, localStorage.getItem('notificationCount'));
    }
  },[]);

  const startFetchingNotifications = () => {
    navigator.serviceWorker.getRegistration().then(registration => {
      registration.active.postMessage({
        type: 'START_FETCHING',
        accessToken: accessToken,
        baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://api.netproapp.com",
      });
    });
  }

  const updateNotificationCount = () => {
      navigator.serviceWorker.addEventListener('message', (event) => {
        const { type, count } = event.data;
        if (type === "UPDATE_COUNT") {
          localStorage.setItem('notificationCount', count);
          setNotificationCount(globalEventDispatch, localStorage.getItem('notificationCount'));
        }
      });
  }

  const stopFetchingNotifications = () => {
    navigator.serviceWorker.getRegistration().then(registration => {
      registration.active.postMessage({
        type: 'STOP_FETCHING',
      });
    });
  }

  useEffect(() => {
      startFetchingNotifications()
      updateNotificationCount();
  },[startFetchingNotifications, updateNotificationCount, globalEventDispatch]);


  //*This will be used to trigger a force logout and cookie clear when someone's token is denied or when it expires
  useForceLogout(isError);

  useEffect(() => {
    if (loggedInUser) {
      Userpilot.identify(loggedInUser.pk, {
        name: loggedInUser.name,
        email: !loggedInUser.email ? "" : loggedInUser.email,
        created_at: loggedInUser.date_joined,
        company: {
          id: loggedInUser.entity?.pk ? loggedInUser.entity?.pk : "",
          name: loggedInUser.entity?.name ? loggedInUser.entity?.name : "",
        },
        active: loggedInUser.is_active,
      });
    } else {
      return;
    }
  }, [loggedInUser, accessToken]);

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  useEffect(() => {
    if (!accessToken) {
      getAllCookies();
      getUserPerms();
      getDashboardV();
      setTimeout(() => {
        setDelay(false);
      }, "2000");
    } else {
      getLanguageObject();
      getUserPerms();
      getDashboardV();
      setTimeout(() => {
        setDelay(false);
      }, "2000");
    }
    // eslint-disable-next-line
  }, [accessToken]);

  const getAllCookies = () => {
    if (!Cookies.get("pnAccessToken")) {
      navigate("/");
      setErrorMessage("You are not logged in, please log in to continue");
    } else {
      getAccessToken(JSON.parse(Cookies.get("pnAccessToken")));
      setCaseManagerPk(JSON.parse(Cookies.get("pnCmPk")));
      setUserType(Cookies.get("userType"));
      getDashboardV();
      getLanguageObject();
      setTimeout(() => {
        setDelay(false);
      }, "2000");
    }
  };

  const getDashboardV = () => {
    if (!Cookies.get("dashVersion")) {
      return;
    } else {
      Cookies.remove("dashVersion");
    }
  };
  const getApiUrl = (apiUrl) => {
    //! Need to rework or get rid of this when there's time. it's only being used to determine if we need to send new client email or not. easily can move this into the places where emailJS is.
    if (apiUrl === "https://dev-api.netproapp.com") {
      setActiveLawfirm("DEV TEST");
      setLawFirmLink("https://www.netproapp.com/");
      console.log("Dev API");
    } else if (apiUrl === "https://staging-api.netproapp.com") {
      setActiveLawfirm("STAGING TEST");
      setLawFirmLink("https://www.netproapp.com/");
      console.log("Dev API");
    } else if (apiUrl === "https://api.netproapp.com") {
      setActiveLawfirm("PRODUCTION");
      setLawFirmLink("https://app.netproapp.com/");
      console.log("Production Loaded");
    } else {
      console.log("No API URL Found");
      setActiveLawfirm("Unknown");
      setLawFirmLink("#");
    }
  };

  const getUserPerms = () => {
    axios
      .get(`/api/users/me/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        getApiUrl(response.config.baseURL);
        setLoggedInUser(response.data);
        setUserRoles({
          groups: response.data.groups,
          permissions: response.data.permissions,
        });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setIsError(true);
        }
      });
  };

  const compareAppVersion = () => {
    if (!userRoles || userRoles.length === 0 || patchNotifs) {
      return;
    } else if (
      userRoles.permissions.includes("utilities.view_softwareupdate")
    ) {
      axios
        .get(`/api/utilities/new_versions/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setPatchNotifs(data);
          if (!data[0]) {
            Cookies.set("patchStatus", "");
          } else if (!Cookies.get("patchStatus")) {
            Cookies.set("patchStatus", data[0].version_hash);
          } else if (data[0].version_hash !== Cookies.get("patchStatus")) {
            Cookies.set("patchStatus", data[0].version_hash);
            
            setTimeout(() => {
              localStorage.remove("case_search");
              window.location.reload();
            }, "1500");
            

          }
        });
    } else {
      return;
    }
  };

  const getLanguageObject = () => {
    if (
      !userRoles ||
      !userRoles.permissions.includes("utilities.view_language")
    ) {
      return;
    } else {
      axios
        .get(`/api/utilities/language/?ordering=created_at`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let obj = {};

          for (const item in data) {
            obj[data[item].pk] = data[item];
          }
          setLanguageObj(obj);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    }
  };

  useEffect(() => {
    compareAppVersion();
    // eslint-disable-next-line
  }, [accessToken, userRoles, patchNotifs, location]);

  if (accessToken) {
    return delay ? (
      <div className="h-screen w-screen flex items-center justify-center">
        <NetworkBackground />
        <div className="p-12 rounded-md shadow-md bg-white flex flex-col justify-center items-center">
          <h1 className="text-lg font-medium">
            {accessToken ? "Page Loading" : "Logging in"}
          </h1>
          <br />
          <CircularProgress color="secondary" className="" />
        </div>
      </div>
    ) : (
      <div className="flex xl:overflow-hidden min-w-[60%] bg-[#f0f0f0]">
        <div className="">
          <SideBar />
        </div>
        <div className="xl:h-screen w-screen sm:ml-16 sm:mt-0 mt-16 overflow-y-auto flex flex-col h-screen">
          {/* Banner */}
          <Banner />

          {/* Main */}
          <div className="p-[15px] bg-[#f0f0f0] flex flex-col flex-1">
            <Outlet />
          </div>
        </div>
        <PatchUpdateDialog />
        {needsUpdate ? (
          <div className="block absolute rounded-full bg-red-600 top-[10px] right-[10px] ">
            <Tooltip title="App Needs Update, please refresh">
              <IconButton
                onClick={() => setRefreshDialog(true)}
                className="text-white"
              >
                <ReportProblemIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
        <FreshDeskWidget
          userName={loggedInUser.name}
          email={loggedInUser.email}
          url={window.location.href}
          casePk={casePk}
        />
      </div>
    );
  }

  return <UnauthAccess status={401} />;
}
