import React, { useContext, useEffect, useState } from "react";
import ProvRating from "../../../components/Rating";
import {
  PDDispatchContext,
  PDStateContext,
} from "../../../context/ProviderDetailProvider";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import { ENTITY_TYPE_PROVIDER } from "../../../../../../../../globalConstants";
import DataContext from "../../../../../../../../context/DataContext";
import { provider as apiClient } from "../../../../../../../api/endpoints/provider";
import InlineEditRow from "../../../../../../../global/InlineEditField/InlineEditRow";
import { parseISO, format } from "date-fns";
import { setTrigger } from "../../../state/actions";

const NetworkSettings = () => {
  const { provider, trigger } = useContext(PDStateContext);
  const { loggedInUser, userRoles, accessToken } = useContext(DataContext);
  const [networkStatusHistory, setNetworkStatusHistory] = useState(null);
  const [receivingClientsHistory, setReceivingClientsHistory] = useState(null);
  const [acceptsFundingHistory, setAcceptsFundingHistory] = useState(null);
  const dispatch = useContext(PDDispatchContext);

  const handleTrigger = (val) => {
    setTrigger(dispatch, val);
  };

  const isProvider =
    loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;
  const ratingPerm =
    !isProvider && userRoles.permissions.includes("provider.view_provider");

  useEffect(() => {
    const fetchProviderStatusHistory = async () => {
      try {
        const [networkStatusHistory, receivingClients, acceptsFunding] =
          await Promise.all([
            apiClient.getProviderStatusHistory(
              accessToken,
              provider?.pk,
              "in_network"
            ),
            apiClient.getProviderStatusHistory(
              accessToken,
              provider?.pk,
              "receiving_clients"
            ),
            apiClient.getProviderStatusHistory(
              accessToken,
              provider?.pk,
              "accepts_funding"
            ),
          ]);

        setNetworkStatusHistory(networkStatusHistory.results?.[0] || null);
        setReceivingClientsHistory(receivingClients.results?.[0] || null);
        setAcceptsFundingHistory(acceptsFunding.results?.[0] || null);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProviderStatusHistory();
  }, [accessToken, provider?.pk, trigger]);

  const formatDate = (date) => {
    if (!date) return "N/A";
    return format(parseISO(date), "MM/dd/yyyy");
  };

  return (
    <div className="px-8 py-6">
      <div className="w-1/2 flex flex-col xl:gap-5">
        <PermissionWrapper permission={ratingPerm}>
          <div className="flex items-center gap-1">
            <p className="text-gray-400">Rating:</p>
            <ProvRating starStyle={"text-lg"} />
          </div>
        </PermissionWrapper>
        <div id="nation-provider-id" className="w-full">
          <InlineEditRow
            title="National Provider ID (NPI)"
            type="text"
            keyVal="npi"
            displayNameKey="NPI"
            apiUrl={`/api/provider/${provider.pk}/`}
            displayDetail={provider}
            valueLabels="npi"
            nullable={false}
            trigger={trigger}
            setTrigger={handleTrigger}
          />
        </div>
        <div id="net-promoter-score" className="w-full">
          <InlineEditRow
            title="Net Promoter Score (NPS)"
            type="text"
            keyVal="nps"
            displayNameKey="NPS"
            apiUrl={`/api/provider/${provider.pk}/`}
            displayDetail={provider}
            valueLabels="nps"
            nullable={false}
            trigger={trigger}
            setTrigger={handleTrigger}
          />
        </div>
        <div id="network-status" className="w-full">
          <InlineEditRow
            title="Network Status"
            type="boolean"
            keyVal="in_network"
            displayNameKey="In_Network"
            apiUrl={`/api/provider/${provider.pk}/`}
            displayDetail={provider}
            valueLabels="in_network"
            nullable={false}
            trigger={trigger}
            setTrigger={handleTrigger}
            booleanLabels={{ true: "In-Network", false: "Out-of-Network" }}
          />
          <div className="text-sm ml-1 flex items-center gap-2">
            <span className="font-medium">Start Date:</span>{" "}
            {formatDate(networkStatusHistory?.start_date)}
            <span className="font-medium">End Date:</span>{" "}
            {formatDate(networkStatusHistory?.end_date)}
          </div>
        </div>
        <div id="receiving-clients" className="w-full">
          <InlineEditRow
            title="Receiving Clients"
            type="boolean"
            keyVal="receiving_clients"
            displayNameKey="receiving_clients"
            apiUrl={`/api/provider/${provider.pk}/`}
            displayDetail={provider}
            valueLabels="receiving_clients"
            nullable={false}
            trigger={trigger}
            setTrigger={handleTrigger}
          />
          <div className="text-sm ml-1 flex items-center gap-2">
            <span className="font-medium">Start Date:</span>{" "}
            {formatDate(receivingClientsHistory?.start_date)}
            <span className="font-medium">End Date:</span>{" "}
            {formatDate(receivingClientsHistory?.end_date)}
          </div>
        </div>
        <div id="accepts-funding" className="w-full">
          <InlineEditRow
            title="Accepts Funding"
            type="boolean"
            keyVal="accepts_funding"
            displayNameKey="accepts_funding"
            apiUrl={`/api/provider/${provider.pk}/`}
            displayDetail={provider}
            valueLabels="accepts_funding"
            nullable={false}
            trigger={trigger}
            setTrigger={handleTrigger}
          />
          <div className="text-sm ml-1 flex items-center gap-2">
            <span className="font-medium">Start Date:</span>{" "}
            {formatDate(acceptsFundingHistory?.start_date)}
            <span className="font-medium">End Date:</span>{" "}
            {formatDate(acceptsFundingHistory?.end_date)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkSettings;
