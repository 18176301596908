import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useContext } from "react";
import {
  NotificationPageDispatchContext,
  NotificationPageStateContext,
} from "../../context/NotificationsPageProvider";
import {
  setGetReadUnReadButtonToggle,
  setGetReadUnReadToggle,
  setShouldFetchNotifications,
} from "../../state/actions";

export function NotificationReadOnlyToggle() {
  const notifyPageState = useContext(NotificationPageStateContext);
  const notifyPageDispatch = useContext(NotificationPageDispatchContext);

  const handleChange = (event) => {
    setGetReadUnReadButtonToggle(notifyPageDispatch, event.target.checked);

    if (notifyPageState.notifications.api.filters.isRead === 1) {
      setGetReadUnReadToggle(notifyPageDispatch, 2);
    } else {
      setGetReadUnReadToggle(notifyPageDispatch, 1);
    }
     setShouldFetchNotifications(notifyPageDispatch, true);
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={notifyPageState.notifications.ui.isReadButton}
            onChange={handleChange}
          />
        }
        label="Only Show Unread"
        labelPlacement="start"
      />
    </FormGroup>
  );
}
