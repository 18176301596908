import { InputAdornment, TextField } from "@mui/material";
import Title from "../../components/Title";
import Colors from "../../../../../../../global/Colors";
import { useContext, useEffect, useState } from "react";
import { ProviderListStateContext } from "../context/ProviderListProvider";
import { TEXTFIELD_TYPES } from "./CustomTextFieldTypes";

export default function CustomTextField({
  index,
  title,
  subTitle,
  defaultValue,
  placeholder,
  width = "w-full",
  disabled = false,
  updateProviderInfo,
  fieldName,
  activateButtons,
  deactivateButtons,
  validatorFunction = null,
  type = "",
}) {
  const providerListState = useContext(ProviderListStateContext);
  const [initialValue, setInitialValue] = useState(defaultValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setInitialValue(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (validatorFunction !== null) {
      if (!validatorFunction(value, setValue, setError, setHelperText)) {
        deactivateButtons(true, false);
        return;
      }
    } else {
      setValue(event.target.value);
    }
    const existingFields =
      providerListState.providersData.get(index).editableFields;
    let editableData = null;
    if (value == initialValue) {
      if (existingFields && Object.keys(existingFields).length > 1) {
        editableData = {
          ...existingFields,
        };
        delete editableData[fieldName];
      }
      const updatedData = new Map(providerListState.providersData);
      updatedData.set(index, {
        ...updatedData.get(index),
        editableFields: editableData,
      });
      updateProviderInfo(updatedData);
      return;
    }
    activateButtons();
    if (
      (value && value.trim()) ||
      (existingFields &&
        (Object.keys(existingFields).length !== 0 ||
          value !== existingFields[fieldName]))
    ) {
      editableData = {
        ...existingFields,
        [fieldName]: value,
      };
    }
    const updatedData = new Map(providerListState.providersData);
    updatedData.set(index, {
      ...updatedData.get(index),
      editableFields: editableData,
    });

    updateProviderInfo(updatedData);
  };

  return (
    <div className="flex flex-col justify-start items-start pr-2 w-full">
      <div>
        <Title
          text={title}
          size={"text-xs"}
          horizontalPadding="pl-2"
          className="w-full block"
        />
        {subTitle && (
          <Title
            text={"(" + subTitle + ")"}
            size={"text-[0.65rem]"}
            color="text-gray-400"
            fontStyle="normal-case"
            horizontalPadding="pl-2"
          />
        )}
      </div>

      <TextField
        error={error}
        disabled={disabled}
        defaultValue={defaultValue !== null ? defaultValue : ""}
        placeholder={defaultValue === "" ? placeholder : ""}
        size="small"
        className={`text-sm ${width} pt-1 px-0`}
        InputProps={{
          startAdornment: type === TEXTFIELD_TYPES.CURRENCY && (
            <InputAdornment position="start">$</InputAdornment>
          ),
          sx: {
            lineHeight: "1rem",
            borderRadius: "50px",
            backgroundColor: disabled ? Colors.gray[200] : Colors.white,
          },
        }}
        helperText={helperText}
        onChange={handleChange}
        value={value}
        type={type === TEXTFIELD_TYPES.CURRENCY && "number"}

      />
    </div>
  );
}
