import React, { useEffect, useRef, useState } from "react";
import { ControlPoint } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button } from "@mui/material";
import RescheduleComponent from "./RescheduleComponent";
import ResponseModal from "../../../global/ResponseModal";
import axios from "../../../api/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';

const RescheduleAppointment = ({ id }) => {
  const [formEntries, setFormEntries] = useState([1]);
  const inputRefs = useRef([]);
  const containerRef = useRef(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [params] = useSearchParams();
  const token = params.get("temporary_token");
  const navigate = useNavigate();

  const addAppointment = () => {
    setFormEntries((prev) => {
      const lastElm = prev.at(-1);
      return [...prev, lastElm + 1];
    });
  };

  const handleDelete = (id) => {
    const deleteIdx = formEntries.findIndex((idEntry) => idEntry === id);
    const newFormEntries = [...formEntries];
    newFormEntries.splice(deleteIdx, 1);
    setFormEntries(newFormEntries);
    inputRefs.current.splice(deleteIdx, 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const dataInput = inputRefs.current
      .filter(Boolean)
      .map((ref) =>
        `${ref.getValues().scheduled} ${ref.getValues().session}`.trim()
      );
    try {
      const res = await axios.post(
        `api/appointments-confirmation/${id}/reschedule/?temporary_token=${token}`,
        {
          schedules: dataInput,
        }
      );
      console.log(res);
      setOpenSuccess(true);
    } catch (err) {
      const errors = Object.entries(err.response?.data?.schedules).map(
        ([index, value], i) => `${value} at position ${Number(index) + 1}.`
      );
      setOpenError(true);
      setErrorArray(errors);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [formEntries]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex justify-center items-center bg-white p-5">
        <div className="max-w-md text-center">
          <h1 className="text-3xl font-bold pb-4">Reschedule Appointment</h1>
          <p className="pb-4">
            You indicated you would like to re-schedule your missed appointment
            - Please select a new time.
          </p>
          <div
            style={{ maxHeight: "200px", overflowY: "auto", height: "200px" }}
            className="flex flex-col"
            ref={containerRef}
            data-testid="reschedule-component"
          >
            {formEntries.map((id, idx) => (
              <RescheduleComponent
                key={id}
                ref={(el) => (inputRefs.current[idx] = el)}
                onDelete={() => handleDelete(id)}
                canDeleteRow={formEntries.length > 1}
              />
            ))}
          </div>
          <div className="pr-[6px] mt-4 text-center">
            <Button
              className="normal-case"
              color="success"
              endIcon={<ControlPoint />}
              onClick={addAppointment}
              disabled={formEntries.length >= 3}
            >
              <span className="text-black">
                Add additional availability times
              </span>
            </Button>
          </div>
          <div className="text-center mt-4">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </Button>
          </div>
        </div>
        <ResponseModal
          title="Appointments has rescheduled"
          description="You have successfully submitted your preferred time to reschedule the appointment."
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={() => navigate(0)}
        />
        <ResponseModal
          title="Error Rescheduling Appointments"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorArray}
        />
      </div>
    </LocalizationProvider>
  );
};

RescheduleAppointment.propTypes = {
  id:PropTypes.any,
}

export default RescheduleAppointment;
