import React, { useContext, useEffect, useState, useCallback } from "react";
import AssociateAppointmentsTable from "../AssociateAppointmentsTable";
import { Button } from "@mui/material";
import ResponseModal from "../../../../../../../../global/ResponseModal";
import LoadingIndicator from "../../../../../../../../global/LoadingIndicator";
import axios from "../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../context/DataContext";
import PropTypes from "prop-types";
import { ClientDetailContext } from "../../../../context/ClientDetailContext";
import CaseSummaryContext from "../../../../CaseSummaryV2/context/CaseSummaryContext";

function AssociateAppointments({
  document,
  clientId,
  setOpenAssociateAppointmentsModal,
  docTrigger,
  setDocTrigger,
  closeUploadModal,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const {
    documentTrigger,
    setDocumentTrigger
  } = useContext(CaseSummaryContext);
  const {
    selectedAppointment,
    isLaunchedFromAppointmentsTab,
    setIsLaunchedFromAppointmentsTab,
    reloadClientDetail,
    setReloadClientDetail,
  } = useContext(ClientDetailContext);

  const [appointmentList, setAppointmentList] = useState([]);
  const [next, setNext] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const buttonStyle = {
    width: "120px",
    height: "40px",
  };
  const provider = document && document.provider ? document.provider : "";
  const canAddAssociatedDocuments = userRoles.permissions.includes(
    "filemanager.add_document_association"
  );

  const handleSaveSuccess = () => {
    setReloadClientDetail(!reloadClientDetail);
    if (isLaunchedFromAppointmentsTab) {
      closeUploadModal();
      setDocumentTrigger(!documentTrigger);
      setDocTrigger(!docTrigger);
      setIsLaunchedFromAppointmentsTab(false);
    } else {
      setDocTrigger(!docTrigger);
      setOpenAssociateAppointmentsModal(false);
    }
  };

  const handleCancel = () => {
    if (isLaunchedFromAppointmentsTab) {
      closeUploadModal();
      setIsLaunchedFromAppointmentsTab(false);
    }else{
      setOpenAssociateAppointmentsModal(false);
    }
  }

  const handleSaveFail = () => {
    setOpenAssociateAppointmentsModal(false);
  };

  const covertDateFollowFormat = (dateObject) => {
    const dateObj = new Date(dateObject);
    const date = `${(dateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${dateObj
      .getDate()
      .toString()
      .padStart(2, "0")}/${dateObj.getFullYear()}`;
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const time = `${hours % 12 || 12}:${minutes} ${ampm}`;
    return { date, time };
  };

  const formatAppointments = useCallback((list) => {
    return list.map((app) => {
      const totalAmount = app.administered_services.reduce((sum, service) => {
        return sum + parseFloat(service.price);
      }, 0);

      const amount = `$${totalAmount.toFixed(2)}`;

      const { date, time } = covertDateFollowFormat(app.scheduled);

      return {
        id: app.pk,
        date,
        time,
        amount,
      };
    });
  }, []);

  const handleSaveData = () => {
    setIsLoading(true);
    console.log(document)
    const idsAssociatedAppointments = appointmentList
      .filter((item) => item.included)
      .map((item) => item.id);
      const documentId = document?.pk ? document?.pk :  document?.id;
    axios
      .patch(
        `/api/documents/${documentId}/`,
        {
          appointments: idsAssociatedAppointments,
        },
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (res) {
        setSaveSuccess(true);
        return res;
      })
      .catch(function (err) {
        setSaveFailed(true);
      });
    setIsLoading(false);
  };

  const fetchAppointments = useCallback(
    async (url) => {
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {

            const allAppointments = formatAppointments(
              response.data.results
            );
            const selAppointment = allAppointments.filter(appt => appt.id === selectedAppointment.pk);
            const responseAppointments = isLaunchedFromAppointmentsTab ? selAppointment : allAppointments
            
            setAppointmentList((prevAppointments) => [
              ...prevAppointments,
              ...responseAppointments,
            ]);
            setNext(response.data.next);

            setIsLoading(false);
          return response;
        })
        .catch(function () {
          setIsLoading(false);
          setSaveFailed(true);
        });
        
    },
    [
      accessToken,
      formatAppointments,
      isLaunchedFromAppointmentsTab,
      selectedAppointment,
    ]
  );

  const handleScroll = () => {
    if (next && !isLoading) {
      fetchAppointments(next);
    }
  };

  useEffect(() => {
    fetchAppointments(
      `/api/appointments/?limit=100&provider=${provider}&client=${clientId}&ordering=-scheduled&include_administered=true`
    );
  }, [document, clientId, provider, fetchAppointments]);

  const isPdf = (fileName) => {
    let fileExtPattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    
    if (fileName) {
      let getFileExt = fileName.match(fileExtPattern)[0];
      if (getFileExt === ".pdf") {
        return 'w-[100%] h-[500px]';
      }
      return 'w-[100%]';
    }
    return 'w-[100%]';
  };


  return (
    <>
      {isLoading ? (
        <div className="min-h-[65vh] w-full">
          <LoadingIndicator isLoading={isLoading} />
        </div>
      ) : (
        <>
        <div className="flex w-[100%]">
        {!isLaunchedFromAppointmentsTab && (
        <div className="w-[60%]">
          
            <div>
              {document && document.file ? (
                <embed
                  aria-label={document.file_name}
                  src={document.file}
                  width="100%"
                  height="100%"
                  className={isPdf(document.file_name)}
                />
              ) : (
                <p
                  style={{
                    fontSize: "1.2rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  No File Preview Available
                </p>
              )}
            </div>
          
          </div>
          )}
          <div className="grow ">
            <AssociateAppointmentsTable
              next={next}
              document={document}
              includedAppointments={isLaunchedFromAppointmentsTab ? document : document.appointments}
              appointmentList={appointmentList}
              setAppointmentList={setAppointmentList}
              handleScroll={handleScroll}
              canAddAssociatedDocuments={canAddAssociatedDocuments}
            />
          </div>
          </div>
          <div>
          {canAddAssociatedDocuments && (
            <div className="flex mt-6 justify-end space-between gap-4">
              <Button
                style={buttonStyle}
                variant="contained"
                color="error"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                disabled={!appointmentList || appointmentList.length === 0}
                style={buttonStyle}
                variant="contained"
                color="primary"
                onClick={() => handleSaveData()}
              >
                Save
              </Button>
            </div>
          )}
         
          </div>
          <ResponseModal
            title="Appointments successfully associated and saved"
            description="You've updated the Association of Appointments to Documents successfully"
            openBool={saveSuccess}
            setOpenBool={setSaveSuccess}
            handleCloseFunc={handleSaveSuccess}
          />
          <ResponseModal
            title="Can't save your associated appointments"
            isError={true}
            openBool={saveFailed}
            setOpenBool={setSaveFailed}
            errorMessage={null}
            handleCloseFunc={handleSaveFail}
          />
        
        </>
      )}
    </>
  );
}

AssociateAppointments.propTypes = {
  document: PropTypes.shape({
    provider: PropTypes.string || PropTypes.number,
  }),
  clientId: PropTypes.number,
  setOpenAssociateAppointmentsModal: PropTypes.func,
  docTrigger: PropTypes.bool,
  setDocTrigger: PropTypes.func,
};

export default AssociateAppointments;
