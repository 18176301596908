import React from "react";
import Colors from "../../../../global/Colors";
import { styled } from "@mui/material";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function CustomNavChip({ path, title, color, active}) {
  const navigate = useNavigate();

  const selected = active;

  const CustomChip = styled(Chip)({
    color: selected ? Colors.white : Colors.black,
    backgroundColor: selected ? Colors[color][600] : Colors.white,
    borderColor: Colors.black,
    "&&:hover, &&:focus": {
      color: Colors.white,
      backgroundColor: selected ? Colors[color][600] : Colors[color][400],
      borderColor: selected ? Colors[color][600] : Colors[color][400],
    },
  });

  const handleNavigateTo = (path) => {
    navigate(`/${path}`);
  };

  let baseStyle = "p-2 mx-1 my-1 text-center rounded-lg shadow-lg ";

  return (
    <CustomChip
      variant={selected ? "default" : "outlined"}
      label={title}
      className={baseStyle + (selected ? "active" : "")}
      onClick={() => handleNavigateTo(path)}
    />
  );
}
