import React, { useContext } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { Button, Dialog } from "@mui/material";
import FileUpload from "./FileUpload";
import { ClientDetailContext } from "../../context/ClientDetailContext";

export default function DocumentUpload({
  closeUploadModal,
  casePk,
  clientId,
  uploadSuccess,
  setUploadSuccess,
  uploadFailed,
  setUploadFailed,
  uploadErrorMessage,
  setUploadErrorMessage,
  uploadDocument,
  setUploadDocument,
  activeProvObj,
  docTrigger,
  setDocTrigger,
  setViewDocuments,
}) {
  const { setSelectedAppointment, setIsLaunchedFromAppointmentsTab } =
    useContext(ClientDetailContext);
  return (
    <Dialog open={uploadDocument} fullScreen={true}>
      <div className="w-full">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
            fontSize: "1.2rem",
          }}
        >
          <div>&nbsp;</div>
          <Button
            onClick={() => {
              closeUploadModal();
              setSelectedAppointment({});
              setIsLaunchedFromAppointmentsTab(false);
            }}
            style={{ padding: "0" }}
          >
            <CloseOutlined style={{ color: "#00000" }} />
          </Button>
        </div>
        <div
          style={{
            width: "fit-content",
            display: "block",
            margin: "0 auto",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Upload Document to Case
        </div>
        <div className="w-[75%] mx-auto my-1">
          <FileUpload
            closeUploadModal={closeUploadModal}
            casePk={casePk}
            clientId={clientId}
            uploadSuccess={uploadSuccess}
            setUploadSuccess={setUploadSuccess}
            uploadFailed={uploadFailed}
            setUploadFailed={setUploadFailed}
            uploadErrorMessage={uploadErrorMessage}
            setUploadErrorMessage={setUploadErrorMessage}
            setUploadDocument={setUploadDocument}
            activeProvObj={activeProvObj}
            docTrigger={docTrigger}
            setDocTrigger={setDocTrigger}
            setViewDocuments={setViewDocuments}
          />
        </div>
      </div>
    </Dialog>
  );
}
