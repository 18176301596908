import { Menu, MenuItem } from "@mui/material";

export default function SubMenuList({
  anchorEl,
  open,
  handleClose,
  subMenuList,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      id="dropdown-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 8,
          sx: {
            overflow: "visible",
            width: 200,
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 27,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {subMenuList &&
        subMenuList.map((subMenu, index) => (
          <MenuItem key={index}>
            {subMenu.menu}
          </MenuItem>
        ))}
    </Menu>
  );
}
