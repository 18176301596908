import {
  CheckCircleOutline,
  CloseOutlined,
  ListAltOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  AlertTitle,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  TextField,
  Tooltip,
  Chip,
  Button,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import qs from "query-string";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";
import ShowMoreButton from "../../../../../global/ShowMoreButton";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import CustomProvModal from "../../Settlement/MedicalBreakdown/CustomProvModal";
import LoadingIndicator from "../../../../../global/LoadingIndicator";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import ProviderDetailv2 from "../../../../../global/ProviderDetailModal/ProviderDetailv2";
import { ENTITY_TYPE_PROVIDER } from "../../../../../../globalConstants";

export default function AddClientProviderModal({
  setProvFormDisplay,
  trigger,
  setTrigger,
  caseInfo,
}) {
  const { accessToken, loggedInUser } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [assignedProvider, setAssignedProvider] = useState("");
  const [providerListResponse, setProviderListResponse] = useState("");
  const [openCustomProv, setOpenCustomProv] = useState(false);
  const [providerNetwork, setProviderNetwork] = useState([
    "inNetwork",
    "outOfNetwork",
  ]);
  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [provTypeOptions, setProvTypeOptions] = useState([]);
  const [providerDetail, setProviderDetail] = useState(null);
  const [outOfNetworkType, setOutOfNetworkType] = useState("All");
  const isDisableFilter = !!assignedProvider;
  const isProvider =
    loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;

  const populateProviderInfo = (provider) => {
    setAssignedProvider(provider.pk);
    setSearch(provider.name);
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(false);
    setProvFormDisplay(false);
    setTrigger(!trigger);
    setSearch("");
    setProviderListResponse("");
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setResLimit(25);
  };

  const sendRequest = () => {
    let querytest = qs.stringify(
      {
        search: search || null,
        type: outOfNetworkType === "All" ? null : outOfNetworkType,
        in_network:
          providerNetwork.length === 2
            ? null
            : providerNetwork[0] === "inNetwork",
      },
      {
        skipNull: true,
      }
    );

    let queryBase = `/api/provider/?limit=${resLimit}&ordering=name`;

    axios
      .get(queryBase + "&" + querytest, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.results;
        if (response.data.count <= resLimit) {
          setShowMoreVisible(false);
        } else {
          setShowMoreVisible(true);
        }
        setProviderListResponse(data);
      });
  };

  //Post
  const handleAddProCliStatus = (e) => {
    setLoading(true);
    let assignNewProvider = {
      client: caseInfo.client.pk,
      provider: assignedProvider,
    };

    axios
      .post("/api/pro_cli_status/", assignNewProvider, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(true);
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setErrorMessage(errArr);
          setLoading(false);
        }
        setOpenError(!openError);
      });
  };

  const handleOpenProvModal = () => {
    setOpenCustomProv(!openCustomProv);
  };

  const handleFilterByProviderNetwork = (value) => {
    if (providerNetwork.includes(value)) {
      if (providerNetwork.length > 1) {
        setProviderNetwork((prev) => prev.filter((n) => n !== value));
        setResLimit(25);
      }
      return;
    }
    setProviderNetwork([...providerNetwork, value]);
    setResLimit(25);
  };

  const getNetworkChipStyles = ({ isSelected, isDisabled, type }) => {
    const colors = {
      inNetwork: {
        main: "#7fba47",
        hover: "#eeffde73",
      },
      outOfNetwork: {
        main: "#d32f2f",
        hover: "#d32f2f0a",
      },
      disabled: "#b8b8b8",
    };

    const selectedColor = colors[type].main;
    const hoverColor = colors[type].hover;

    if (isSelected) {
      return {
        backgroundColor: isDisabled ? colors.disabled : selectedColor,
        color: "#fff",
        "&:hover": {
          backgroundColor: isDisabled ? colors.disabled : selectedColor,
        },
      };
    }

    return {
      color: isDisabled ? "#fff" : selectedColor,
      backgroundColor: isDisabled ? colors.disabled : "#fff",
      border: `solid 1px ${isDisabled ? colors.disabled : selectedColor}`,
      "&:hover": {
        backgroundColor: isDisabled ? colors.disabled : hoverColor,
      },
    };
  };

  useEffect(() => {
    axios
      .get(`/api/provider_types/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setProvTypeOptions(response.data.results || []);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setProvTypeOptions, accessToken]);

  useDebouncedEffect(
    () => sendRequest(),
    [accessToken, search, resLimit, providerNetwork, outOfNetworkType],
    250
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="w-[95%] flex justify-center flex-col mt-4">
        <div className="flex w-full justify-between items-center mb-10">
          <FormControl style={{ width: "50%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                style={{
                  width: "100%",
                  textAlign: "center",
                  alignSelf: "center",
                }}
                disabled={isDisableFilter}
                size="small"
                placeholder="Search by Provider Name, Address, City, State, or Provider Type."
                value={search}
                onChange={(event) => handleSearch(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: assignedProvider ? (
                    <InputAdornment position="start">
                      <CheckCircleOutline style={{ color: "green" }} />
                    </InputAdornment>
                  ) : (
                    ""
                  ),
                }}
              />
              {assignedProvider && (
                <Tooltip title="Clear Selection">
                  <IconButton
                    onClick={() => {
                      setAssignedProvider("");
                      setSearch("");
                    }}
                  >
                    <CloseOutlined style={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </FormControl>
          <div className="flex gap-4">
            <Chip
              disabled={isDisableFilter}
              className="!opacity-100"
              onClick={() => handleFilterByProviderNetwork("inNetwork")}
              label="In-Network"
              sx={getNetworkChipStyles({
                isSelected: providerNetwork.includes("inNetwork"),
                isDisabled: isDisableFilter,
                type: "inNetwork",
              })}
            />
            <Chip
              disabled={isDisableFilter}
              className="!opacity-100"
              onClick={() => handleFilterByProviderNetwork("outOfNetwork")}
              label="Out of Network"
              sx={getNetworkChipStyles({
                isSelected: providerNetwork.includes("outOfNetwork"),
                isDisabled: isDisableFilter,
                type: "outOfNetwork",
              })}
            />
          </div>
          <FormControl sx={{ width: "20%" }}>
            <InputLabel id="select-provider-type">Provider Type</InputLabel>
            <Select
              disabled={isDisableFilter}
              label="Provider Type"
              size="small"
              labelId="select-provider-type"
              id="provider-type"
              value={outOfNetworkType}
              onChange={(e) => {
                setOutOfNetworkType(e.target.value);
                setResLimit(25);
              }}
            >
              <MenuItem value="All">All</MenuItem>
              {provTypeOptions.map((opt) => (
                <MenuItem key={opt.id} value={opt.name}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          {!providerListResponse ||
          providerListResponse.length === 0 ||
          assignedProvider ? (
            <LoadingIndicator isLoading={!providerListResponse} />
          ) : (
            <React.Fragment>
              {providerListResponse.map((provider) => (
                <List
                  key={provider.pk}
                  className="p-0 my-1 w-full border border-solid border-gray-300 rounded-lg"
                >
                  <div className="flex">
                    <ListItemButton
                      className="w-[90%] gap-1"
                      onClick={() => populateProviderInfo(provider)}
                    >
                      <span style={{ width: "20%" }}>{provider.name}</span>
                      <span
                        style={{
                          width: !isProvider ? "30%" : "40%",
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                      >
                        {provider.address.raw}
                      </span>
                      {!isProvider && (
                        <span style={{ width: "10%", textAlign: "center" }}>
                          {provider.rating || "-"}
                        </span>
                      )}
                      <span style={{ width: "20%", textAlign: "center" }}>
                        {provider.in_network ? "In-Network" : "Out of Network"}
                      </span>
                      <span style={{ width: "20%", textAlign: "center" }}>
                        {(
                          provider?.provider_types?.map((type) => type?.name) ||
                          []
                        ).join(", ")}
                      </span>
                    </ListItemButton>
                    <Tooltip title="View Provider Detail" placement="right">
                      <IconButton
                        onClick={() => setProviderDetail(provider)}
                        className="flex-1 h-fit aspect-square my-auto"
                      >
                        <ListAltOutlined className="text-purple-600" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </List>
              ))}
              <ShowMoreButton
                showMoreVisible={showMoreVisible}
                setResLimit={setResLimit}
                resLimit={resLimit}
                showMoreAmt={25}
              />
            </React.Fragment>
          )}
        </div>
        <div className="flex justify-between my-6 w-[90%] mx-auto">
          <Button
            color="error"
            variant="outlined"
            className="normal-case"
            onClick={handleOpenProvModal}
          >
            Add Out of Network Provider
          </Button>
          <Button
            onClick={handleAddProCliStatus}
            className="text-white bg-sky-600 hover:bg-sky-700 normal-case"
          >
            {loading ? "Loading..." : "Add Provider to Client's Case"}
            {loading && (
              <div className="block pl-4 relative mt-2">
                <CircularProgress
                  sx={{
                    color: "#FFF",
                    maxWidth: "25px",
                    maxHeight: "25px",
                  }}
                />
              </div>
            )}
          </Button>
        </div>
        <Dialog open={openSuccess}>
          <Alert variant="outlined" severity="success" role="alert">
            <AlertTitle>Provider Added</AlertTitle>
            <p>
              Successfully Added Provider to Client's Data
              <br />
            </p>
          </Alert>
          <div style={{ padding: "1rem 0" }}>
            <ModalButton
              action={handleOpenSuccess}
              baseColor={"#1976d2"}
              content={"OK"}
            />
          </div>
        </Dialog>
        <ResponseModal
          title="Error Assigning Provider"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
        <CustomProvModal
          openCustomProv={openCustomProv}
          setOpenCustomProv={setOpenCustomProv}
        />
        {providerDetail && (
          <ProviderDetailv2
            open
            providerDetail={providerDetail}
            setOpen={() => setProviderDetail(null)}
          />
        )}
      </div>
    </LocalizationProvider>
  );
}
