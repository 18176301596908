export default function SubMenu({ title, onclick, link }) {
  const handleNavigation = () => {
    onclick(link);
  };
  return (
    <div onClick={handleNavigation} className="w-full">
      <span>{title}</span>
    </div>
  );
}
