import FormatPhoneNumber from "../../../../../../../global/FormatPhoneNumber";
import TooltipLineItem from "./TooltipLineItem";
import TooltipTitle from "./TooltipTitle";

export default function TooltipContentLawFirm({ item }) {
  return (
    <div id={`Tooltip-LawFirm-` + item?.id}>
      <TooltipTitle value={"Law Firm Detail"} />
      <hr />
      <TooltipLineItem label={"Name:"} value={item?.name} />
      <TooltipLineItem label={"Address:"} value={item?.address} />
      <TooltipLineItem label={"Email:"} value={item?.email} />
      <TooltipLineItem label={"Law Firm Website:"} value={item?.homepage_url} />
      <TooltipLineItem label={"Phone:"} value={FormatPhoneNumber(item?.phone)} />
    </div>
  );
}
