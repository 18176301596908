import {
  Button,
  CircularProgress,
  List,
  ListItem,
  TextField,
  Tooltip,
  Dialog,
} from "@mui/material/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useContext, useState } from "react";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import PaginationControls from "../../../global/PaginationControls";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../../../hooks/useWindowDimensions.js";
import CreateApptProcedurev2 from "./ClientDetail/ProvApptDetails/CreateApptProcedurev2.js";
import { useNavigate } from "react-router-dom";

const LIMIT = 25;
const COMPLETED_WITH_NULL_BILLS_PARAM = "completed_with_null_bills";

export default function CompletedNoBill({ isDashboardVersion }) {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const { accessToken, trigger, setTabTitle, userRoles } =
    useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [responseResults, setResponseResults] = useState([]);
  const [resultCount, setResultCount] = useState({ total: 0, current: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [openAddBill, setOpenAddBill] = useState(false);
  const [selectedAppt, setSelectedAppt] = useState(null);

  const handleSelectAppointment = (appointment) => {
    setSelectedAppt(appointment);
    setOpenAddBill(true);
  };

  const sendRequest = () => {
    setLoading(true);
    axios
      .get(`/api/appointments`, {
        headers: { Authorization: `Token ${accessToken}` },
        params: {
          [COMPLETED_WITH_NULL_BILLS_PARAM]: true,
          limit: LIMIT,
          offset: LIMIT * (currentPage - 1),
          search: search,
        },
      })
      .then(function (response) {
        const data = response?.data;
        setResponseResults(data?.results ?? []);
        setResultCount({
          total: data?.count ?? 0,
          current: data?.results?.length ?? 0,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [search, trigger, currentPage],
    250
  );

  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:p-2 px-2 py-2 sm:border-b-0 self-center";

  const handlePaginationChange = (_event, value) => {
    setCurrentPage(value);
  };

  const hasAddBillPermission = () => {
    return userRoles?.permissions?.map((permission) => permission?.toLowerCase())?.includes(
      "provider.add_administeredservices"
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={`px-4 ${
          isDashboardVersion ? "h-full overflow-auto" : "h-full overflow-hidden"
        } flex`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div className={`w-full md:ml-4`}>
          <h1
            className={`text-xl text-purple-600 text-center my-4 font-bold uppercase`}
          >
            completed appointments with no bill
          </h1>
          <div className="flex justify-center mt-4">
            <TextField
              className="placeholder:text-center rounded-lg w-full text-sm h-[2rem]"
              placeholder="Search by Client Name, Provider Name, Appointment Status, Appointment Notes"
              size="small"
              name="search"
              value={search}
              InputLabelProps={{ shrink: true }}
              label="Search"
              onChange={(event) => {
                setCurrentPage(1);
                setSearch(event.target.value)
              }}
            />
          </div>

          <div className="h-full overflow-hidden">
            <div className="flex justify-between my-2">
              <p className="pl-5 py-4">
                Results: {responseResults?.length}/{resultCount?.total}
              </p>
            </div>
            <div className="flex md:px-4 px-2 text-center py-2 border-b border-1 border-gray grid-cols-6">
              <h2 className="font-bold w-full">CLIENT NAME</h2>
              <h2 className="font-bold w-full">MEDICAL STATUS</h2>
              <h2 className="font-bold w-full">OVERALL STATUS</h2>
              <h2 className="font-bold w-full">PROVIDER NAME</h2>
              <h2 className="font-bold w-full">APPOINTMENT DATE</h2>
              <h2 className="font-bold w-full">&nbsp;</h2>
            </div>
            <List>
              {loading ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {responseResults.map((appointment) => {
                    const dateStr = appointment?.scheduled;
                    const formattedDate = dateStr
                      ? new Date(dateStr).toLocaleString("en-us", {
                          dateStyle: "medium",
                          timeStyle: "short",
                        })
                      : null;
                    return (
                      <ListItem
                        key={appointment?.pk}
                        value={appointment?.pk}
                        className="text-sm shadow-lg px-3 w-full flex bg-white rounded-md border-gray-100 border-y justify-between mb-2 grid-cols-6"
                      >
                        <button
                          className={`flex justify-start text-left uppercase w-full ${resultClasses} hover:bg-purple-600 hover:text-white rounded-lg`}
                          onClick={() => {
                            setTabTitle("Appointments");
                            navigate(`/client-detail/${appointment?.case?.pk}`);
                          }}
                        >
                          {appointment?.client?.name}
                        </button>
                        <span
                          className={`flex justify-center w-full ${resultClasses}`}
                        >
                          {appointment?.case?.status}
                        </span>
                        <span
                          className={`flex justify-center text-center w-full ${resultClasses}`}
                        >
                          {appointment?.case?.overall_status}
                        </span>
                        <span
                          className={`flex justify-center text-center w-full ${resultClasses}`}
                        >
                          {appointment?.provider?.name}
                        </span>
                        <span
                          className={`flex justify-center w-full  ${resultClasses}`}
                        >
                          {formattedDate}
                        </span>
                        <span
                          className={`flex justify-center w-full ${resultClasses} border-none`}
                        >
                          <Tooltip title="Add Bill" placement="top">
                            <Button
                              onClick={() =>
                                handleSelectAppointment(appointment)
                              }
                              className="flex mx-auto text-green-500 hover:text-white hover:bg-green-500 overflow-x-hidden"
                              disabled={!hasAddBillPermission()}
                            >
                              <span className="mx-4">
                                <ControlPointOutlinedIcon />
                              </span>
                            </Button>
                          </Tooltip>
                        </span>
                      </ListItem>
                    );
                  })}
                </>
              )}
              <ListItem className={`justify-center py-10 md:px-4 px-0`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handlePaginationChange}
                  currentPage={currentPage}
                  limit={LIMIT}
                />
              </ListItem>
            </List>
          </div>
        </div>
      </div>

      <Dialog open={openAddBill} fullScreen={width < 640}>
        <div className="py-5 flex flex-col items-center w-full">
          <div className="flex justify-end w-full text-xl">
            <Button className="p-0 w-fit" onClick={() => setOpenAddBill(false)}>
              <CloseIcon />
            </Button>
          </div>
          <h1 className="text-xl mb-4 text-center text-green-500">
            Create Bill
          </h1>
          <CreateApptProcedurev2
            selectedAppt={selectedAppt}
            setOpenAddBill={setOpenAddBill}
            caseInfo={selectedAppt}
          />
        </div>
      </Dialog>
    </LocalizationProvider>
  );
}
