import ProviderListProvider from "./context/ProviderListProvider";
import ProviderListv2WithState from "./ProviderListv2WithState";

const ProviderListv2 = ({ caseInfo }) => {
  return (
    <ProviderListProvider>
      <ProviderListv2WithState caseInfo={caseInfo} />
    </ProviderListProvider>
  );
};

export default ProviderListv2;
