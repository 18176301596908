import { useQuery } from "@tanstack/react-query";
import { user } from "../../../../../../api/endpoints/user";
import { useContext } from "react";
import DataContext from "../../../../../../../context/DataContext";
import TextCounter from "../TextCounter";
import { STRINGS } from "./strings";
import ScrollList from "../ScrollList";
import { LIST_TYPES } from "../listTypes";

const ActiveRolesDashFilter = () => {
  const { accessToken } = useContext(DataContext);
  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ["dashboard-stats-filters"],
    queryFn: async () => {
      const response = user.getActiveRolesStats(accessToken);
      return await response;
    },
  });

  if (isPending) return STRINGS.LOADING + "...";

  if (error) return STRINGS.ERROR + ": " + error.message;

  return (
    <div
      id="Active-Roles-Dashboard-Filter"
      data_testid="Active-Roles-Dashboard-Filter"
      className="flex flex-col h-full"
    >
      <div className="h-[90px] border-box p-2">
        <TextCounter
          title={STRINGS.VISIBLE_CASES}
          value={data.total_cases}
        />
        <TextCounter
          title={STRINGS.WITHOUT_ROLE}
          value={data.without_role_assignment_count}
        />
        <TextCounter
          title={STRINGS.WITH_ROLE}
          value={data.with_role_assignment_count}
        />
      </div>
      <div className="h-[100px] border-box p-1 overflow-y-auto border-t-2 border-slate-200">
        <ScrollList array={data.role_counts} type={LIST_TYPES.TEXT_LIST} />
      </div>
      <div>{isFetching ? STRINGS.UPDATING + "..." : ""}</div>
    </div>
  );
};

export default ActiveRolesDashFilter;
