import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "@mui/material";
import Colors from "../../../../global/Colors";
import SubMenuList from "./SubMenu/components/SubMenuList";

export default function ActionButton({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const link = children?.link || null;
  const subMenuList = children?.subMenuList?.() || null;
  const content = children?.content || 0;

  const isSubMenu = () => {
    return subMenuList && subMenuList.length > 0;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link to={link}>
        <Button
          onClick={isSubMenu() ? handleClick : () => {}}
          aria-controls={open ? "dropdown-menu" : undefined}
          aria-haspopup={"true"}
          aria-expanded={open ? "true" : undefined}
          className="text-4xl p-0 m-0"
        >
          <Badge
            badgeContent={parseInt(content)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiBadge-badge": {
                color: Colors.white,
                backgroundColor: Colors.red[600],
              },
            }}
          >
            {children?.icon}
          </Badge>
        </Button>
      </Link>
      <SubMenuList
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        subMenuList={subMenuList}
      />
    </>
  );
}
