import { AccountCircleRounded, ListAltOutlined } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

import Colors from "../../../../../styles/Colors";

export const ProviderTableRow = ({ data, onDetailClick }) => {
  return (
    <TableRow data-testid="provider-row">
      <TableCell
        className="sticky left-0 bg-white shadow-[-1px_3px_6px_lightgray] gap-1"
      >
        <Tooltip title="View Provider Detail">
          <IconButton
            className="hover:bg-gray-300 mr-1"
            onClick={() => onDetailClick(data?.pk)}
          >
            {data?.photo ? (
              <Avatar
                src={data?.photo}
                alt={data?.name}
                className="w-[50px] h-[50px]"
              />
            ) : (
              <AccountCircleRounded
                sx={{ fontSize: 55, color: Colors.gray[400] }}
              />
            )}
          </IconButton>
        </Tooltip>
        {data?.name || "-"}
      </TableCell>
      <TableCell align="center" className="whitespace-nowrap">
        {data?.address?.formatted || data?.address?.raw || "-"}
      </TableCell>
      <TableCell align="center" className="whitespace-nowrap">
        {data?.rating || "-"}
      </TableCell>
      <TableCell align="center" className="whitespace-nowrap">
        {data?.in_network ? "In-Network" : "Out of Network"}
      </TableCell>
      <TableCell align="center" className="whitespace-nowrap">
        <div className="flex flex-col mx-2 gap-1 mt-1">
          {data?.provider_types?.map((type) => (
            <div key={type.id}>{type?.name}</div>
          )) || []}
        </div>
      </TableCell>
      <TableCell
        align="center"
        className="sticky right-0 bg-white shadow-[2px_3px_6px_lightgray] whitespace-nowrap"
      >
        <Tooltip title="View Provider Detail" placement="right">
          <IconButton
            data-testid="view-detail"
            onClick={() => onDetailClick(data?.pk)}
            className="flex-1 h-fit aspect-square my-auto"
          >
            <ListAltOutlined className="text-purple-600" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

ProviderTableRow.propTypes = {
  data: PropTypes.object,
  onDetailClick: PropTypes.func,
};
