import { ArrowDownward } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

export default function CustomIconButton({
  bgColor,
  iconVisible,
  CustomIcon,
  handleOnClick,
  size = "small",
  tooltipText = "",
}) {
  return (
    <div className={`flex justify-center ${bgColor}`}>
      {" "}
      {!iconVisible ? (
        ""
      ) : (
        <Tooltip title={tooltipText}>
          <IconButton size={size} onClick={handleOnClick}>
            {CustomIcon ? CustomIcon : <ArrowDownward />}
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
