export const STRINGS = {
  TITLE: "Notifications",
  NO_NOTIFICATIONS_AVAILABLE: "No Notifications Available",
  ERROR_FETCHING_NOTIFICATION_FILTER:
    "There was an error getting filters.",
  ERROR_FETCHING_NOTIFICATION_LIST:
    "There was an error getting notifications. Please try again.",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  OLDER: "Older",
};
