import { useContext, useEffect, useState } from "react";
import {
  AccordionDetails,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Cancel, Check, Close, Edit } from "@mui/icons-material";

import {
  PDDispatchContext,
  PDStateContext,
} from "../../../context/ProviderDetailProvider";
import { setTrigger } from "../../../state/actions";
import axios from "../../../../../../../api/axios";
import DataContext from "../../../../../../../../context/DataContext";
import ApiRequestErrorHandler from "../../../../../../../global/ApiRequestErrorHandler";

export const Notes = () => {
  const { accessToken } = useContext(DataContext);
  const { provider, trigger } = useContext(PDStateContext);
  const dispatch = useContext(PDDispatchContext);

  const [isEditing, setIsEditing] = useState(false);
  const [providerNotes, setProviderNotes] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProviderNotes(provider?.notes || "");
  }, [provider]);

  const handleApiError = (error) => {
    setLoading(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr[0]);
    setResponseSuccess(false);
  };

  const handleApiSuccess = (response, valueName) => {
    setLoading(false);
    const res = response;
    setTrigger(dispatch, !trigger);
    setResponseBreakdown(`${valueName} was successfully updated`);
    setResponseSuccess(true);
    setIsEditing(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  const submitData = () => {
    const postData = { notes: providerNotes };

    axios
      .patch(`/api/provider/${provider.pk}/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        handleApiSuccess(response, "Notes");
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const cancelEdit = () => {
    const value = provider?.notes;

    setProviderNotes(value || "");
    setIsEditing(false);
    setResponseBreakdown("");
    setResponseSuccess(null);
  };

  return (
    <div className="flex flex-col">
      <div className="flex gap-2 pb-4">
        <h2 data-test-id="location-note" className="text-xl font-bold">
          Location Note
        </h2>
        {!isEditing ? (
          <Tooltip title="Click to edit">
            <IconButton
              data-test-id="edit-button"
              onClick={() => {
                setIsEditing(true);
              }}
              className="text-black"
              size="small"
            >
              <Edit className="text-[20px] text-gray-400" />
            </IconButton>
          </Tooltip>
        ) : (
          <div>
            {loading && <CircularProgress color="secondary" />}
            {!loading && responseSuccess === false && (
              <Cancel className="text-red-600" />
            )}
            {responseSuccess ? (
              ""
            ) : (
              <div className="flex justify-end">
                <Tooltip title="Submit">
                  <IconButton
                    data-test-id="submit-data-button"
                    className="text-green-600"
                    onClick={() => submitData()}
                    size="small"
                  >
                    <Check className="text-[20px]" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    data-test-id="cancel-button"
                    className="text-red-600"
                    onClick={() => cancelEdit()}
                    size="small"
                  >
                    <Close className="text-[20px]" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
      {!isEditing ? (
        <div>
          <AccordionDetails className="p-0">{providerNotes}</AccordionDetails>
          <p className="text-[12px]">
            <span className="mr-1">{responseBreakdown}</span>
            {responseSuccess ? <Check className="text-green-600" /> : ""}
          </p>
        </div>
      ) : (
        <TextField
          className="w-full"
          size="small"
          multiline
          onChange={(e) => {
            const value = e.target.value;
            // Limit to 500 characters
            const trimmedValue = value.slice(0, 500);
            setProviderNotes(trimmedValue);
          }}
          value={providerNotes}
          rows={7}
          error={responseSuccess !== null ? !responseSuccess : null}
        />
      )}
    </div>
  );
};
