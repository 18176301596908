import { Typography } from "@mui/material";

export default function TooltipLineItem({ label, value }) {
  return (
    <p className="my-1">
      <Typography component="span" className="text-xs text-black font-bold">
        {label}
      </Typography>{" "}
      {value}
    </p>
  );
}
