import React, { useContext, useMemo } from "react";

import LocationInfo from "./LocationInfo";
import TabPanel from "../../../../../global/TabPanel/TabPanel";
import DataContext from "../../../../../../context/DataContext";
import Management from "./Management";

const allTabs = [
  {
    name: "Location Info",
    isDisabled: false,
    component: <LocationInfo />,
    permission: null,
  },
  {
    name: "Available Services",
    isDisabled: true,
    component: <></>,
    permission: null,
  },
  {
    name: "Relationships",
    isDisabled: true,
    component: <></>,
    permission: null,
  },
  {
    name: "Summary/Notes",
    isDisabled: true,
    component: <></>,
    permission: null,
  },
  {
    name: "Management",
    isDisabled: false,
    component: <Management/>,
    permission: null,
  },
];

function ProviderOptions() {
  const { userRoles } = useContext(DataContext);

  const tabs = useMemo(() => {
    const result = [];
    allTabs.forEach((tab) => {
      if (tab.permission === null) {
        result.push(tab);
      }
      if (
        tab.permission !== null &&
        userRoles?.permissions?.includes(tab.permission)
      ) {
        result.push(tab);
      }
    });

    return result;
  }, [userRoles?.permissions]);

  return (
    <div className="flex-1 flex flex-col transparent overflow-y-auto xl:mr-6 bg-white rounded-tl-lg rounded-tr-lg">
      <TabPanel tabList={tabs} />
    </div>
  );
}

export default ProviderOptions;
