import { ListItem, MenuItem, Tooltip, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import OptionsMenu from "../../../../../../../global/OptionsMenu";
import DeleteProviderv2 from "../../DeleteProviderv2";
import ModalShell from "../../../../../../../global/ModalShell";
import DataContext from "../../../../../../../../context/DataContext";
import { Delete, ListAltOutlined } from "@mui/icons-material";
import StatusSelect from "./StatusSelect";
import ProviderDetailv2 from "../../../../../../../global/ProviderDetailModal/ProviderDetailv2";
import EditTotalInput from "../EditTotalInput";
import { MEDICAL_RECORD_NUMBER_KEY_VAL, TOTAL_INPUTS } from "../contants";
import PropTypes from 'prop-types';

export default function ProviderListItem({
  prov,
  proCliTrigger,
  setProCliTrigger,
  docTypeOptions,
  docTypeOptionsObj,
  caseInfo,
}) {
  const { userRoles } = useContext(DataContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openProvDetail, setOpenProvDetail] = useState(false);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    setProvider(prov);
  }, [prov]);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const getProcliPanelBgColor = () => {
    if (!prov.provider.in_network) return "bg-red-200";
    if (prov.provider.in_network && !prov.provider.receiving_clients) return "bg-yellow-100";
    return "bg-white";
  }
  return (
    <ListItem
      className={`shadow-md rounded-md justify-between hidden lg:flex ${getProcliPanelBgColor()}`}
    >
      <div className="w-1/5 text-left text-[14px]" aria-labelledby="provider">
        <h3 className="font-bold">{prov.provider.name}</h3>
        <div className="flex flex-col mx-2 gap-1 text-gray-400 mt-1">
          {prov?.provider?.provider_types?.map((type) => (
            <div key={type.id}>{type?.name}</div>
          )) || []}
        </div>
      </div>
      <div className="w-1/5 text-center text-[14px]" aria-labelledby="status">
        <StatusSelect
          provider={prov}
          proCliTrigger={proCliTrigger}
          setProCliTrigger={setProCliTrigger}
          docTypeOptions={docTypeOptions}
          docTypeOptionsObj={docTypeOptionsObj}
        />
      </div>
      <div className="w-1/5 text-[14px] px-2 flex flex-col gap-2" aria-labelledby="totals">
        {prov.can_view_balance_and_mrn ? TOTAL_INPUTS.map(key => (
          <EditTotalInput
            key={key.keyVal}
            title={key.title}
            type={key.type}
            keyVal={key.keyVal}
            tooltipEnabled={key.tooltipEnabled}
            provider={provider}
            setProvider={setProvider}
            noDataPlaceholder={key.noDataPlaceholder}
            isMedicalRecordValidate={key.keyVal === MEDICAL_RECORD_NUMBER_KEY_VAL}
            />
        )) : (
          <div className="grid justify-around">
            <span>-</span>
          </div>
        )}
      </div>
      <div
        className="w-1/5 text-center text-[14px] flex items-center justify-center"
        aria-labelledby="created"
      >
        <div>
          <span className="block">Created by: {prov.created_by_name}</span>
          <span className="block">
            Created at:&nbsp;
            {new Date(prov.created_at).toLocaleString("EN-US", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </div>
      </div>
      <div
        className="w-[15%] text-[14px] flex items-center justify-center"
        aria-labelledby="first-appt"
      >
        <div className="block text-center">
          {!prov.first_appointment_date
            ? "-"
            : new Date(prov.first_appointment_date).toLocaleString("EN-US", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
        </div>
      </div>
      <div className="w-[5%]">
        {" "}
        <OptionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
          <MenuItem onClick={() => setAnchorEl(null)}>
            {" "}
            <Tooltip title="View Provider Detail" placement="right">
              <IconButton
                onClick={() => setOpenProvDetail(true)}
                className="text-purple-600"
              >
                <ListAltOutlined className="text-[18px]" />
              </IconButton>
            </Tooltip>
          </MenuItem>
          {userRoles.permissions.includes(
            "provider.delete_providerclientstatus"
          ) ? (
            <MenuItem onClick={() => setAnchorEl(null)}>
              {" "}
              <Tooltip title="Delete Provider Connection" placement="right">
                <IconButton
                  onClick={() => handleOpenDeleteModal()}
                  className="text-red-600"
                >
                  <Delete className="text-[18px]" />
                </IconButton>
              </Tooltip>
            </MenuItem>
          ) : (
            ""
          )}
        </OptionsMenu>
      </div>
      {openDeleteModal ? (
        <ModalShell
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={"Delete Provider"}
          color={"#d32f2f"}
          customWidth={"md"}
        >
          <DeleteProviderv2
            providerDetail={prov}
            setDeleteFormDisplay={setOpenDeleteModal}
            trigger={proCliTrigger}
            setTrigger={setProCliTrigger}
            caseInfo={caseInfo}
          />
        </ModalShell>
      ) : (
        ""
      )}
      {openProvDetail ? (
        <ProviderDetailv2
          providerDetail={prov.provider}
          open={openProvDetail}
          setOpen={setOpenProvDetail}
        />
      ) : (
        ""
      )}
    </ListItem>
  );
}

ProviderListItem.propTypes = {
  prov: PropTypes.object,
};
