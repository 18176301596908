import { SHOULD_FETCH_DATA, SET_PROVIDERS_DATA, SET_STATUSES } from "./actions";

export const initialState = {
  shouldFetchData: true,
  providersData: new Map(),
  status: {
    finished_treatment: "",
    medical_documents_status: "",
  },
};

export function providersListReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_DATA: {
      return { ...state, shouldFetchData: action.payload };
    }
    case SET_PROVIDERS_DATA: {
      return { ...state, providersData: action.payload };
    }
    case SET_STATUSES: {
      return {
        ...state,
        status: {
          ...state.status,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    }
    default:
      return state;
  }
}
