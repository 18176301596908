import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function CaseDetailModal({ caseDetail, setActiveCase }) {
  const navigate = useNavigate();

  const navigateToDetail = (caseDetail) => {
    navigate(`/client-detail/${caseDetail.pk}`);
    setActiveCase("");
  };
  return (
    <div className="flex rounded-b-lg" style={{ padding: "0 0 2rem 0" }}>
      <div style={{ textAlign: "left" }}>
        <h3 className="my-2">
          <strong>Client Name:</strong> {caseDetail.client.name}
        </h3>
        <h3>
          <h3 className="my-2">
            <strong>Address:</strong>{" "}
            {!caseDetail.client.address
              ? "No Address"
              : caseDetail.client.address.raw}
          </h3>
          <h3 className="my-2">
            <strong>Email:</strong>{" "}
            {!caseDetail.client.email ? "No Email" : caseDetail.client.email}
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h3>
              <strong>Phone:&nbsp;</strong> {caseDetail.client.phone}
            </h3>
            {!caseDetail.client.phone ? (
              "No Phone Listed"
            ) : (
              ""
            )}
          </div>
        </h3>
        <div style={{ margin: "1rem 0" }}>
          <Button
            onClick={() => navigateToDetail(caseDetail)}
            variant="outlined"
            style={{
              borderColor: "#9333ea",
              textAlign: "center",
              fontWeight: "bold",
              color: "#9333ea",
            }}
          >
            View Case Detail
          </Button>
        </div>
      </div>
    </div>
  );
}
