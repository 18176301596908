import React from "react";
export default function AccordionList({
  accordionListData,
  accordionVariant = "default",
  accordionSummaryVariant = "default",
  expandIcon,
  shrinkIcon,
  children,
  summaryContent,
  expandedContent,
}) {
  const icons = { expandIcon: expandIcon, shrinkIcon: shrinkIcon };
  return (
    <div className="h-full">
      {accordionListData &&
        [...accordionListData.entries()].map(([key, accordionListItem]) =>
          React.cloneElement(children, {
            key: key,
            accordionItem: accordionListItem,
            icons: icons,
            accordionVariant: accordionVariant,
            accordionSummaryVariant: accordionSummaryVariant,
            summaryContent: summaryContent,
            expandedContent: expandedContent,
          })
        )}
    </div>
  );
}
