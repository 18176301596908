import { useContext } from "react";
import DataContext from "../../context/DataContext";
import Cookies from "js-cookie";
import AuthContext from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";

const useLogout = () => {
  const { setAuth } = useContext(AuthContext);
  const {
    getAccessToken,
    accessToken,
    setCaseManagerPk,
    setSelectedProvider,
    setSelectedClient,
    setUserType,
    setDashCompLimit,
    setErrorMessage,
  } = useContext(DataContext);
  const navigate = useNavigate();

  const logout = () => {
    axios
      .post("/auth/logout", null, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status < 405) {
          setAuth(null);
          navigate("/");
          Cookies.remove("pnAccessToken");
          Cookies.remove("pnCmPk");
          Cookies.remove("userType");
          Cookies.remove("category");
          Cookies.remove("token_exp");
          getAccessToken("");
          setCaseManagerPk("");
          setSelectedProvider("");
          setSelectedClient("");
          setUserType("");
          setDashCompLimit(5);
          setErrorMessage("");
          return response;
        }
      })
      .catch((error) => {
        return error;
      });
  };

  return logout;
};

export default useLogout;
