//import SelectBasic from "../../../../../../../../../../global/FormComponents/SelectBasic";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../../../../../context/DataContext";
import { STRINGS } from "../../../../components/utils/strings";
import {
  //ProviderListDispatchContext,
  ProviderListStateContext,
} from "../../../../context/ProviderListProvider";
//import { setStatuses } from "../../../../state/actions";
import { FormControl, MenuItem, Select } from "@mui/material";
import Colors from "../../../../../../../../../../global/Colors";
import Title from "../../../../../components/Title";

export default function TreatmentStatus({
  canEdit,
  title,
  index,
  fieldName,
  initialValue,
  updateProviderInfo,
  subTitle,
}) {
  const { userRoles } = useContext(DataContext);
  const providerListState = useContext(ProviderListStateContext);
  const [initialLoad, setInitialLoad] = useState(false);

  const [options, setOptions] = useState([
    {
      id: "",
      name: STRINGS.NO_SELECTION,
    },
    {
      id: STRINGS.TREATMENT_STATUS.TREATING,
      name: STRINGS.TREATMENT_STATUS.TREATING,
    },
    {
      id: STRINGS.TREATMENT_STATUS.COMPLETE,
      name: STRINGS.TREATMENT_STATUS.COMPLETE,
    },
  ]);
  const [treatment, setTreatment] = useState("");
  const perms = {
    change_providerclientstatus: userRoles.permissions.includes(
      "provider.change_providerclientstatus"
    ),
    //canEdit: "provider.can_edit",
  };

  const isDisabled = canEdit ? false : true;

  const getTreatmentValue = (treatmentValue) => {
    return treatmentValue === STRINGS.TREATMENT_STATUS.COMPLETE ? true : false;
  };
  const handleSetTreatment = (event) => {
    let value = event.target.value;
    setTreatment(value);

    let boolValue = getTreatmentValue(value);

    let editableData = null;

    const existingFields =
      providerListState.providersData.get(index).editableFields;
    if (boolValue === initialValue) {
      if (existingFields && Object.keys(existingFields).length > 1) {
        editableData = {
          ...existingFields,
        };
        console.log(editableData);
        delete editableData[fieldName];
        console.log(editableData);
      }
      const updatedData = new Map(providerListState.providersData);
      updatedData.set(index, {
        ...updatedData.get(index),
        editableFields: editableData,
      });
      updateProviderInfo(updatedData);
      return;
    }
    editableData = {
      ...existingFields,
      [fieldName]: boolValue,
    };
    const updatedData = new Map(providerListState.providersData);
    updatedData.set(index, {
      ...updatedData.get(index),
      editableFields: editableData,
    });
    updateProviderInfo(updatedData);
  };
  useEffect(() => {
    let active = true;
    if (active) {
      if (initialLoad === false) {
        let finalValue;
        if (initialValue === null) {
          finalValue = STRINGS.NO_SELECTION;
        } else if (initialValue === true) {
          finalValue = STRINGS.TREATMENT_STATUS.COMPLETE;
        } else if (initialValue === false) {
          finalValue = STRINGS.TREATMENT_STATUS.TREATING;
        } else {
          finalValue = STRINGS.NO_SELECTION;
        }

        setTreatment(finalValue);
        setInitialLoad(true);
      }
    }
    return () => {
      active = false;
    };
  }, [initialValue, treatment, initialLoad]);

  return (
    <div
      id="Provider-Tab-Treatment-Status"
      data-testid="Provider-Tab-Treatment-Status"
      className="w-full"
    >
      <div>
        <Title
          text={title}
          size={"text-xs"}
          horizontalPadding="pl-2"
          className="w-full block"
        />
        {subTitle && (
          <Title
            text={"(" + subTitle + ")"}
            size={"text-[0.65rem]"}
            color="text-gray-400"
            fontStyle="normal-case"
            horizontalPadding="pl-2"
          />
        )}
      </div>
      {options && options.length > 0 && (
        <FormControl
          sx={{ marginTop: "0.5rem", minWidth: 160, maxWidth: 500 }}
          size="small"
        >
          <Select
            labelId="Provider-Tab-Treatment-Status-label"
            id="Provider-Tab-Treatment-Status-select"
            className="w-full"
            value={treatment}
            label={title}
            onChange={handleSetTreatment}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              "&.Mui-disabled": {
                backgroundColor: isDisabled ? Colors.gray[200] : Colors.white,
              },
              fontSize: "0.85rem",
              borderRadius: "50px",
              backgroundColor: Colors.white,
            }}
            disabled={isDisabled}
          >
            <MenuItem value={STRINGS.NO_SELECTION}>
              {STRINGS.NO_SELECTION}
            </MenuItem>
            <MenuItem value={STRINGS.TREATMENT_STATUS.TREATING}>
              {STRINGS.TREATMENT_STATUS.TREATING}
            </MenuItem>
            <MenuItem value={STRINGS.TREATMENT_STATUS.COMPLETE}>
              {STRINGS.TREATMENT_STATUS.COMPLETE}
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </div>
  );
}
