import { createContext, useReducer } from "react";
import PropTypes from "prop-types";

import { initialState, pdReducer } from "../state/pdReducer";

export const PDDispatchContext = createContext();
export const PDStateContext = createContext();

const ProviderDetailProvider = ({ children }) => {
  const [state, dispatch] = useReducer(pdReducer, initialState);

  return (
    <PDStateContext.Provider value={state}>
      <PDDispatchContext.Provider value={dispatch}>
        {children}
      </PDDispatchContext.Provider>
    </PDStateContext.Provider>
  );
};

export default ProviderDetailProvider;

ProviderDetailProvider.propTypes = {
  children: PropTypes.any,
};
