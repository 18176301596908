import React, { useContext, useMemo } from "react";

import InfoContent from "./InfoContent";
import DataContext from "../../../../../../../context/DataContext";
import TabPanel from "../../../../../../global/TabPanel/TabPanel";
import ProviderHours from "./ProviderHours";
import Employee from "./Employee";

const allTabs = [
  {
    name: "Info",
    isDisabled: false,
    component: <InfoContent />,
    permission: null,
  },
  {
    name: "Physicians",
    isDisabled: true,
    component: <></>,
    permission: null,
  },
  {
    name: "Employees",
    isDisabled: false,
    component: <Employee/>,
    permission: "users.view_usertoproviderassignments",
  },
  {
    name: "Hours of Operations",
    isDisabled: false,
    component: <ProviderHours/>,
    permission: "provider.view_providerofficehours",
  },
];

function LocationInfo() {
  const { userRoles } = useContext(DataContext);

  const tabs = useMemo(() => {
    const result = [];
    allTabs.forEach((tab) => {
      if (tab.permission === null) {
        result.push(tab);
      }
      if (
        tab.permission !== null &&
        userRoles?.permissions?.includes(tab.permission)
      ) {
        result.push(tab);
      }
    });

    return result;
  }, [userRoles?.permissions]);

  return (
    <div className="flex-1 flex flex-col transparent overflow-y-auto bg-white rounded-tl-lg rounded-tr-lg">
      <TabPanel
        tabList={tabs}
        headerBoxBackgroundColor="transparent"
        headerTabVariant="standard"
        headerTabIndicatorSx={{ backgroundColor: "#9333EA" }}
        headerTabSx={{
          "& button.Mui-selected": {
            color: `#000`,
          },
          "& .Mui-selected": {
            color: `#9333EA`,
          },
          "& .MuiTab-root": {
            fontWeight: "700",
            color: "#000",
          },
          "& .MuiBox-root.div": {
            padding: 0,
          },
          "& .MuiTabs-fixed": {
            borderLeft: `1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
            borderRight: `1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
            borderBottom: `1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
          },
          "& .Mui-disabled": {
            color: "#ccc",
          },
        }}
      />
    </div>
  );
}

export default LocationInfo;
