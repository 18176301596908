import React from "react";

export default function AccordionDetails({ children, accordionItem }) {
  return (
    <div className="p-2">
      {children && React.cloneElement(children, {
        accordionItemContent: accordionItem,
      })}
    </div>
  );
}
