import { useContext, useEffect } from "react";
import DataContext from "../../../../../../../context/DataContext";
import { useQuery } from "@tanstack/react-query";
import { user } from "../../../../../../api/endpoints/user";
import { STRINGS } from "./strings";
import { LIST_TYPES } from "../listTypes";
import ScrollList from "../ScrollList";

const CaseStatusDashFilter = () => {
  const { accessToken } = useContext(DataContext);

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ["dashboard-stats-filters"],
    queryFn: async () => {
      const response = user.getCaseStatusStats(accessToken);
      return await response;
    },
    selected: (data) => data.case_status_counts,
  });

  if (isPending) return STRINGS.LOADING + "...";

  if (error) return STRINGS.ERROR + ": " + error.message;

  return (
    <div
      id="Case-Status-Dashboard-Filter"
      data_testid="Case-Status-Dashboard-Filter"
      className="overflow-y-auto"
    >
      <ScrollList
        array={data.case_status_counts}
        type={LIST_TYPES.BUTTON_LIST}
        color="purple"
      />
      <div>{isFetching ? STRINGS.UPDATING + "..." : ""}</div>
    </div>
  );
};

export default CaseStatusDashFilter;
