export const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
          navigator.serviceWorker
            .register("/serviceWorker.js", {scope: '/', updateViaCache: 'none'})
            .then((registration) => {
              console.log("Service Worker registered.");
            })
            .catch((error) => {
              console.log("Service Worker registration failed:", error);
            });
        });
      }
}
