import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../../../../../../context/DataContext";
import axios from "../../../../../../../api/axios";
import { PDDispatchContext, PDStateContext } from "../../../context/ProviderDetailProvider";
import HoursOfOperation from "../../../../../../../global/ProviderDetailModal/ProviderHours/HoursOfOperation";
import { setTrigger } from "../../../state/actions";

const ProviderHours = () => {
  const { accessToken, loggedInUser } = useContext(DataContext);
  const [activeProvObj, setActiveProvObj] = useState("");
  const dispatch = useContext(PDDispatchContext);
  const { provider, trigger } = useContext(PDStateContext);

    const handleTrigger = () => {
      setTrigger(dispatch, !trigger);
    };
  

  useEffect(() => {
    if (
      loggedInUser.entity &&
      loggedInUser.entity.entity_type_label === "Provider"
    ) {
      axios
        .get(`/api/provider/?managed_by=${loggedInUser.pk}&simplified=true?`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let obj = {};

          for (const item in data) {
            obj[data[item].pk] = data[item].pk;
          }

          setActiveProvObj(obj);
        });
    }
  }, [accessToken, loggedInUser]);

  return (
    <HoursOfOperation
      providerDetail={provider}
      activeProvObj={activeProvObj}
      handleTrigger={handleTrigger}
    />
  );
};

export default ProviderHours;
