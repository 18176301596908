import Title from "../../components/Title";

export default function AnalyticsHeader({ title, subTitle }) {
  return (
    <div className="flex flex-col justify-start p-2">
      <Title text={title} size="text-lg" />
      <Title
        text={subTitle}
        color="text-gray-600"
        size="text-sm"
        fontWeight="font-medium"
      />
    </div>
  );
}
