import { Stack } from "@mui/material";
import ActionButton from "./ActionButton";
import RenderActionButtons from "./renderActionButtons";

export default function ActionButtonList() {
  const renderActionButtonsList = RenderActionButtons();
  return (
      <Stack spacing={0} direction="row" className="flex justify-end items-center mr-4 text-white">
        {renderActionButtonsList.map((actionButton, index) => (
          <ActionButton key={index}>{actionButton}</ActionButton>
        ))}
      </Stack>
  );
}
