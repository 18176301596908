import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Select, MenuItem, Button } from "@mui/material";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { selectStyles } from "../../constant";
import FormField from "../../../../../global/FormComponents/field/FormField";
import Modal from "../../../../../global/Modal";
import { ControlPoint } from "@mui/icons-material";
import { ENTITY_TYPE_PROVIDER } from "../../../../../../globalConstants";
import AppointmentForm from "./AppointmentForm";
import { isValid, parse } from "date-fns";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import PropTypes from "prop-types";
import { provider } from "../../../../../api/endpoints/provider";
import { useParams } from "react-router-dom";

const isEmpty = (obj) => Object.keys(obj).length === 0;

export default function BulkCreateAppointment({
  setOpenAddAppts,
  activeProvObj,
  caseInfo,
  open,
  onClose,
  title,
}) {
  const { accessToken, trigger, setTrigger, loggedInUser } =
    useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providerList, setProviderList] = useState([]);
  const [providerTypeList, setProviderTypeList] = useState([]);
  const [selectedProviderType, setSelectedProviderType] = useState("");
  const [loading, setLoading] = useState(false);
  const [formEntries, setFormEntries] = useState([1]);
  const inputRefs = useRef([]);
  const params = useParams();
  const isProvider =
    loggedInUser.entity &&
    loggedInUser.entity.entity_type_label === ENTITY_TYPE_PROVIDER;

  const addAppointment = () => {
    setFormEntries((prev) => {
      const lastElm = prev.at(-1);
      return [...prev, lastElm + 1];
    });
  };

  const handleDelete = (id) => {
    const deleteIdx = formEntries.findIndex((idEntry) => idEntry === id);
    const newFormEntries = [...formEntries];
    newFormEntries.splice(deleteIdx, 1);
    setFormEntries(newFormEntries);
    inputRefs.current.splice(deleteIdx, 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let dataSubmit = [
      {
        status: "",
        client: caseInfo.client.pk,
        provider: selectedProvider,
        provider_type: selectedProviderType,
      },
    ];
    const dataInput = inputRefs.current
      .filter((item) => !!item)
      .map((ref) => ref.getValues())
      .filter((d) => !!d.status || !!d.scheduled);
    if (dataInput.length > 0) {
      dataSubmit = dataInput.map((d) => {
        const scheduled = d.scheduled
          ? parse(d.scheduled, "MM/dd/yyyy hh:mm a", new Date())
          : null;
        const data = {
          status: d.status,
          client: caseInfo.client.pk,
          provider: selectedProvider,
          provider_type: selectedProviderType,
        };
        if (isValid(scheduled)) {
          data.scheduled = scheduled.toISOString();
        }
        return data;
      });
    }
    try {
      setLoading(true);
      await axios.post("/api/appointments/", dataSubmit, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      });
      setOpenSuccess(true);
      setLoading(false);
    } catch (error) {
      const res = error.response.data || [];
      const errArr = ApiRequestErrorHandler(
        res
          .map((err, idx) => {
            if (!isEmpty(err)) {
              const objErr = {};
              Object.keys(err).forEach((k) => {
                const newKey =
                  k === "non_field_errors" ? k : `${k} in line ${idx + 1}`;
                objErr[newKey] = err[k];
              });
              return objErr;
            }
            return null;
          })
          .filter((err) => !!err)
      );
      setErrorArray(errArr);
      setOpenError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenSuccess = () => {
    setOpenAddAppts(false);
    setTrigger(!trigger);
  };

  const fetchProviders = useCallback(async (fieldOptionsUrls) => {
    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorArray(errArr);
        setOpenError(true);
      });
  }, []);

  useEffect(() => {
    const filterProvidersBasedOnRole = (data, isProvider) => {
      let newArr = [];
      for (let i = 0; i < data.length; i++) {
        if (isProvider) {
          if (data[i].can_edit) {
            newArr.push(data[i]);
          }
        } else {
          newArr.push(data[i]);
        }
      }
      return newArr;
    };

    const fieldOptionsUrls = [
      provider.getProviderClientStatusByCaseId(params.id, accessToken),
    ];

    fetchProviders(fieldOptionsUrls)
      .then(async (results) => {
        if (results) {
          if (results[0]) {
            setProviderList(
              filterProvidersBasedOnRole(results[0].results, isProvider)
            );
          }
        }
        return results;
      })
      .then(async (results) => {
        return results;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorArray(errArr);
        setOpenError(true);
      });
  }, [accessToken, fetchProviders, params.id, isProvider]);

  /* useEffect(
    () => {
    const fetchProvider = async () => {
      try {
        const response = await axios.get(
          `/api/pro_cli_status/?client=${caseInfo.client.pk}`,
          {
              headers: { Authorization: `Token ${accessToken}` }
          }
        );     
        if(response){
          setProviderList(filterProvidersBasedOnRole(response.data.results));
        } 
       
      } catch (err) {
        console.error(err);
      }
    };
    if (caseInfo?.client?.pk) {
      fetchProvider();
    }

  }, [accessToken, caseInfo.client.pk, filterProvidersBasedOnRole]); */

  const handleSelectedProvider = async (e) => {
    setProviderTypeList([]);
    setSelectedProviderType("");
    setSelectedProvider(e.target.value);
  };

  useEffect(() => {
    const fetchProviderTypes = async () => {
      try {
        const response = await axios.get(`/api/provider/${selectedProvider}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        });
        const results = response.data?.provider_types;
        setProviderTypeList(results);
      } catch (err) {
        console.error(err);
      }
    };
    if (selectedProvider) {
      fetchProviderTypes();
    }
  }, [selectedProvider, accessToken]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      actions={[
        {
          label: "Create Appointments",
          onClick: caseInfo?.client?.pk ? handleSubmit : () => {},
          isLoading: loading,
        },
      ]}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="w-full flex flex-col gap-4 justify-center bg-white">
          <FormField label="Select a Provider From This Case">
            <Select
              data-testid="provider-select"
              className="w-full"
              sx={{
                ...selectStyles,
                "& fieldset": {
                  borderColor: "#9ca3af",
                },
              }}
              value={selectedProvider}
              onChange={(event) => handleSelectedProvider(event)}
            >
              {providerList.map((prov) => {
                return (
                  <MenuItem value={prov.provider.pk} key={prov.provider.pk}>
                    {prov.provider.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormField>
          {selectedProvider > 0 && (
            <FormField label="Provider Type">
              <Select
                className="w-full"
                sx={{
                  ...selectStyles,
                  "& fieldset": {
                    borderColor: "#9ca3af",
                  },
                }}
                value={selectedProviderType}
                onChange={(event) =>
                  setSelectedProviderType(event.target.value)
                }
                placeholder="Please select a provider type"
                displayEmpty
                renderValue={(value) =>
                  !value ? (
                    <span className="text-gray-400">
                      Please select a provider type
                    </span>
                  ) : (
                    providerTypeList.find((p) => p.id === value)?.name
                  )
                }
              >
                {providerTypeList?.map((protypes) => {
                  return (
                    <MenuItem value={protypes.id} key={protypes.id}>
                      {protypes.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormField>
          )}

          <FormField label="Appointments">
            <div className="flex flex-col gap-2">
              {formEntries.map((id, idx) => (
                <AppointmentForm
                  key={id}
                  ref={(el) => (inputRefs.current[idx] = el)}
                  onDelete={() => handleDelete(id)}
                  canDeleteRow={formEntries.length > 1}
                />
              ))}
              {formEntries.length < 20 && (
                <div className="text-end pr-[6px]">
                  <Button
                    className="normal-case"
                    color="success"
                    endIcon={<ControlPoint />}
                    onClick={addAppointment}
                  >
                    <span className="text-black">Add another appointment</span>
                  </Button>
                </div>
              )}
            </div>
          </FormField>
        </div>
      </LocalizationProvider>
      <ResponseModal
        title="Appointments Created"
        description="Appointments have been added to clients case"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Creating Appointments"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </Modal>
  );
}

BulkCreateAppointment.propTypes = {
  setOpenAddAppts: PropTypes.func,
  activeProvObj: PropTypes.object,
  caseInfo: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};
