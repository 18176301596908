import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../context/DataContext";
import InlineEditRow from "../components/InlineEditRow";
import axios from "../../../../../../api/axios";
import CaseSummaryContext from "../../CaseSummaryV2/context/CaseSummaryContext";

export default function PrimaryContacts({ caseObj, viewPermissions = true }) {
  const { accessToken } = useContext(DataContext);
  const { caseSummaryTrigger, setCaseSummaryTrigger } =
    useContext(CaseSummaryContext);
  const [userValueLabels, setUserValueLabels] = useState("");
  const [userData, setUserData] = useState("");
  const params = useParams();

  useEffect(() => {
    if (!caseObj) {
      return;
    } else {
      axios
        .get(`/api/users/${caseObj.client.pk}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setUserData(data);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [setUserData, accessToken, params.id, caseObj, caseSummaryTrigger]);

  useEffect(() => {
    axios
      .get(`/api/field_options/all_fields/user/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setUserValueLabels(data);
      })
      .catch((err) => {
        return err;
      });
  }, [accessToken, setUserValueLabels]);

  const inputs = [
    {
      keyVal: "phone",
      type: "text",
      title: "Phone",
    },
    {
      keyVal: "email",
      type: "text",
      title: "Email",
    },
    {
      keyVal: "address",
      type: "address",
      title: "Address",
    },
  ];
  return !caseObj ? (
    <h2 className="text-center underline my-2 text-gray-500">
      Content details not available for this account at this time
    </h2>
  ) : (
    <div className="pr-2 pl-4 py-4 shadow-md rounded-md">
      {!viewPermissions ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account
        </h2>
      ) : (
        <div>
          <h2 className="text-lg font-bold text-gray-600">Primary Contact</h2>{" "}
          {inputs.map((key, idx) => (
            <div className="w-full flex items-center">
              <InlineEditRow
                key={idx}
                title={key.title}
                type={key.type}
                keyVal={key.keyVal}
                displayNameKey={key.displayNameKey}
                caseDetail={userData}
                apiUrl={`/api/users/${caseObj.client.pk}/`}
                customDisplayUrl={key.customValueLabels ? true : false}
                optionsUrl={key.optionsUrl}
                customLimit={key.customLimit}
                postKeyVal={key.postKey}
                trigger={caseSummaryTrigger}
                setTrigger={setCaseSummaryTrigger}
                optionsDisplayKey={key.optionsDisplayKey}
                valueHistory={null}
                displayDetail={userData}
                valueLabels={
                  key.customValueLabels
                    ? key.customValueLabels
                    : userValueLabels
                }
                disabled={key.disabled ? true : false}
                nullable={key.nullable}
                staticVal={key.staticVal}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
