export const STRINGS = {
  EMPTY: "",
  NA: "N/A",
  IN_NETWORK: "In Network",
  OUT_OF_NETWORK: "Out Of Network",
  NOT_ACCEPTING_PATIENTS: "Not Accepting Patients",
  SPACE_STR_SEPARATOR: " ",
  APPT_TEXT: "Appointments",
  SCHEDULED_TEXT: "Scheduled",
  MISSED_TEXT: "Missed",
  DURATION_DAYS: "Days",
  PROCLI_LIST_ERROR:
    "There was an error while fetching providers. Please try again.",
  EDITABLE_FIELDS_MAPPING: {
    completedAppt: "completed_appt_count",
    estimatedApptCount: "estimated_appointment_count",
    medicalRecNo: "medical_record_number",
    confirmedBalance: "confirmed_balance",
    unconfirmedBalance: "unconfirmed_balance",
    treatmentStatus: "finished_treatment",
    documentStatus: "medical_documents_status",
    currentBalance: "current_balance",
  },
  TREATMENT_STATUS: {
    TREATING: "Treating",
    COMPLETE: "Complete",
  },
  NO_SELECTION: "No Selection",
};
