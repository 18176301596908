import { CloseOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ModalButton from "../../../../../../../../global/ModalButton";
import axios from "../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../context/DataContext";
import ResponseModal from "../../../../../../../../global/ResponseModal";
import CaseSummaryContext from "../../../../CaseSummaryV2/context/CaseSummaryContext";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import { ClientDetailContext } from "../../../../context/ClientDetailContext";

export default function DeleteDocument({
  document,
  openDeleteModal,
  setOpenDeleteModal,
  getExtension,
  docTrigger,
  setDocTrigger,
  documentTypeObj,
}) {
  const { accessToken } = useContext(DataContext);
  const { documentTrigger, setDocumentTrigger, caseDetail } =
    useContext(CaseSummaryContext);
  const { reloadClientDetail, setReloadClientDetail } = useContext(ClientDetailContext);
  
  const [errorArray, setErrorArray] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const handleDeleteSuccess = () => {
    setOpenDeleteModal(false);
    setDeleteSuccess(false);
    setDocTrigger(!docTrigger);
  };

  const deleteDocument = () => {
    axios
      .delete(`/api/documents/${document.pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (res) {
        setDeleteSuccess(true);
        setReloadClientDetail(!reloadClientDetail);
        if (+document.document_type === 7) {
          setDocumentTrigger(!documentTrigger);
        }
        return res;
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
        }
        return error;
      });
  };
  return (
    <>
      <Dialog open={openDeleteModal}>
        <Alert
          variant="outlined"
          severity="error"
          role="error"
          style={{ paddingRight: "40px" }}
        >
          <AlertTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 0 1rem",
            }}
          >
            Delete Document{" "}
            <Button
              onClick={() => setOpenDeleteModal(false)}
              style={{ padding: "0" }}
            >
              <CloseOutlined />
            </Button>
          </AlertTitle>
          <h1
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              margin: "1rem 0",
            }}
          >
            Confirm document deletion
          </h1>
          <div style={{ margin: "0 auto" }}>
            {" "}
            {!document.file ? (
              <CircularProgress color="secondary" />
            ) : (
              <div>
                <p>
                  <strong>File Name: </strong>
                  {document.file_name}
                </p>
                <p>
                  <strong>File Type: </strong>
                  {!document.document_type
                    ? "No type added"
                    : !documentTypeObj
                    ? ""
                    : documentTypeObj[document.document_type]}
                </p>
                {getExtension(document.file_name) === "" ? (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      margin: "10px",
                    }}
                  >
                    No File Preview Available
                  </p>
                ) : (
                  <object
                    data={document.file}
                    aria-label={document.file_name}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "block",
                      minHeight: "80vh",
                    }}
                  ></object>
                )}
              </div>
            )}
          </div>
          <ModalButton
            className="block"
            action={deleteDocument}
            baseColor="#d32f2f"
            icon="delete"
            content={"Confirm Document Delete"}
          />
        </Alert>{" "}
      </Dialog>
      <ResponseModal
        title="Document/Image Delete Success"
        description="The Document/Image was successfully removed from this case"
        openBool={deleteSuccess}
        setOpenBool={setDeleteSuccess}
        handleCloseFunc={handleDeleteSuccess}
      />
      <ResponseModal
        title="Deletion failed"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </>
  );
}
