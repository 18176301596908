import { List, ListSubheader } from "@mui/material";
import NotificationsItem from "./NotificationsItem";

export function NotificationsSection({ title, section }) {

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: 700,
        "& ul": { padding: 0 },
      }}
      key={`section-${title}`}
      dense={true}
      subheader={<li />}
    >
      <ListSubheader className="bg-gray-100" sx={{ lineHeight: "32px" }}>
        {title}
      </ListSubheader>
      <List dense={true}>
        {section.length > 0 &&
          section.map((item, idx) => (
            <NotificationsItem
              key={`item-${section}-${item.id}-${idx}`}
              item={item}
            />
          ))}
      </List>
    </List>
  );
}
