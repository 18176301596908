import { format, parseISO } from "date-fns";

const emptyString = "";
const doaValue = (value, specificFormat) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  let trimmedValue = value.trim();
  if (trimmedValue === "") {
    return emptyString;
  }

  let date = parseISO(trimmedValue);
  return format(date, specificFormat);
};
const FormatISODate = (date, specificFormat = "M/d/yyyy") => {
  return doaValue(date, specificFormat);
};
export default FormatISODate;
