import { React, Fragment, useState, useContext, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Tooltip,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { APPT_STATUS } from "../../../../../../../globalConstants";
import {
  DeleteForeverOutlined,
  EditOutlined,
  ExpandMore,
  ListAltOutlined,
  UploadFile,
} from "@mui/icons-material";
import ProviderApptProceduresv2 from "../ProviderApptProceduresv2";
import DataContext from "../../../../../../../context/DataContext";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import HtmlTooltip from "./HtmlToolTip";
import { format, parseISO } from "date-fns";
import { ClientDetailContext } from "../../context/ClientDetailContext";

export default function CustomCollapsibleTable({
  providerApptsList,
  isModalVersion,
  activeProvObj,
  activeBills,
  apptResLimit,
  caseInfo,
  proCliTrigger,
  setProCliTrigger,
  printable,
  handleApptDelete,
  handleProviderApptEdit,
  setProvider,
  setProviderDetailDisplay,
  handleUploadDocument,
}) {
  const { userRoles } = useContext(DataContext);
  const { setSelectedAppointment } = useContext(ClientDetailContext);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const rows = providerApptsList;

  function onClickProviderDetails(provider) {
    setProvider(provider);
    setProviderDetailDisplay(true);
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [bills, setBills] = useState(false);
    const [hasBills, setHasBills] = useState();

    useEffect(() => {
      let billsObj = {};
      for (const item in activeBills) {
        billsObj[activeBills[item].appointment.pk] = activeBills[item].price;
      }
      setHasBills(billsObj);
    }, []);

    return (
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell align="left" component="th" scope="row">
            {row.provider.name}
          </TableCell>
          <TableCell align="left">{row.provider.type}</TableCell>
          <TableCell align="left">
            {row.scheduled
              ? format(parseISO(row.scheduled), "M/d/yyyy h:mm aaa")
              : ""}
            &nbsp;
          </TableCell>
          <TableCell align="center">
            <div className="flex flex-row gap-2 items-center">
              <div className="flex flex-row gap-0 items-center">
                <HtmlTooltip
                  title={
                    <>
                      <h1>{`Amount: ${
                        row.running_total
                          ? formatter.format(row.running_total)
                          : ""
                      }`}</h1>
                    </>
                  }
                  arrow
                  className="arrow"
                  placement="top-start"
                >
                  {
                    <AttachMoneyRoundedIcon
                      className={`text-[20px] text-bold text-purple-700 ${
                        hasBills && hasBills[row.pk] ? "visible" : "invisible"
                      }`}
                    />
                  }
                </HtmlTooltip>
                <Box
                  sx={{
                    borderRadius: "50%",
                    height: "15px",
                    width: "15px",
                    bgcolor:
                      row.status === "Done"
                        ? "#86CFAC"
                        : row.status === "Overdue"
                        ? "#FCA5A5"
                        : "#7DD3FC",
                  }}
                />
              </div>
              {APPT_STATUS[row.status] ?? row.status ?? ""}
            </div>
          </TableCell>
          <TableCell align="center">{row.document_count}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto">
              <Box sx={{ margin: 1 }}>
                <div className="p-2 w-full flex justify-center">
                  <div className="justify-evenly place-content-center text-center flex  w-full">
                    {userRoles.permissions.includes(
                      "provider.view_provider"
                    ) ? (
                      <Tooltip title="Provider Details">
                        <Button
                          onClick={() => onClickProviderDetails(row.provider)}
                          className="flex rounded-md hover:text-purple-500 text-purple-300 hover:text-white hover:bg-purple-300"
                          style={{ color: "#9333ea" }}
                        >
                          <span>
                            <ListAltOutlined />
                          </span>
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    {userRoles.permissions.includes(
                      "provider.change_appointment"
                    ) ? (
                      <Tooltip title="Edit Appointment" placement="bottom">
                        <Button
                          className="rounded-md hover:text-purple-500 text-purple-300 hover:text-white hover:bg-purple-300"
                          style={{ color: "#9333ea" }}
                          onClick={(e) => handleProviderApptEdit(row)}
                        >
                          <span>
                            <EditOutlined />
                          </span>
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    {userRoles.permissions.includes(
                      "provider.change_appointment",
                      "filemanager.view_document", 
                      "filemanager.add_document"
                    ) && (
                      <Tooltip title="Upload Documents" placement="bottom">
                        <Button
                          className="rounded-md hover:text-purple-500 text-purple-300 hover:text-white hover:bg-purple-300"
                          style={{ color: "#9333ea" }}
                          onClick={() => {
                            handleUploadDocument(row);
                            setSelectedAppointment(row);
                          }}
                        >
                          <UploadFile />
                        </Button>
                      </Tooltip>
                    )}
                    {userRoles.permissions.includes(
                      "provider.change_appointment"
                    ) ? (
                      <Tooltip title="Delete Appointment" placement="bottom">
                        <Button
                          onClick={(e) => handleApptDelete(row)}
                          className="rounded-md hover:text-white text-red-300 hover:bg-red-300"
                          style={{ color: "#D32F2F" }}
                        >
                          <span>
                            <DeleteForeverOutlined />
                          </span>
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Accordion
                  style={{ margin: "1rem" }}
                  disabled={!row.notes ? true : false}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {!row.notes
                      ? "No Notes - Edit appointment info to add"
                      : "Appointment Notes"}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="bg-white">
                      <h3
                        className="notes"
                        dangerouslySetInnerHTML={{
                          __html: row.notes,
                        }}
                      ></h3>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <div className="p-2 m-1 flex flex-col border-purple-600 border rounded-md w-full text-center">
                  {userRoles.permissions.includes(
                    "provider.view_administeredservices"
                  ) ? (
                    <ProviderApptProceduresv2
                      selectedAppt={row}
                      setBills={setBills}
                      bills={bills}
                      isModalVersion={isModalVersion}
                      printable={printable}
                      hasBills={hasBills}
                      setHasBills={setHasBills}
                      activeProvObj={activeProvObj}
                      activeBills={activeBills}
                      apptResLimit={apptResLimit}
                      caseInfo={caseInfo}
                      proCliTrigger={proCliTrigger}
                      setProCliTrigger={setProCliTrigger}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  return (
    <TableContainer component={Paper} data-id="collapsible-table">
      <Table sx={{ minWidth: 640 }} aria-label="collapsible table">
        <TableHead style={{ fontWeight: "900" }}>
          <TableRow data-id="collapsible-table-headers">
            <TableCell align="left" className="font-bold">
              Provider&nbsp;Location
            </TableCell>
            <TableCell align="left" className="font-bold">
              Type
            </TableCell>
            <TableCell align="left" className="font-bold">
              Date&nbsp;&&nbsp;Time
            </TableCell>
            <TableCell align="center" className="font-bold">
              Status
            </TableCell>
            <TableCell align="center" className="font-bold">
              Documents
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
