import { format, parseISO } from "date-fns";

const emptyString = "";

const formattedDate = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  if (value === "" || value === " ") {
    return emptyString;
  }
  return format(parseISO(value), "M/d/yyyy");
}

const formattedTime = (value) =>{
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  if (value === "" || value === " ") {
    return emptyString;
  }
  return format(parseISO(value), "h:mm aaa")};

const separator = () => {
  return " ";
};

const lineBreakSeparator = () => {
  return "\n";
}

const FormatISODateTime = (date, newLine = false) => {
  return formattedDate(date).toString() + separator() + (newLine ? lineBreakSeparator():"") + formattedTime(date).toString();
};
export default FormatISODateTime;
