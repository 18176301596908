import FormatISODate from "../../../../../../../../global/FormatISODate";
import ValueWithFallback from "../../../../../../../../global/ValueWithFallback";
import { STRINGS } from "./strings";

export default function getUpdatedString(user, date) {
  let userNameStr = ValueWithFallback(user, STRINGS.NA);
  let dateStr = FormatISODate(date);
  let deliminator = dateStr !== "" ? " " : "";

  return dateStr + deliminator + userNameStr;
}
