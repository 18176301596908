import { Divider, Stack } from "@mui/material";
import AnalyticsHeader from "../../components/AnalyticsHeader";
import CustomTextField from "../../components/CustomTextField";
import ProgressBarCircular from "../../../../../../../../ui library/dataVisualizations/ProgressBarCircular";
import { useContext } from "react";
import { STRINGS } from "../../components/utils/strings";
import {
  ProviderListDispatchContext,
  ProviderListStateContext,
} from "../../context/ProviderListProvider";
import { setProvidersData } from "../../state/actions";
import DataContext from "../../../../../../../../../context/DataContext";

export default function AnalyticsStatus({
  currentAccordionItem,
  activateButtons,
  deactivateButtons,
}) {
  const providerListStateContext = useContext(ProviderListStateContext);
  const providerListDispatchContext = useContext(ProviderListDispatchContext);
  const index = currentAccordionItem.index;
  const proCliData = currentAccordionItem.proCliData;
  const getTreatmentStatus = () => {
    if (
      providerListStateContext.providersData.get(index).editableFields &&
      "treatmentStatus" in
        providerListStateContext.providersData.get(index).editableFields
    ) {
      if (
        providerListStateContext.providersData.get(index).editableFields
          .treatmentStatus === true
      ) {
        return true;
      }
      return false;
    } else if (proCliData.finished_treatment === true) {
      return true;
    }
    return false;
  };

  const inTreatmentDays =
    proCliData.total_days_in_treatment +
    STRINGS.SPACE_STR_SEPARATOR +
    STRINGS.DURATION_DAYS;
  const noBillsCount =
    proCliData.appt_no_bill_count +
    STRINGS.SPACE_STR_SEPARATOR +
    STRINGS.APPT_TEXT;
  const scheduledApptCount =
    proCliData.scheduled_appt_count +
    STRINGS.SPACE_STR_SEPARATOR +
    STRINGS.SCHEDULED_TEXT;
  const missedApptCount =
    proCliData.missed_appt_count +
    STRINGS.SPACE_STR_SEPARATOR +
    STRINGS.MISSED_TEXT;
  const completedApptCount = proCliData.completed_appt_count;
  const estimatedApptCount = getTreatmentStatus()
    ? proCliData.completed_appt_count
    : proCliData.estimated_appointment_count;
  const medicalRecNo = proCliData.medical_record_number;

  const getPercentCompletion = () => {
    const treatmentStat = getTreatmentStatus();
    if (treatmentStat) {
      return 100;
    }
    return Number(proCliData.completion_percentage) * 100 ?? 0;
  };

  const treatmentCompletionPercent = getPercentCompletion();

  const validateEstimatedApptCount = (
    value,
    setValue,
    setError,
    setHelperText
  ) => {
    const regex = /[^0-9]/g;
    const match = regex.test(value);
    if (match) {
      deactivateButtons();
      setValue(value);
      setError(true);
      setHelperText("Only numbers allowed.");
      return 0;
    } else if (parseInt(value) > 32767) {
      setValue(value);
      setError(true);
      setHelperText("Count needs to be less than or equal to 32767");
      return 0;
    }
    setError(false);
    setHelperText("");
    const sanitizedValue = value.replace(regex, "");
    setValue(sanitizedValue);
    return 1;
  };

  const validateMedicalRecordNumber = (
    value,
    setValue,
    setError,
    setHelperText
  ) => {
    const regex = /[^a-zA-Z0-9]/g;
    const match = regex.test(value);
    if (match) {
      setValue(value);
      setError(true);
      setHelperText("Only alphabets and numbers allowed");
      return 0;
    }

    const sanitizedValue = value.replace(regex, "");

    if (sanitizedValue.length > 40) {
      setValue(value);
      setError(true);
      setHelperText("The length needs to be less than 40");
      return 0;
    }
    setError(false);
    setHelperText("");
    const trimmedValue = sanitizedValue.slice(0, 40);
    setValue(trimmedValue);
    return 1;
  };

  const updateProviderInfo = (updatedData = null) => {
    if (updatedData === null) {
      deactivateButtons();
      return;
    }
    if (updatedData.get(index).editableFields === null) {
      deactivateButtons();
    } else {
      activateButtons();
    }
    if (updatedData !== null) {
      setProvidersData(providerListDispatchContext, updatedData);
    }
  };

  return (
    <div className="h-full flex flex-row p-2 flex-wrap">
      <div className="h-full mr-4">
        <ProgressBarCircular
          progressPercent={
            treatmentCompletionPercent > 100 ? 100 : treatmentCompletionPercent
          }
        />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="flex flex-row mb-4 flex-wrap">
          <AnalyticsHeader title={inTreatmentDays} subTitle={"in Treatment"} />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ borderWidth: "1px" }}
          />
          <AnalyticsHeader title={noBillsCount} subTitle={"With No-Bills"} />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ borderWidth: "1px" }}
          />
          <AnalyticsHeader
            title={scheduledApptCount}
            subTitle={"Appointments"}
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ borderWidth: "1px" }}
          />
          <AnalyticsHeader title={missedApptCount} subTitle={"Appointments"} />
        </div>
        <div className="flex flex-row justify-start w-full mt-4 flex-wrap">
          <div className="w-3/5 flex flex-row justify-start p-0 m-0">
            <CustomTextField
              index={index}
              title={"Completed Appt"}
              width={"w-5/6"}
              defaultValue={completedApptCount}
              providerInfo={proCliData}
              activateButtons={activateButtons}
              deactivateButtons={deactivateButtons}
              updateProviderInfo={updateProviderInfo}
              disabled={true}
            />
            <CustomTextField
              index={index}
              title={"Provider Estimated Count"}
              fieldName={"estimatedApptCount"}
              width={"w-5/6"}
              defaultValue={estimatedApptCount}
              providerInfo={proCliData}
              updateProviderInfo={updateProviderInfo}
              activateButtons={activateButtons}
              deactivateButtons={deactivateButtons}
              validatorFunction={validateEstimatedApptCount}
              disabled={!proCliData.can_edit || getTreatmentStatus()}
            />
          </div>
          <div className="w-2/5 flex-1">
            <CustomTextField
              index={index}
              title={"Medical Record Number"}
              fieldName={"medicalRecNo"}
              width={"w-5/6"}
              defaultValue={medicalRecNo}
              providerInfo={proCliData}
              placeholder={"Add a number"}
              updateProviderInfo={updateProviderInfo}
              activateButtons={activateButtons}
              deactivateButtons={deactivateButtons}
              validatorFunction={validateMedicalRecordNumber}
              disabled={!proCliData.can_edit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
