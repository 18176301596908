export const SHOULD_FETCH_DATA = "SET_SHOUL_FETCH_DATA";
export const SET_PROVIDERS_DATA = "SET_PROVIDERS_DATA";
export const SET_STATUSES = "SET_STATUSES";

export const setShouldFetchData = (dispatch, bool) =>
  dispatch({ type: SHOULD_FETCH_DATA, payload: bool });

export const setProvidersData = (dispatch, Map) =>
  dispatch({ type: SET_PROVIDERS_DATA, payload: Map });

export const setStatuses = (dispatch, obj) =>
  dispatch({ type: SET_STATUSES, payload: obj });
