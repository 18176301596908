import React from "react";
import { ListItemIcon } from "@mui/material";
import { AccountBalance, DocumentScanner } from "@mui/icons-material";
import Colors from "../../../../../../../global/Colors";
import { NOTIFICATION_TYPES } from "../utils/types";
import TooltipContentCase from "./TooltipContentCase";
import TooltipContentLawFirm from "./TooltipContentLawFirm";
import NotificationTooltip from "./NotificationTooltip";

export default function NotificationsItemtype({ item }) {
  const renderIcon = (item) => {
    switch (item.type) {
      case NOTIFICATION_TYPES.CASE:
        return <DocumentScanner />;
      case NOTIFICATION_TYPES.LAW_FIRM:
        return <AccountBalance />;
      default:
        return;
    }
  };
  const renderName = (item) => {
    switch (item.type) {
      case NOTIFICATION_TYPES.CASE:
        return item.client_name;
      case NOTIFICATION_TYPES.LAW_FIRM:
        return item.name;
      default:
        return;
    }
  };
  const renderTooltipContent = (item) => {
    switch (item.type) {
      case NOTIFICATION_TYPES.CASE:
        return <TooltipContentCase item={item} />;
      case NOTIFICATION_TYPES.LAW_FIRM:
        return <TooltipContentLawFirm item={item} />;
      default:
        return;
    }
  };

  const renderNotificationItem = (item) => {
    return (
      <NotificationTooltip title={renderTooltipContent(item)}>
        <div className="flex inline-flex items-center my-0">
          <div className="w-[20px] mr-2">
            <ListItemIcon sx={{ color: Colors.black }}>
              {renderIcon(item)}
            </ListItemIcon>
          </div>
          <div className="text-black ">{renderName(item)}</div>
        </div>
      </NotificationTooltip>
    );
  };
  return <div>{renderNotificationItem(item)}</div>;
}
