import React, {useContext, useState, useEffect} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {Button, Dialog, Grid, Typography} from "@mui/material";
import {format, isBefore, startOfToday} from "date-fns";
import {Close} from "@mui/icons-material";
import LoadingIndicator from "../../../../../../../../global/LoadingIndicator";
import axios from "../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../context/DataContext";


const AddUnavailabilityModal = ({userId, handleClose, setResponseState, setIsResponseModalVisible}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const {accessToken} = useContext(DataContext);
  const [isEndDateInvalid, setIsEndDateInvalid] = useState(false);

  const validateDates = (startDate, endDate) => {
    const isDateValid = (date) => {
      return date && !isBefore(date, startOfToday());
    };

    if (!startDate) {
      return {isError: true, message: "Please select a start date."};
    }

    if (!isDateValid(startDate)) {
      return {isError: true, message: "Start date cannot be in the past."};
    }

    if (endDate && isBefore(endDate, startDate)) {
      return {isError: true, message: "End date must be greater than start date."};
    }

    return {isError: false, message: ""};
  };

  useEffect(() => {
    if (endDate && startDate) {
      setIsEndDateInvalid(isBefore(endDate, startDate));
    }
  }, [endDate, startDate]);

  const onSave = () => {
    const {isError, message} = validateDates(startDate, endDate);

    if (isError) {
      setResponseState({message, isError});
      setIsResponseModalVisible(true);
      return;
    }

    const postData = {
      start_date: format(startDate, 'yyyy-MM-dd'),
      end_date: endDate != null ? format(endDate, 'yyyy-MM-dd') : null,
      user: userId,
    };

    setIsLoading(true);

    axios.post("/api/user_unavailability/", postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
    })
      .then((response) => {
        setResponseState({
          message: "Unavailability has been successfully added.",
          isError: false,
        });
        setIsResponseModalVisible(true);
        handleClose();
        return response;
      })
      .catch((error) => {
        setResponseState({
          message: "Error saving unavailability. Please try again.",
          isError: true,
        });
        setIsResponseModalVisible(true);
        return false;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={true}>
        <div className="outer text-center p-2">
          <div className="text-right">
            <Button onClick={(e) => handleClose(e)}>
              <Close/>
            </Button>
          </div>

          <div className="text-center p-2 relative">
            <div className="text-center p-2">
              <div id="Content-Add-Users" data-testid="Content-Add-Users">
                <Grid container direction="column" spacing={4}>
                  <div className="text-xl text-center">Add Unavailability</div>

                  <Grid item xs={12}>
                    <Typography variant="body1" align="left">Please Select Start Date</Typography>
                    <DatePicker
                      disablePast
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          disabled: isLoading,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" align="left">Please Select End Date</Typography>
                    <DatePicker
                      disablePast
                      value={endDate}
                      error={isEndDateInvalid}
                      onChange={setEndDate}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          disabled: isLoading,
                          error: isEndDateInvalid,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="m-2 capitalize"
                      variant="contained"
                      color="primary"
                      onClick={onSave}
                      disabled={!startDate || isEndDateInvalid || isLoading}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            {isLoading && (
              <div className="inset-2 absolute flex items-center justify-center bg-white bg-opacity-75">
                <LoadingIndicator isLoading left="auto"/>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </LocalizationProvider>
  );
}

export default AddUnavailabilityModal;
