import { Divider, Stack } from "@mui/material";
import FullyRoundedButton from "../../../../../../../../ui library/components/buttons/FullyRoundedButton";
import HyperLinkButton from "../../../../../../../../ui library/components/buttons/HyperLinkButton";
import Colors from "../../../../../../../../global/Colors";
import ProviderDetailsContainer from "../ProviderDetailsContainer";
import AnalyticsStatus from "../../ProviderAnalytics/components/AnalyticsStatus";
import Balances from "../../ProviderAnalytics/components/Balances";
import Status from "../../ProviderAnalytics/components/Status";
import { provider } from "../../../../../../../../api/endpoints/provider";
import { useContext, useState } from "react";
import DataContext from "../../../../../../../../../context/DataContext";
import {
  ProviderListDispatchContext,
  ProviderListStateContext,
} from "../../context/ProviderListProvider";
import { setProvidersData } from "../../state/actions";
import { STRINGS } from "../utils/strings";

export default function ProviderListDetailsv2({ accordionItemContent }) {
  const { userRoles, accessToken } = useContext(DataContext);
  const providerListStateContext = useContext(ProviderListStateContext);
  const providerListDispatchContext = useContext(ProviderListDispatchContext);

  const index = accordionItemContent.index;
  const providerInfo = accordionItemContent.proCliData.provider;
  const caseKey = accordionItemContent.proCliData?.pk;

  const [saveBtn, disableSaveBtn] = useState(true);
  const [clearBtn, disableClearBtn] = useState(true);
  const [refreshDetails, setRefreshDetails] = useState(false);

  const activateButtons = (save = false, clear = false) => {
    disableSaveBtn(save);
    disableClearBtn(clear);
  };

  const deactivateButtons = (save = true, clear = true) => {
    disableSaveBtn(save);
    disableClearBtn(clear);
  };

  const handleOnClickViewProvider = () => {
    accordionItemContent.setProviderData(providerInfo);
    accordionItemContent.setOpenProvDetail(true);
  };

  const handleOnClickDeleteProvider = () => {
    accordionItemContent.setProviderData(accordionItemContent.proCliData);
    accordionItemContent.setOpenDeleteModal(true);
  };

  const handleOnClickSave = async () => {
    try {
      const params = [caseKey];
      /*Note: accordionItemContent will have editebleFields as null. Therefore, we are fetching directly from state because OnChange from textfield is reflected in the state. */
      const editableData =
        providerListStateContext.providersData.get(index).editableFields;
      const editableFieldsKeyMapping = STRINGS.EDITABLE_FIELDS_MAPPING;
      const patchData = Object.keys(editableData).reduce((acc, key) => {
        const mappedKey = editableFieldsKeyMapping[key];
        if (mappedKey) {
          acc[mappedKey] = editableData[key];
        }
        return acc;
      }, {});

      const res = await provider.updateProviderClientData(
        accessToken,
        patchData,
        params
      );

      if (res.response && res.response.status && res.response.status !== 200)
        throw res;
      accordionItemContent.handleSuccess(
        "Provider Client details successfully updated."
      );
      deactivateButtons();
    } catch (error) {
      accordionItemContent.handleError(error);
    }
  };

  const handleOnClickClear = () => {
    try {
      const updatedProvidersData = new Map(
        providerListStateContext.providersData
      );
      updatedProvidersData.set(index, {
        ...updatedProvidersData.get(index),
        editableFields: {},
      });

      setProvidersData(providerListDispatchContext, updatedProvidersData);
      disableSaveBtn(true);
      disableClearBtn(true);
      setRefreshDetails(!refreshDetails);
    } catch (error) {
      accordionItemContent.handleError(error);
    }
  };

  return (
    <div className="my-4">
      <Stack spacing={1} direction="column" className="h-full">
        <ProviderDetailsContainer title={"Status"}>
          <AnalyticsStatus
            key={refreshDetails}
            currentAccordionItem={accordionItemContent}
            activateButtons={activateButtons}
            deactivateButtons={deactivateButtons}
          />
        </ProviderDetailsContainer>
        <Stack spacing={1.5} direction="row" className="mx-2 py-2 pb-4">
          <ProviderDetailsContainer title={"Balances"}>
            <Balances
              key={refreshDetails}
              currentAccordionItem={accordionItemContent}
              activateButtons={activateButtons}
              deactivateButtons={deactivateButtons}
            />
          </ProviderDetailsContainer>
          <ProviderDetailsContainer title={"Statuses"}>
            <Status
              key={refreshDetails}
              currentAccordionItem={accordionItemContent}
              activateButtons={activateButtons}
              deactivateButtons={deactivateButtons}
            />
          </ProviderDetailsContainer>
        </Stack>
      </Stack>
      <div className="flex flex-row justify-between items-baseline m-2">
        <Stack spacing={2} direction="row">
          <FullyRoundedButton
            buttonText="Save"
            disabled={saveBtn}
            horizontalPadding="px-12"
            verticalPadding="py-2"
            handleOnClick={handleOnClickSave}
            hoverBgColor={Colors.blue[600]}
          />
          <FullyRoundedButton
            variant="outlined"
            disabled={clearBtn}
            bgColor="bg-white"
            hoverBgColor={Colors.blue[100]}
            textColor="text-blue-600"
            hoverTextColor="text-blue-600"
            borderWidth="border-[1px]"
            hoverBorderWidth="border-[1px]"
            borderColor="border-blue-600"
            buttonText="Clear"
            horizontalPadding="px-12"
            verticalPadding="py-2"
            handleOnClick={handleOnClickClear}
          />
        </Stack>
        <Stack spacing={1} direction="row">
          <HyperLinkButton
            buttonText={"View Provider Details"}
            buttonTextSize="text-xs"
            handleOnClick={handleOnClickViewProvider}
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: Colors.black, borderColor: Colors.black }}
          />
          <HyperLinkButton
            buttonText={"Delete Provider Connection"}
            buttonTextSize="text-xs"
            handleOnClick={handleOnClickDeleteProvider}
            disabled={
              !userRoles.permissions.includes(
                "provider.delete_providerclientstatus"
              )
            }
          />
        </Stack>
      </div>
    </div>
  );
}
