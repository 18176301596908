import Title from "../../components/Title";

export default function ProviderDetailsContainer({
  children,
  title,
  horizontalPadding = "p-4",
  verticalPadding = "p-4",
}) {
  return (
    <div
      className={`${horizontalPadding} ${verticalPadding} bg-gray-100 rounded-md w-full`}
    >
      <Title text={title} textCase="uppercase" size="text-xs" />
      {children}
    </div>
  );
}
