import React from "react";
import NotificationsPage from "./NotificationPage";

const Notifications = () => {
  return (
    <NotificationsPage/>
  );
};

export default Notifications;
