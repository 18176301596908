const TextCounter = ({ title, value }) => {
  return (
    <div className="text-[0.85rem] m-1 flex w-[100%]">
      <div data-testid="textcounter-title" className="w-[80%] text-left">
        {title ? title : "No Data"}
      </div>
      <div data-testid="textcounter-value" className="w-[20%] text-right">
        {value}
      </div>
    </div>
  );
};

export default TextCounter;
