import { useState, Fragment, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingButton from "./LoadingButton";
import axios from "../../api/axios";
import RescheduleAppointment from "../Dashboard/RescheduleAppointment";

const DEFAULT_ERR_MSG = "Something wrong! Contact your ITN Representative.";
const YES_OPTION = "Attended";
const NO_OPTION = "Absences";
const RESCHEDULE_OPTION = "Rescheduled";

function AppointmentConfirmation() {
  const [params] = useSearchParams();
  const token = params.get("temporary_token");
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [optionClicked, setOptionClicked] = useState(true);

  const onSubmit = async (value) => {
    const dataSubmit = {
      confirm: value,
    };
    setIsSubmitting(true);
    try {
      const res = await axios.patch(
        `/api/appointments-confirmation/${id}/?temporary_token=${token}`,
        dataSubmit
      );
      setOptionClicked(value === YES_OPTION);
      setMessage(res.data.detail);
    } catch (err) {
      setMessage(err.response?.data?.detail || DEFAULT_ERR_MSG);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(`/api/appointments-confirmation/${id}/`, {
          params: { temporary_token: token },
        });
        if (res.data?.status === YES_OPTION || res.data?.status === RESCHEDULE_OPTION) {
          setOptionClicked(true);
          setMessage(res?.data?.detail);
        } else if (res.data?.status === NO_OPTION) {
          setOptionClicked(false);
        }
      } catch (err) {
        setMessage(err.response?.data?.detail || DEFAULT_ERR_MSG);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [token, id]);

  return (
    <div className="max-w-3xl w-full m-auto pb-16 pt-0 min-h-screen flex">
      <div className="flex flex-col gap-10 m-auto">
        {optionClicked ? (
          <div className="flex flex-col gap-10 items-center text-center px-8">
            <p className="text-2xl font-bold">Appointment Confirmation</p>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Fragment>
                <p className="text-lg">
                  {message ||
                    `We hope your appointment went well today. Please confirm if you were able to attend your appointment by selecting "YES" or "NO".`}
                </p>
                {!message && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                    <LoadingButton
                      onSubmit={() => onSubmit(YES_OPTION)}
                      color="success"
                      isLoading={isSubmitting}
                    >
                      <span>Yes</span>
                    </LoadingButton>
                    <LoadingButton
                      onSubmit={() => onSubmit(NO_OPTION)}
                      color="error"
                      isLoading={isSubmitting}
                    >
                      <span>No - Reschedule</span>
                    </LoadingButton>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        ) : (
          <RescheduleAppointment id={id} setOptionClicked={setOptionClicked} />
        )}
      </div>
    </div>
  );
}

export default AppointmentConfirmation;
