import BaseField from "../../ui/BaseField";
import FormatPhoneNumber from "../../utils/FormatPhoneNumber";
import ValueWithFallback from "../../ui/ValueWithFallback";

const PhoneNumber = ({ fieldName, number, flag, clock }) => {
  return (
    <BaseField
      id="SB-Phone"
      testId="SB-Phone"
      fieldName={fieldName}
      label={null}
      flag={flag}
      clock={clock}
    >
      {ValueWithFallback(FormatPhoneNumber(number))}{" "}
    </BaseField>
  );
};

export default PhoneNumber;
