import ActiveRolesDashFilter from "./components/content/ActiveRolesDashFilter";
import CaseStatusDashFilter from "./components/content/CaseStatusDashFilter";
import MedicalStatusDashFilter from "./components/content/MedicalStatusDashFilter";
import OtherPagesDashFilter from "./components/content/OtherPagesDashFilter";
import DashboardCard from "./components/DashboardCard";
import DashboardCardFilters from "./components/DashboardCard/DashboardCardFilters";

export default function DashboardHeaderFilters() {
  return (
    <div id="Dashboard" data-testid="Dashboard">
      <div id="Dashboard-Content">
        <div id="Dashboard-Filters">
          <DashboardCardFilters>
            <DashboardCard title={"Active Roles"}>
              <ActiveRolesDashFilter />
            </DashboardCard>
            <DashboardCard title={"Case Status"}>
              <CaseStatusDashFilter />
            </DashboardCard>
            <DashboardCard title={"Medical Status"}>
              <MedicalStatusDashFilter />
            </DashboardCard>
            <DashboardCard title={"Other Pages"}>
              <OtherPagesDashFilter />
            </DashboardCard>
          </DashboardCardFilters>
        </div>
      </div>
    </div>
  );
}
