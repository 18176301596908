import React, { useContext, useEffect, useState } from "react";
import {
  PDDispatchContext,
  PDStateContext,
} from "../../context/ProviderDetailProvider";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import { Tooltip, Rating as MuiRating } from "@mui/material";
import { Warning } from "@mui/icons-material";
import ResponseModal from "../../../../../../global/ResponseModal";
import { setTrigger } from "../../state/actions";
import PropTypes from "prop-types";

const ProvRating = ({ starStyle }) => {
  const { provider: providerInformation, trigger } = useContext(PDStateContext);
  const { accessToken } = useContext(DataContext);
  const dispatch = useContext(PDDispatchContext);

  const [state, setState] = useState({
    rating: undefined,
    responseModal: false,
    responseBreakdown: "",
    isError: false,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      rating: providerInformation.rating,
    }));
  }, [providerInformation.rating]);

  const handleRatingChange = async (event, newValue) => {
    try {
      await axios.patch(
        `/api/provider/${event.target.name}/`,
        { rating: newValue },
        { headers: { Authorization: `Token ${accessToken}` } }
      );
      setState((prev) => ({
        ...prev,
        rating: newValue,
        responseModal: true,
        responseBreakdown: "Provider Rating Updated",
        isError: false,
      }));
      setTrigger(dispatch, !trigger);
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isError: true,
        responseModal: true,
        responseBreakdown: ApiRequestErrorHandler(error.response),
      }));
    }
  };

  const closeResModal = () => {
    setState((prev) => ({
      ...prev,
      responseModal: false,
      responseBreakdown: "",
    }));
  };

  return (
    <>
      <MuiRating
        name={`${providerInformation.pk}`}
        value={state.rating || 0}
        className={`flex justify-center ${starStyle}`}
        onChange={handleRatingChange}
      />
      {!state.rating && (
        <Tooltip title="Not yet rated">
          <Warning style={{ color: "red" }} className={`text-sm`} />
        </Tooltip>
      )}
      <ResponseModal
        title={state.isError ? "Failed" : "Successful"}
        isError={state.isError}
        description={!state.isError ? state.responseBreakdown : ""}
        openBool={state.responseModal}
        setOpenBool={(open) =>
          setState((prev) => ({ ...prev, responseModal: open }))
        }
        errorMessage={state.responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </>
  );
};

ProvRating.propTypes = {
  starStyle: PropTypes.string,
};

export default ProvRating;
