import { SET_PROVIDER, SET_TRIGGER } from "./actions";

export const initialState = {
  provider: null,
  trigger: false,
};

export function pdReducer(state, action) {
  switch (action.type) {
    case SET_PROVIDER: {
      return { ...state, provider: action.payload };
    }
    case SET_TRIGGER: {
      return { ...state, trigger: action.payload };
    }
    default:
      return state;
  }
}
