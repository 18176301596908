import { useContext } from "react";
import NotificationIcon from "../../../Dashboard/FullPages/Notifications/NotificationIcon/NotificationIcon";
import RenderSubMenu from "./renderSubMenu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { EventsStateContext } from "../../../../../context/EventsProvider";

export default function RenderActionButtons() {
  const actionButtonList = [];
  const globalEventDispatch = useContext(EventsStateContext);

  actionButtonList.push({
    link: "/notifications",
    content: globalEventDispatch.notifications.count,
    icon: <NotificationIcon />,
  });

  actionButtonList.push({
    icon: (
      <AccountCircleIcon className="text-white text-2xl hover:cursor-pointer" />
    ),
    subMenuList: RenderSubMenu,
  });

  return actionButtonList;
}
