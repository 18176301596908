import { TOGGLE_FETCH_MEDICAL_STATUS } from "./actions";

export const initialState = {
  getMedicalStatus: true,
};

export function dashboardReducer(state, action) {
  switch (action.type) {
    case TOGGLE_FETCH_MEDICAL_STATUS: {
      return { ...state, getMedicalStatus: action.payload };
    }
    default:
      return state;
  }
}
