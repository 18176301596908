import PermissionWrapper from "../../../../../../../../../../global/PermissionWrapper";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../../../../../context/DataContext";
import { useQuery } from "@tanstack/react-query";
import { STRINGS } from "../../../../components/utils/strings";
import { provider } from "../../../../../../../../../../api/endpoints/provider";
import { SelectOptionsDataProcessor } from "../../../../../../../../../../global/SelectOptionsDataProcessor";
import { ProviderListStateContext } from "../../../../context/ProviderListProvider";
import Colors from "../../../../../../../../../../global/Colors";
import { FormControl, MenuItem, Select } from "@mui/material";
import Title from "../../../../../components/Title";

export default function DocumentStatus({
  canEdit,
  title,
  index,
  fieldName,
  initialValue,
  updateProviderInfo,
  subTitle,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [options, setOptions] = useState(null);
  const providerListState = useContext(ProviderListStateContext);
  const [initialLoad, setInitialLoad] = useState(false);
  const [document, setDocument] = useState("");

  const perms = {
    change_providerclientstatus: userRoles.permissions.includes(
      "provider.change_providerclientstatus"
    ),
    utilities_view_fieldoptions: userRoles.permissions.includes(
      "utilities.view_fieldoptions"
    ),
    //canEdit: canEdit,
  };

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ["FieldOptions-Medical-Documents-Status"],
    queryFn: async () => {
      // if(!perms.utilities_view_fieldoptions) return [];
      const response =
        provider.getMedicalDocumentStatusFieldOptions(accessToken);
      return await response;
    },
    select: (data) => SelectOptionsDataProcessor(data.results, "No Selection"),
  });

  const isDisabled = canEdit ? false : true;

  const handleSetDocument = (event) => {
    let value = event.target.value;
    setDocument(value);

    let documentValue = value;

    let editableData = null;

    const existingFields =
      providerListState.providersData.get(index).editableFields;
    if (documentValue === initialValue) {
      if (existingFields && Object.keys(existingFields).length > 1) {
        editableData = {
          ...existingFields,
        };
        delete editableData[fieldName];
      }
      const updatedData = new Map(providerListState.providersData);
      updatedData.set(index, {
        ...updatedData.get(index),
        editableFields: editableData,
      });
      updateProviderInfo(updatedData);
      return;
    }
    if (!existingFields || documentValue !== initialLoad) {
      editableData = {
        ...existingFields,
        [fieldName]: documentValue,
      };
    }
    const updatedData = new Map(providerListState.providersData);
    updatedData.set(index, {
      ...updatedData.get(index),
      editableFields: editableData,
    });
    updateProviderInfo(updatedData);
  };
  /**
   * Use Effect for setting selected option when optionlist is available*/

  useEffect(() => {
    let active = true;
    if (active) {
      if (initialLoad === false) {
        setDocument(initialValue);
        setInitialLoad(true);
      }
    }
    return () => {
      active = false;
    };
  }, [options, data, initialValue, initialLoad]);

  if (isPending) return STRINGS.LOADING + "...";

  if (error)
    return (
      <div className="text-xs text-red-500">
        {STRINGS.ERROR + ": " + error.message}
      </div>
    );

  return (
    <div
      id="Provider-Tab-Document-Status"
      data-testid="Provider-Tab-Document-Status"
      className="w-full"
    >
      <PermissionWrapper permission={perms.utilities_view_fieldoptions}>
        <div>
          <Title
            text={title}
            size={"text-xs"}
            horizontalPadding="pl-2"
            className="w-full block"
          />
          {subTitle && (
            <Title
              text={"(" + subTitle + ")"}
              size={"text-[0.65rem]"}
              color="text-gray-400"
              fontStyle="normal-case"
              horizontalPadding="pl-2"
            />
          )}
        </div>
        <FormControl sx={{ marginTop: "0.5rem", width: 160 }} size="small">
          <Select
            labelId="Provider-Tab-Document-Status-label"
            id="Provider-Tab-Document-Status-select"
            className="w-full"
            value={document}
            label={title}
            onChange={handleSetDocument}
            InputLabelProps={{ shrink: false }}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              "&.Mui-disabled": {
                backgroundColor: isDisabled ? Colors.gray[200] : Colors.white,
              },
              padding: "1px",
              fontSize: "0.85rem",
              borderRadius: "50px",
              backgroundColor: Colors.white,
            }}
            disabled={isDisabled}
          >
            {data &&
              data.length > 0 &&
              data.map((docStat) => (
                <MenuItem key={docStat.id} value={docStat.id}>
                  {docStat.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </PermissionWrapper>
    </div>
  );
}
