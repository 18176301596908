import ApiServices from "../service";

export const user = {
  getGenderFieldOptions: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=user&field_name=gender&ordering=order`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getNamePrefixFieldOptions: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=user&field_name=name_prefix&ordering=order`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  getManagedUsers: (accessToken, pagination = null) => {
    let paginate = pagination ? `${pagination}` : "";

    let query = paginate ? "?" : "";
    let deliminator = paginate ? "&" : "";
    return ApiServices.getData(
      `/api/users/managed_users/${query}${deliminator}${paginate}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err));
  },
  getAssignedTeams: (userId, queryParams, accessToken) =>
    ApiServices.getData(
      `api/team_members/?user=${userId}${queryParams}&ordering=law_firm_name`,
      accessToken
    ),

  getLawFirmTeams: (queryParams, accessToken) =>
    ApiServices.getData(`api/teams/${queryParams}`, accessToken),

  getLawFirmTeamsUserIsNotAMemberOf: (userId, queryParams, accessToken) =>
    ApiServices.getData(
      `api/teams/?members_not__in=${userId}${queryParams}&ordering=law_firm_name`,
      accessToken
    ),

  addUserToLawFirmTeams: (data, accessToken) =>
    ApiServices.postData(`api/team_members/`, data, accessToken),

  deleteUserFromLawFirmTeams: (data, accessToken) =>
    ApiServices.postData(
      `api/team_members/remove_team_members/`,
      data,
      accessToken
    ),

  getActiveRolesStats: (accessToken) =>
    ApiServices.getData("api/case/user_stats/", accessToken),

  getCaseStatusStats: (accessToken) =>
    ApiServices.getData("api/case/user_stats/", accessToken),

  getMedicalStatusStats: (accessToken, params) =>
    ApiServices.getData(`api/case/user_stats/${params}`, accessToken),

  getNotifications: (accessToken, params) =>
    ApiServices.getData(`api/core/notifications/${params}`, accessToken),

  getNotificationFilters: (accessToken) =>
    ApiServices.getData("api/core/notifications/user_stats/", accessToken)
      .then((res) => res)
      .then((res) => res["notification_type_counts"])
      .catch((err) => err),

  markReadNotifications: (accessToken, data) =>
    ApiServices.postData(`api/core/notifications/read/`, data, accessToken),
};
