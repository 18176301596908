import { useContext, useState } from "react";
import Colors from "../../../../../global/Colors";
import { DashboardFilterDispatchContext } from "../context/DashboardFilterContext";
import { toggleFetchMedicalStatus } from "../state/actions";
import SearchContext from "../../../../../../context/SearchContext";

const ButtonCounter = ({
  title,
  value,
  statusType,
  disabled,
  color = "slate",
  isActive=false,
}) => {
  const [active, setActive] = useState(isActive);
  const [hover, setHover] = useState("hover:bg-white hover:text-black");
  const dispatch = useContext(DashboardFilterDispatchContext);
  const {
    selectedStatuses,
    selectedCaseStatuses,
    setActiveCaseStatuses,
    setSelectedCaseStatuses,
    setActiveStatuses,
    setSelectedStatuses,
  } = useContext(SearchContext);

  const handleClick = (title) => {
    if (!disabled) {
      let arr;
      if (statusType === "overall_status") {
        arr =
          typeof selectedCaseStatuses === "string"
            ? selectedCaseStatuses.split(",")
            : selectedCaseStatuses;
      } else {
        arr =
          typeof selectedStatuses === "string"
            ? selectedStatuses.split(",")
            : selectedStatuses;
      }

      let newArr = [];
      if (!active) {
        newArr = arr.map((item) => item);

        newArr.push(title);
      } else {
        newArr = arr.filter((selected) => selected !== title);
      }
      if (statusType === "overall_status") {
        setSelectedCaseStatuses(newArr);
        setActiveCaseStatuses(newArr);
        
      } else {
        setSelectedStatuses(newArr);
        setActiveStatuses(newArr);
      }
      toggleFetchMedicalStatus(dispatch, true);
      setActive(!active);
    }
  };
  return (
    <div
      className={
        "m-1 text-xs text-center rounded-lg shadow-lg w-[46.5%] " + hover
      }
      role="button"
      tabIndex={0}
      aria-disabled={disabled}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        padding: "4px",
        border: "1px solid ",
        borderRadius: "4px",
        display: "inline-block",
        textAlign: "center",
        color: active ? Colors.white : Colors.black,
        backgroundColor: active ? Colors[color][700] : Colors.white,
        borderColor: active ? Colors[color][700] : Colors.black,
      }}
      onClick={() => handleClick(title)}
      onMouseOver={() => setHover("hover:bg-pink-400 hover:text-white")}
      onMouseOut={() => setHover("hover:bg-white hover:text-black")}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleClick(title);
        }
      }}
    >
      <div data-testid="buttoncounter-title" className="w-full text-center">
        {title}
      </div>
      <div data-testid="buttoncounter-value" className="w-full text-center">
        {value}
      </div>
    </div>
  );
};

export default ButtonCounter;
