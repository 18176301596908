import React from "react";

const PermissionWrapper = ({ permission, children, alternateChildren = null }) => {
  if (permission) {
    return <>{children}</>;
  }

  return <>{alternateChildren}</>;
};

export default PermissionWrapper;
