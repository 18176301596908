import NotificationsIcon from "@mui/icons-material/Notifications";
const NotificationIcon = () => {
  return (
      <NotificationsIcon
        id="Notification-Icon"
        data-testid="Notification-Icon"
        className="text-white text-2xl hover:cursor-pointer"
      />
  );
};
export default NotificationIcon;
