import { CircularProgress } from "@mui/material";
import Title from "../components/Title";
import AccordionList from "../../../../../../ui library/components/accordions/AccordionList";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Accordion from "../../../../../../ui library/components/accordions/Accordion";
import { useContext, useState } from "react";
import ModalShell from "../../../../../../global/ModalShell";
import AddClientProviderModal from "../AddClientProviderModal";
import ProviderHeaderIcon from "../../../../../../ui library/icons/ProviderHeaderIcon";
import ProviderListDetailsv2 from "./components/CustomProviderAccordion/ProviderListDetailsv2";
import ProviderListSummaryv2 from "./components/CustomProviderAccordion/ProviderListSummaryv2";
import DeleteProviderv2 from "../DeleteProviderv2";
import ProviderDetailv2 from "../../../../../../global/ProviderDetailModal/ProviderDetailv2";
import { GenerateQueryParameterString } from "../../../../../../global/GenerateQueryParamString";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../context/DataContext";
import { STRINGS } from "./components/utils/strings";
import { provider } from "../../../../../../api/endpoints/provider";
import { ClientDetailContext } from "../../context/ClientDetailContext";
import FullyRoundedButton from "../../../../../../ui library/components/buttons/FullyRoundedButton";
import CustomIconButton from "../../../../../../ui library/components/buttons/CustomIconButton";
import {
  ProviderListDispatchContext,
  ProviderListStateContext,
} from "./context/ProviderListProvider";
import { setProvidersData, setShouldFetchData } from "./state/actions";
import ResponseModal from "../../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";

export default function ProviderListv2WithState({ caseInfo }) {
  const { accessToken } = useContext(DataContext);
  const providerListDispatchContext = useContext(ProviderListDispatchContext);
  const providerListStateContext = useContext(ProviderListStateContext);
  const resLimit = 25;
  const [provResLimit, setProvResLimit] = useState(25);
  const [proCliResponse, setProCliResponse] = useState(null);
  const [showMoreProvVisible, setShowMoreProvVisible] = useState(false);
  const [providerData, setProviderData] = useState([]);
  const [openAddProv, setOpenAddProv] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openProvDetail, setOpenProvDetail] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [proCliTrigger, setProCliTrigger] = useState(false);
  const { reFetchStaticBar, setReFetchStaticBar } =
    useContext(ClientDetailContext);
  const params = useParams();
  const accordionData = new Map();

  const handleAddProvider = () => {
    setOpenAddProv(true);
  };

  const handleShowMoreBtnClick = () => {
    setShouldFetchData(providerListDispatchContext, true);
    setProvResLimit(provResLimit + resLimit);
  };

  const handleSuccess = (msg = "") => {
    setResponseModal(true);
    setResponseBreakdown(msg);
    setIsError(false);
  };

  const handleError = (error) => {
    let errArr = ApiRequestErrorHandler(error.response.data);
    setIsError(true);
    setResponseModal(true);
    setResponseBreakdown(errArr);
  };

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setShouldFetchData(providerListDispatchContext, true);
  };

  const toggleProCliTrigger = () => {
    setShouldFetchData(providerListDispatchContext, true);
  };

  const setAllTriggers = () => {
    setReFetchStaticBar(true);
    setShouldFetchData(providerListDispatchContext, true);
  };

  const {
    isLoading: isLoadingProCliData,
    error: ProCliError,
    isFetching: isFetchingProCliData,
  } = useQuery({
    queryKey: ["provider-client-data", { provResLimit, proCliTrigger }],
    queryFn: async () => {
      const response = provider
        .getFilteredProviderClientStatus(
          accessToken,
          GenerateQueryParameterString({
            case: params.id,
            limit: provResLimit,
          })
        )
        .then(async (res) => {
          let data = res.results;
          if (res.count <= provResLimit) {
            setShowMoreProvVisible(false);
          } else {
            setShowMoreProvVisible(true);
          }
          setProvidersData(
            providerListDispatchContext,
            createAccordionSummaryData(data)
          );
          setShouldFetchData(providerListDispatchContext, false);
          return res;
        })
        .catch((err) => err);
      return await response;
    },
    enabled: providerListStateContext.shouldFetchData,
  });

  const getBgSummaryColor = (provider) => {
    if (provider && provider.in_network) {
      if (provider.receiving_clients) return "bg-green-100";
      else return "bg-yellow-100";
    }
    return "bg-blue-100";
  };

  const createAccordionSummaryData = (data) => {
    data.map((proCliElement, index) => {
      accordionData.set(proCliElement.pk, {
        index: proCliElement.pk,
        bgSummaryColor: getBgSummaryColor(proCliElement?.provider),
        proCliData: proCliElement,
        handleSuccess: handleSuccess,
        handleError: handleError,
        setProviderData: setProviderData,
        setOpenProvDetail: setOpenProvDetail,
        setOpenDeleteModal: setOpenDeleteModal,
        editableFields: null,
      });
    });
    setProCliResponse(accordionData);
    return accordionData;
  };
  return (
    <div className="sm:h-[60vh] overflow-y-hidden relative">
      <div className="h-full overflow-y-auto p-1">
        <div className="px-8">
          <Title text={"Providers"} />
        </div>

        <div
          data-test-id="provider-page-introduction"
          className="flex flex-row p-4 items-center"
        >
          <div className="px-4">
            <ProviderHeaderIcon />
          </div>
          <div className="flex flex-row items-end flex-1">
            <div className="flex flex-col pr-8">
              <div id="provider-page-info-header">
                <Title text={"Provider Client Relationship"} />
              </div>
              <div id="provider-page-info">
                <h1 className="text-sm font-medium">
                  A strong Provider-Client Relationship thrives on trust,
                  communication, and efficiency fostering long-term partnerships
                  that improve patient outcomes and business success.
                </h1>
              </div>
            </div>
            <div>
              <FullyRoundedButton
                buttonText="+&nbsp;Add&nbsp;Provider"
                handleOnClick={handleAddProvider}
              />
            </div>
          </div>
        </div>

        <div className="px-5 w-full py-4">
          {(isFetchingProCliData && !proCliResponse) ? (
            <div className="w-full flex items-center justify-center h-full p-10">
              <CircularProgress color="secondary" />
            </div>
          ) : proCliResponse && proCliResponse.size === 0 ? (
            <div className="w-full">
              <p className="text-lg text-center my-2 text-black font-bold">
                No providers found
              </p>
            </div>
          ) : (
            <>
              <AccordionList
                accordionListData={proCliResponse}
                accordionVariant="default"
                summaryContent={ProviderListSummaryv2}
                expandedContent={<ProviderListDetailsv2 />}
                expandIcon={<ExpandMore className="text-blue-500" />}
                shrinkIcon={<ExpandLess className="text-blue-500" />}
              >
                <Accordion />
              </AccordionList>
              <CustomIconButton
                iconVisible={showMoreProvVisible}
                handleOnClick={handleShowMoreBtnClick}
              />
            </>
          )}
          {ProCliError && STRINGS.PROCLI_LIST_ERROR}
        </div>

        {openProvDetail ? (
          <ProviderDetailv2
            providerDetail={providerData}
            open={openProvDetail}
            setOpen={setOpenProvDetail}
          />
        ) : (
          ""
        )}

        {openDeleteModal ? (
          <ModalShell
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            title={"Delete Provider"}
            color={"#d32f2f"}
            customWidth={"md"}
          >
            <DeleteProviderv2
              providerDetail={providerData}
              setDeleteFormDisplay={setOpenDeleteModal}
              trigger={proCliTrigger}
              setTrigger={setAllTriggers}
              caseInfo={caseInfo}
            />
          </ModalShell>
        ) : (
          ""
        )}

        <ModalShell
          open={openAddProv}
          setOpen={setOpenAddProv}
          color="#1976d2"
          title="Add Provider"
          fullWidth
        >
          <AddClientProviderModal
            setProvFormDisplay={setOpenAddProv}
            trigger={proCliTrigger}
            setTrigger={toggleProCliTrigger}
            caseInfo={caseInfo}
          />
        </ModalShell>

        {!responseModal ? (
          ""
        ) : (
          <ResponseModal
            title={`${isError ? "Failed" : "Successful"}`}
            isError={isError}
            description={isError ? "" : responseBreakdown}
            openBool={responseModal}
            setOpenBool={setResponseModal}
            errorMessage={responseBreakdown}
            handleCloseFunc={closeResModal}
          />
        )}
      </div>
    </div>
  );
}
