import CardContent from "./CardContent";
import CardHeader from "./CardHeader";

export default function DashboardCard({
  title,
  children,
  gridColumns = 1,
  span = 1,
}) {
  const columns = "grid-cols-" + gridColumns;
  const colspan = "col-span-" + span;

  return (
    <div
      className={`border-box h-60  min-w-[245px] bg-white shadow-md flex flex-col bg-white ${colspan} m-2`}
    >
      <CardHeader title={title} />
      <CardContent columns={columns}>{children}</CardContent>
    </div>
  );
}
