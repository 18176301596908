import React, { useContext } from "react";
import { setCurrentPage, setOffset, setShouldFetchNotifications } from "../../state/actions";
import { Stack } from "@mui/material";
import {
  NotificationPageDispatchContext,
  NotificationPageStateContext,
} from "../../context/NotificationsPageProvider";
import PaginationControlsV2 from "../../../../../../../global/PaginationControlsV2";

const Pagination = () => {
  const state = useContext(NotificationPageStateContext);
  const dispatch = useContext(NotificationPageDispatchContext);

  const handleChange = (event, value) => {
    setCurrentPage(dispatch, value);
    if (value === 1) {
      setOffset(dispatch, state.notifications.api.filters.resLimit);
    }
    setOffset(dispatch, state.notifications.api.filters.resLimit * (value - 1));
    setShouldFetchNotifications(dispatch, true);
  };
  return (
    <div id="Pagination">
      {
        <Stack alignItems={"center"}>
          <PaginationControlsV2
            alignment={"center"}
            resultCount={state.notifications.resultCount.total}
            handleChange={handleChange}
            currentPage={state.notifications.currentPage}
            resultLimit={state.notifications.api.filters.resLimit}
          />
        </Stack>
      }
    </div>
  );
};
export default Pagination;
