import { Button } from "@mui/material";
import Colors from "../../../global/Colors";

export default function HyperLinkButton({
  buttonText,
  horizontalPadding = "p-0",
  verticalPadding = "p-0",
  buttonTextSize = "text-sm",
  handleOnClick,
  disabled = false
}) {
  return (
    <Button
      disabled = {disabled}
      variant="text"
      disableElevation
      className={`transparent ${buttonTextSize} ${horizontalPadding} ${verticalPadding} normal-case text-blue-600 underline hover:cursor-pointer hover:bg-none hover:underline`}
      onClick={() => handleOnClick()}
      sx={{
        "&:hover": {
          bgcolor: "transparent",
          color: Colors.blue[700],
        },
        "&:active": {
          boxShadow: "none",
          backgroundColor: Colors.white,
          borderColor: Colors.white,
        },
        "&:focus": {
          boxShadow: "none",
          backgroundColor: "transparent",
          color: "bg-purple-700",
        },
      }}
    >
      {buttonText}
    </Button>
  );
}
