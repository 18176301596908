import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipStyling } from "../utils/TooltipStyling";

const NotificationTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement="bottom-end"
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -12],
            },
          },
        ],
      },
    }}
    arrow
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
}));

export default NotificationTooltip;
