import { useState } from "react";
import PropTypes from "prop-types";

import TabHeader from "./TabHeader";
import TabBody from "./TabBody";

export default function TabPanel({
  tabList,
  headerBoxBackgroundColor,
  headerTabVariant,
  headerTabIndicatorSx,
  headerTabSx,
}) {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (_, newValue) => {
    setTabValue(newValue);
  };

  if (!tabList?.length) return null;
  return (
    <div className="flex-1 h-full flex flex-col items-stretch transparent">
      <TabHeader
        tabList={tabList}
        tabValue={tabValue}
        handleChange={handleChange}
        boxBackgroundColor={headerBoxBackgroundColor}
        tabVariant={headerTabVariant}
        tabIndicatorSx={headerTabIndicatorSx}
        tabsSx={headerTabSx}
      />
      <TabBody tabList={tabList} tabValue={tabValue} />
    </div>
  );
}

TabPanel.propTypes = {
  tabList: PropTypes.array,
  headerBoxBackgroundColor: PropTypes.string,
  headerTabVariant: PropTypes.string,
  headerTabIndicatorSx: PropTypes.object,
  headerTabSx: PropTypes.object,
};
