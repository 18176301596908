import { isToday, isYesterday } from "date-fns";
import { SET_NOTIFICATION_COUNT } from "../../../../../../../state/actions.js";
import {
  CURRENT_PAGE_SET,
  ORDERING_BY_SET,
  ORDERING_DIR_SET,
  RESLIMIT_SET,
  RESULT_COUNT_SET,
  SET_NOTIFICATION_FILTER_TYPE,
  SET_NOTIFICATION_READUNREAD_BUTTON_TOGGLE,
  SET_NOTIFICATION_READUNREAD_TOGGLE,
  SET_NOTIFICATIONS,
  SET_OFFSET,
  SET_SHOULD_FETCH_NOTIFICATIONS,
  SET_SHOULD_FETCH_NOTIFICATIONS_FILTERS,
} from "./actions";

export const initialState = {
  shouldFetchNotifications: true,
  shouldFetchNotificationFilters: true,
  loading: false,
  success: null,
  error: null,
  errorArray: [],
  notifications: {
    count: 0,
    data: null,
    currentPageIsSeen: false,
    api: {
      filters: {
        type: [],
        isRead: 1,
        resLimit: 25,
        offset: 0,
        ordering: "-notification_sent",
      },
    },
    resultCount: { total: 0, current: 0 },
    currentPage: 1,
    isReadButton: false,
    ui: {
      areAllListsEmpty: true,
      notificationsList: {
        today: [],
        yesterday: [],
        older: [],
      },
    },
  },
};

export function notificationPageReducer(state, action) {
  switch (action.type) {
    case SET_SHOULD_FETCH_NOTIFICATIONS: {
      return {
        ...state,
        shouldFetchNotifications: action.payload,
      };
    }
    case SET_SHOULD_FETCH_NOTIFICATIONS_FILTERS: {
      return {
        ...state,
        shouldFetchNotificationFilters: action.payload,
      };
    }
    case SET_NOTIFICATIONS: {
      let newToday = [];
      let newYesterday = [];
      let newOlder = [];
      action.payload.forEach((listItem) => {
        if (isToday(new Date(listItem.notification_sent))) {
          newToday.push(listItem);
        } else if (isYesterday(new Date(listItem.notification_sent))) {
          newYesterday.push(listItem);
        } else {
          newOlder.push(listItem);
        }
      });
      let isAllListEmpty =
        newToday.length === 0 &&
        newYesterday.length === 0 &&
        newOlder.length === 0
          ? true
          : false;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          shouldFetchNotifications: false,
          ui: {
            notificationsList: {
              today: newToday,
              yesterday: newYesterday,
              older: newOlder,
            },
            areAllListsEmpty: isAllListEmpty,
          },
        },
      };
    }
    case SET_NOTIFICATION_COUNT: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          count: action.payload,
        },
      };
    }
    case SET_NOTIFICATION_FILTER_TYPE: {
      let typeArr = [...state.notifications.api.filters.type];
      if (action.payload.selected) {
        typeArr.push(action.payload.key);
      } else {
        typeArr = typeArr.filter((item) => item !== action.payload.key);
      }
      return {
        ...state,
        notifications: {
          ...state.notifications,
          api: {
            ...state.notifications.api,
            filters: {
              ...state.notifications.api.filters,
              type: typeArr,
            },
          },
        },
      };
    }
    case RESLIMIT_SET: {
      return { ...state, resLimit: action.payload };
    }
    case RESULT_COUNT_SET: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          resultCount: action.payload,
        },
      };
    }
    case SET_OFFSET: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          api: {
            ...state.notifications.api,
            filters: {
              ...state.notifications.api.filters,
              offset: action.payload,
            },
          },
        },
      };
    }
    case CURRENT_PAGE_SET: {
      return {
        ...state,
        notifications: {
          ...state.notifications,

          currentPage: action.payload,
        },
      };
    }
    case ORDERING_BY_SET: {
      return { ...state, orderingBy: action.payload };
    }
    case ORDERING_DIR_SET: {
      return { ...state, orderingDirection: action.payload };
    }
    case SET_NOTIFICATION_READUNREAD_TOGGLE: {
      // let bool = action.payload ? true :null;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          api: {
            ...state.notifications.api,
            filters: {
              ...state.notifications.api.filters,
              isRead: action.payload,
            },
          },
        },
      };
    }
    case SET_NOTIFICATION_READUNREAD_BUTTON_TOGGLE: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isReadButton: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
