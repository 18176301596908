import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { ProviderTableRow } from "./ProviderTableRow";
import OrderingArrow from "../../../../global/OrderingArrow";

export const columns = [
  "Name",
  "Address",
  "Rating",
  "In Network",
  "Provider Types",
  "Options",
];

export const ProviderList = ({
  data,
  orderingVal,
  setOrderingVal,
}) => {
  const navigate = useNavigate();

  const handleOnDetailClick = (pk) => {
    navigate(`/provider/${pk}`);
  };

  return (
    <TableContainer
      component={Paper}
      className="relative max-h-[70vh]"
    >
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => {
              if (idx === 0) {
                return (
                  <TableCell
                    key={column}
                    align="center"
                    className="sticky left-0 z-20 bg-white whitespace-nowrap shadow-[-1px_-5px_6px_lightgray]"
                  >
                    {column}
                    <OrderingArrow
                      orderingVal={orderingVal}
                      setOrderingVal={setOrderingVal}
                      val={"name"}
                    />
                  </TableCell>
                );
              }
              if (idx === columns.length - 1) {
                return (
                  <TableCell
                    key={column}
                    align="center"
                    className="sticky left-0 z-20 bg-white whitespace-nowrap shadow-[2px_-5px_6px_lightgray]"
                  >
                    {column}
                  </TableCell>
                );
              }

              return (
                <TableCell
                  key={column}
                  align="center"
                  className="whitespace-nowrap"
                >
                  {column}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 &&
            data.map((row) => (
              <ProviderTableRow
                key={row.pk}
                data={row}
                onDetailClick={handleOnDetailClick}
              />
            ))}
          {data?.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                data-testid="no-providers-found"
              >
                No Providers Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProviderList.propTypes = {
  data: PropTypes.array,
  orderingVal: PropTypes.string,
  setOrderingVal: PropTypes.func,
};
