/* Checks the can_edit property before perforaming operations. can_edit is a property on the pro_cli_status object that determines if a user can edit the relationship.
can_edit can determine if user has access to pro cli item to determine if provider can see the
procli item returned

*/ /**
 *
 *
 * @param {Array} data
 * @param {boolean} isProvider - can pass in a check to do a separate operation if isProvider is true
 * @param {string} defaultValue - Default option with label and "" for id if a "Choose Selection" or "Select One" option is needed.
 * @return {*}
 */
export const SelectOptionsProCliStatusDataProcessorRole = (
  data,
  isProvider,
  defaultValue = null
) => {
  let newArr = defaultValue ? [{ id: "", name: defaultValue }] : [];
  for (let i = 0; i < data.length; i++) {
    if (isProvider) {
      if (data[i].can_edit) {
        newArr.push({ id: data[i].provider.pk, name: data[i].provider.name });
      }
    } else {
      newArr.push({ id: data[i].provider.pk, name: data[i].provider.name });
    }
  }
  return newArr;
};
