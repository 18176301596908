import React from "react";
import ActionButtonList from "./components/ActionButtons/ActionButtonList";

export const Banner = () => {
  return (
    <div className="flex flex-col justify-evenly w-full min-w-fit">
      <div data-testid="banner-gradient-2" className="w-full min-w-fit h-16 bg-gradient-to-r from-[#5248E6] to-[#2E2880] flex flex-row items-center justify-between w-full">
        <div>
        <img
          alt="NetProApp Banner"
          src="/assets/NetProApp_banner_logo.png"
          className="w-72 2xl:w-[346px] md:ml-11"
        />
        </div>
        <ActionButtonList/>
      </div>
      <div data-testid="banner-gradient-1" className="w-full h-2 bg-gradient-to-r from-[#F58626] to-[#F05A22]"></div>
    </div>
  );
};

