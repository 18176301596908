import { Typography } from "@mui/material";

export default function FieldInfo({ title, value }) {
  const shouldHideTitle = () => {
    if (value === null || value === "" || value === " ") {
      return true;
    }
    return false;
  };
  return (
    <div className="w-full">
      <Typography className="pl-2 pt-2">
        {!shouldHideTitle() && (
          <div className="text-[0.65rem] text-gray-400 font-bold normal-case">
            {title}
          </div>
        )}
        <div className="text-[0.65rem] text-gray-900 font-bold normal-case">
          {value}
        </div>
      </Typography>
    </div>
  );
}
