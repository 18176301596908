import CustomAccordionListElement from "./CustomAccordionListElement";

const CustomAccordionDetails = ({ accordionItem }) => {
  return (
    <div className="w-full p-2 bg-white">
      <div className="border-gray-100 border-l-4">
        {accordionItem &&
          accordionItem.map((accordionElement, index) => (
            <CustomAccordionListElement accordionElement={accordionElement} key={index} />
          ))}
      </div>
    </div>
  );
};

export default CustomAccordionDetails;
