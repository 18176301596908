export const SEARCH = "SEARCH";
export const CURRENT_PAGE_SET = "CURRENT_PAGE_SET";
export const CURRENT_PAGE_URL_SET = "CURRENT_PAGE_URL_SET";
export const RESULT_COUNT_SET = "RESULT_COUNT_SET";

export const searchText = (dispatch, text) =>
  dispatch({ type: SEARCH, payload: text });

export const setCurrentPageSet = (dispatch, number) =>
  dispatch({ type: CURRENT_PAGE_SET, payload: number });

export const setCurrentPageUrlSet = (dispatch, text) =>
  dispatch({ type: CURRENT_PAGE_URL_SET, payload: text });

export const setResultCount = (dispatch, obj) =>
  dispatch({ type: RESULT_COUNT_SET, payload: obj });
