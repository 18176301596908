import { Typography } from "@mui/material";

export default function TooltipTitle({ value }) {
  return (
    <p className="my-2">
      <Typography component="span" className="text-sm text-black font-bold">
        {value}
      </Typography>{" "}
    </p>
  );
}
