import { useContext } from "react";
import { NotificationPageStateContext } from "../context/NotificationsPageProvider";
import Pagination from "./NotificationsItem/Pagination";
import { NotificationsSection } from "./NotificationsSection";
import { STRINGS } from "./utils/strings";

const NotificationsList = () => {
  const notifyPageState = useContext(NotificationPageStateContext);

  return (
    <>
      {notifyPageState.notifications.ui.areAllListsEmpty ? (
        <div className="p-4 text-center min-h-[100px]">
          {STRINGS.NO_NOTIFICATIONS_AVAILABLE}
        </div>
      ) : (
        <div>
          {notifyPageState.notifications.ui.notificationsList.today.length >
            0 && (
            <NotificationsSection
              title={STRINGS.TODAY}
              section={notifyPageState.notifications.ui.notificationsList.today}
            />
          )}
          {notifyPageState.notifications.ui.notificationsList.yesterday.length >
            0 && (
            <NotificationsSection
              title={STRINGS.YESTERDAY}
              section={
                notifyPageState.notifications.ui.notificationsList.yesterday
              }
            />
          )}
          {notifyPageState.notifications.ui.notificationsList.older.length >
            0 && (
            <NotificationsSection
              title={STRINGS.OLDER}
              section={notifyPageState.notifications.ui.notificationsList.older}
            />
          )}
        </div>
      )}
      <Pagination />
    </>
  );
};
export default NotificationsList;
