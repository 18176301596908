import { useContext, useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Colors from "../../../../../../../../../../global/Colors";
import { setFieldData } from "../../../../../CaseFollowUps/state/actions";
import STRINGS from "../../../../../CaseFollowUps/components/utils/strings";
import { setFormIsTouched, setFormIsValid } from "../state/actions";
import {
  FollowUpFormDispatchContext,
  FollowUpFormStateContext,
} from "../context/FollowUpFormContext";
import ProCliStatusDropdown from "../../../../../../../../../../global/FormComponents/FormPresets/ProCliStatus/ProCliStatusDropdown";
import DataContext from "../../../../../../../../../../../context/DataContext";

const Form = ({
  isProviderFollowUp,
  isProviderEditable,
  submitForm,
  title,
  buttonsArr,
  providerOptions,
  setGetProCliOptions,
  isFormTouched,
}) => {
  const { userRoles } = useContext(DataContext);
  const formState = useContext(FollowUpFormStateContext);
  const formDispatch = useContext(FollowUpFormDispatchContext);
  const submitButtonDefault =
    "opacity-70 text-white rounded-md px-8 py-2 m-6 mb-3";
  const submitButtonActive =
    "opacity-90 hover:opacity-100 text-white rounded-md px-8 py-2 m-6 mb-3";

  const [submitButtonStyle, setSubmitButtonStyle] = useState(
    formState.isValid ? submitButtonActive : submitButtonDefault
  );
  const [selectedProvider, setSelectedProvider] = useState("");

  const isFormValid = () => {
    let isValid = true;
    Object.keys(formState.fields).forEach((field) => {
      if (!formState.fields[field].isValid) {
        isValid = false;
      }
    });

    setFormIsValid(formDispatch, isValid);
  };

  useEffect(() => {
    let active = true;

    if (active) {
      if (formState.isValid && formState.isTouched) {
        setSubmitButtonStyle(submitButtonActive);
      }
    }
    return () => {
      active = false;
    };
  }, [formState.isTouched, formState.isValid]);

  const handleDateField = (e) => {
    handleFieldOnChange("dueDate", e);
  };

  const handleDetailField = (e) => {
    handleFieldOnChange(e.target.name, e.target.value);
  };
  const handleProviderField = (value) => {
    handleFieldOnChange("provider", value);
    setSelectedProvider(value);
  };
  const handleFieldOnChange = (fname, value) => {
    if (!formState.isTouched) {
      setFormIsTouched(formDispatch, true);
    }

    setFieldData(formDispatch, {
      fieldName: fname,
      data: value,
    });

    //Bubbles up to Modal Component to clear errors upon user interaction
    isFormTouched(true);
  };

  const handleSubmitForm = (submitType) => {
    if (formState.isTouched && formState.isValid) {
      let formData = {
        action_detail: formState.fields.detail.value,
        due_date: formState.fields.dueDate.value,
        ...(formState.fields.provider && formState.fields.provider.value !== ""
          ? { on_provider: formState.fields.provider.value }
          : {}),
      };
      submitForm(submitType, formData);
    }
  };
  useEffect(() => {
    let active = true;
    if (active) {
      if (formState.fields.provider) {
        if(!formState.fields.provider.isValid){
          setFormIsValid(formDispatch, false);
        }
        if(formState.fields.provider.isValid){
          setFormIsValid(formDispatch, true);
        }
      }
    }
    return () => {
      active = false;
    };
  }, [formDispatch, formState.fields]);

  return (
    <div id="Form" data-testid="Form">
      <div data-testid="Dialog-Header" className="">
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDateTimePicker
          data-testid="field-datepicker-due-date"
          className="w-full my-4 text-center"
          label={STRINGS.FIELD_LABEL_DUE_DATE}
          id="field_followup_date"
          name="dueDate"
          value={
            formState.fields.dueDate.value !== "" &&
            new Date(formState.fields.dueDate.value)
          }
          onChange={handleDateField}
        />
      </LocalizationProvider>
      {isProviderFollowUp && isProviderEditable && (
        <ProCliStatusDropdown
          proCliStatus={selectedProvider}
          setProCliStatusParent={handleProviderField}
          optionsList={providerOptions}
          getOptionsList={setGetProCliOptions}
        />
      )}
      {isProviderFollowUp && !isProviderEditable && (
        <div>
          <h4>Provider:</h4>
          {formState.fields.provider.displayValue}
        </div>
      )}

      <TextField
        data-testid="field-input-followup-detail"
        required={formState.fields.detail.required}
        className="w-full my-4 text-center"
        id="followup-detail"
        name="detail"
        label={STRINGS.FIELD_LABEL_REASON}
        placeholder={STRINGS.FIELD_PLACEHOLDER_REASON}
        value={
          formState.fields.detail.value ? formState.fields.detail.value : ""
        }
        variant="outlined"
        onChange={handleDetailField}
        error={formState.fields.detail.isError}
        helperText={
          formState.fields.detail.isHelperTextVisible
            ? STRINGS.FIELD_HELPERTEXT_REASON
            : STRINGS.EMPTY
        }
        multiline
        minRows={3}
        maxRows={10}
      />
      {buttonsArr.map(
        (buttonItem) =>
          userRoles.permissions.includes(buttonItem.permissions[0]) &&
          userRoles.permissions.includes(buttonItem.permissions[1]) && (
            <Button
              key={buttonItem.submitType}
              data-testid={"button-submit-" + buttonItem.submitType}
              onClick={() => handleSubmitForm(buttonItem.submitType)}
              className={submitButtonStyle}
              style={{
                backgroundColor:
                  buttonItem.submitType === "Complete-FollowUp"
                    ? Colors.green[500]
                    : Colors.blue[500],
              }}
            >
              {buttonItem.displayText}
            </Button>
          )
      )}
    </div>
  );
};
export default Form;
