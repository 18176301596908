import React, { useEffect, useRef, useState } from "react";
import Colors from "../../global/Colors";
import { Typography } from "@mui/material";

const CircularProgressBar = ({ progressPercent = 0 }) => {
  const [step, setStep] = useState(1);
  const [finalProgress, setFinalProgress] = useState(100);
  const [progress, setProgress] = useState(0);
  const [remainingProgess, setRemainingProgress] = useState(null);
  const radius = 45;
  const circumference = 2 * Math.PI * radius;
  const [dashOffset, setDashOffset] = useState(circumference);
  const thumbRef = useRef(null);
  const [xCoord, setXCoord] = useState(0);
  const [yCoord, setYCoord] = useState(0);

  useEffect(() => {
    setStep(progress <= progressPercent ? 1 : -1);
    setRemainingProgress(null);
    setFinalProgress(progressPercent);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prev) => {
        if (prev < Math.round(progressPercent)) {
          setRemainingProgress(null);
          return prev + step;
        } else if (Math.round(progressPercent) < prev) {
          setRemainingProgress(null);
          return prev - step;
        }
        if (step === 1) {
          if (progressPercent < 100) {
            setRemainingProgress(100 - Math.round(progressPercent));
          } else {
            setRemainingProgress(0);
          }
        } else {
          if (progressPercent > finalProgress) {
            setRemainingProgress(prev);
          } else {
            setRemainingProgress(Math.round(progressPercent));
          }
        }
        clearInterval(intervalId);
        return Math.round(progressPercent);
      });
    }, 20);
    return () => clearInterval(intervalId);
  }, [progressPercent]);

  useEffect(() => {
    const angle = (progress / 100) * 360;
    const thumbX = 50 + radius * Math.cos((angle - 90) * (Math.PI / 180)); // X position based on angle
    const thumbY = 50 + radius * Math.sin((angle - 90) * (Math.PI / 180)); // Y position based on angle
    setDashOffset(circumference * ((100 - progress) / 100));
    setXCoord(thumbX);
    setYCoord(thumbY);
  }, [progress]);

  return (
    <div className="w-56 h-56 flex justify-center items-center relative">
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r={radius}
          stroke={Colors.sky[100]}
          strokeWidth="2"
          fill={Colors.white}
        />

        <circle
          id="progress-circle"
          cx="50"
          cy="50"
          r={radius}
          stroke={Colors.green[400]}
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={dashOffset}
          transform="rotate(-90, 50, 50)"
          fill="none"
        />

        <foreignObject
          width="100%"
          height="100%"
          className="flex justify-center items-center"
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            <span
              className="font-thin text-black text-3xl"
              sx={{ fontWeight: "100" }}
            >
              {progress}
              <span className="font-thin text-black text-sm">%</span>
            </span>
            <Typography className="text-[6px] text-black font-bold">
              Treatment Completed
            </Typography>
            <Typography
              className={`${
                remainingProgess !== null ? "text-gray-400" : "text-white"
              } text-[5px] font-normal`}
            >
              {remainingProgess}% Remaining
            </Typography>
          </div>
        </foreignObject>
      </svg>

      {progress !== 0 && progress !== 100 ? (
        <div
          ref={thumbRef}
          className="absolute p-0.5"
          style={{
            top: `${yCoord}%`,
            left: `${xCoord}%`,
            width: "27px",
            height: "27px",
            backgroundColor: Colors.green[400],
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <svg width="100%" height="100%" viewBox="0 0 100 100">
            <circle
              cx="50"
              cy="50"
              r={radius}
              stroke={Colors.green[100]}
              strokeWidth="1"
              fill={Colors.white}
            />
          </svg>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CircularProgressBar;
