import CustomTextField from "../../../components/CustomTextField";
import { TEXTFIELD_TYPES } from "../../../components/CustomTextFieldTypes";
import ValueWithFallback from "../../../../../../../../../global/ValueWithFallback";
import FieldInfo from "../../../components/FieldInfo";
import getUpdatedString from "../../../components/utils/getUpdatedString";
import { setProvidersData } from "../../../state/actions";
import { ProviderListDispatchContext } from "../../../context/ProviderListProvider";
import { useContext } from "react";
export default function Balances({
  currentAccordionItem,
  activateButtons,
  deactivateButtons,
}) {
  const index = currentAccordionItem.index;
  const proCliData = currentAccordionItem.proCliData;
  const providerListDispatchContext = useContext(ProviderListDispatchContext);

  const updateProviderInfo = (updatedData = null) => {
    if (updatedData === null) {
      deactivateButtons();
      return;
    }
    if (updatedData.get(index).editableFields === null) {
      deactivateButtons();
    } else {
      activateButtons();
    }
    if (updatedData !== null) {
      setProvidersData(providerListDispatchContext, updatedData);
    }
  };
  return (
    <div id="Provider-Balances" data-testid="Provider-Balances">
      <div className="flex flex-row justify-start items-start w-full mt-4 flex-wrap">
        <div className="w-1/2">
          <div className="h-3/4">
            <CustomTextField
              index={index}
              title={"Current Balance"}
              fieldName={"currentBalance"}
              subTitle={""}
              width={"w-6/6"}
              disabled={!proCliData?.can_edit}
              defaultValue={ValueWithFallback(
                proCliData?.current_balance,
                ""
              )}
              placeholder={"0.00"}
              providerInfo={proCliData}
              type={TEXTFIELD_TYPES.CURRENCY}
              activateButtons={activateButtons}
              deactivateButtons={deactivateButtons}
              updateProviderInfo={updateProviderInfo}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="h-1/4">
            <CustomTextField
              index={index}
              title={"Calculated Appt Balances"}
              subTitle={""}
              width={"w-6/6"}
              disabled={true}
              defaultValue={ValueWithFallback(
                proCliData?.running_total,
                "0.00"
              )}
              providerInfo={proCliData}
              type={TEXTFIELD_TYPES.CURRENCY}
              activateButtons={activateButtons}
              deactivateButtons={deactivateButtons}
              updateProviderInfo={updateProviderInfo}
            />
          </div>
          <div className="h-[20%]">
            <FieldInfo
              title={"Updated"}
              value={getUpdatedString(
                proCliData?.running_total_changed_by_name,
                proCliData?.running_total_changed
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
