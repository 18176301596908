import { useContext } from "react";
import DataContext from "../../../../../../../context/DataContext";
import renderAvailablePages from "../../../renderAvailablePages";
import CustomNavChip from "../../../CustomNavChip";
import { STRINGS } from "./strings";

const OtherPagesDashFilter = () => {
  const { userRoles, loggedInUser } = useContext(DataContext);

  const renderOtherPagesCardContent = () => {
    return renderAvailablePages(userRoles, loggedInUser).map((dataValue) => (
      <CustomNavChip
        path={dataValue.path}
        title={dataValue.title}
        color={dataValue.color}
        key={dataValue.path}
        active={dataValue.active}
      />
    ));
  };

  const availablePages = renderOtherPagesCardContent();

  return (
    <div
      id="Other-Pages-Dashboard-Filter"
      data_testid="Other-Pages-Dashboard-Filter"
      className="flex flex-col overflow-y-auto"
    >
      {availablePages.length === 0 ? (
        <div className="h-full flex flex-col justify-center">
          <h2 className="text-center my-4 text-[1.2rem] font-bold">
            {STRINGS.NO_AVAILABLE_PAGES}
          </h2>
        </div>
      ) : (
        availablePages
      )}
    </div>
  );
};

export default OtherPagesDashFilter;
