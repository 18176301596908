import { Button } from "@mui/material";
import Colors from "../../../global/Colors";

export default function FullyRoundedButton({
  buttonText = "",
  bgColor = "bg-blue-600",
  textColor = "text-white",
  horizontalPadding = "px-8",
  verticalPadding = "py-3",
  textSize = "text-sm",
  buttonWidth = "w-full",
  buttonHeight = "h-full",
  borderWidth = "border-[1px]",
  borderColor = "bg-blue-600",
  hoverBgColor = "bg-blue-700",
  hoverBorderColor = "border-blue-700",
  hoverBorderWidth = "border-[1px]",
  hoverTextColor = "text-white",
  classNames = "",
  variant = "contained",
  disabled = false,
  handleOnClick = {},
}) {
  return (
    <Button
      disableElevation
      disabled={disabled}
      variant={variant}
      className={`normal-case rounded-full self-baseline hover:cursor-pointer m-2  ${bgColor} ${horizontalPadding} ${verticalPadding} ${textSize} ${textColor} ${buttonWidth} ${buttonHeight} ${borderWidth} ${borderColor} hover:${hoverBgColor}  hover:${hoverTextColor} hover:${hoverBorderWidth} hover:${hoverBorderColor}`}
      sx={{
        "&:hover": {
          bgcolor: hoverBgColor,
          color: "text-blue-600",
        },
      }}
      onClick={() => handleOnClick()}
    >
      <span>{buttonText}</span>
    </Button>
  );
}
