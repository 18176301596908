import { useState } from "react";
import CustomAccordionDetails from "./CustomAccordionDetails";
import CustomAccordionSummary from "./CustomAccordionSummary";

const CustomAccordion = ({ accordionItem }) => {
  const [expandAccordion, setExpandAccordion] = useState(true);
  return (
    <CustomAccordionSummary
      accordionItem={accordionItem}
      expandAccordion={expandAccordion}
      setExpandAccordion={setExpandAccordion}
    >
      <CustomAccordionDetails />
    </CustomAccordionSummary>
  );
};

export default CustomAccordion;
