import { useContext, useState } from "react";
import { Typography } from "@mui/material";
import {
  setNotificationFilterType,
  setShouldFetchNotifications,
} from "../../state/actions";
import {
  NotificationPageDispatchContext,
  NotificationPageStateContext,
} from "../../context/NotificationsPageProvider";

const CustomAccordionListElement = ({ accordionElement }) => {
  const [selected, setSelected] = useState(false);

  const notificationPageEventDispatch = useContext(
    NotificationPageDispatchContext
  );

  const handleAccordionItemClick = (event, item) => {
    if (item.count > 0) {
      setNotificationFilterType(notificationPageEventDispatch, {
        selected: !selected,
        key: item.key,
      });
      setSelected(!selected);
      setShouldFetchNotifications(notificationPageEventDispatch, true);
    }
  };

  return (
    <div
      className={`group mb-2 flex flex-row justify-between items-center border-white border-l-4 rounded ${
        selected ? "border-purple-500 border-l-4 bg-gray-100" : ""
      } ${
        accordionElement.count > 0
          ? "hover:cursor-pointer hover:bg-gray-100 ml-2 hover:border-purple-500 hover:border-l-4"
          : "px-2"
      }`}
      onClick={(event) => {
        handleAccordionItemClick(event, accordionElement);
      }}
    >
      <div className="p-2">
        <Typography className="text-sm">{accordionElement.label}</Typography>
      </div>
      {accordionElement.count !== 0 ? (
        <div
          className={`mx-2 my-1 w-8 h-8 text-center rounded-full flex items-center justify-center p-2 ${
            selected ? "bg-purple-500 text-white" : "bg-gray-100 text-black"
          } ${
            accordionElement.count > 0
              ? "group-hover:bg-purple-500 group-hover:text-white"
              : ""
          }
          `}
        >
          <Typography className="text-sm text-center align-middle">
            {accordionElement.count}
          </Typography>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomAccordionListElement;
