export default function DashboardCardFilters({ children }) {
  return (
    <div
      className="w-full grid mb-4"
      style={{
        gridTemplateColumns: "repeat(auto-fit, minmax(min(25%, 100%), 1fr))",
      }}
    >
      {children}
    </div>
  );
}
