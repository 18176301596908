import { useContext } from "react";
import NotificationsList from "./components/NotificationsList";
import NotificationsFilter from "./components/NotificationsFilter";
import { STRINGS } from "./components/utils/strings";
import DataContext from "../../../../../../context/DataContext";
import { useQuery } from "@tanstack/react-query";
import { user } from "../../../../../api/endpoints/user";
import { GenerateQueryParameterString } from "../../../../../global/GenerateQueryParamString";
import {
  NotificationPageDispatchContext,
  NotificationPageStateContext,
} from "./context/NotificationsPageProvider";
import { CircularProgress, Divider } from "@mui/material";
import { NotificationReadOnlyToggle } from "./components/NotificationsItem/NotificationReadOnlyToggle";
import {
  setNotifications,
  setPageIsSeen,
  setResultCount,
  setShouldFetchNotifications,
  setShouldFetchNotificationFilters,
} from "./state/actions";
import ApiServices from "../../../../../api/service";
import { setNotificationCount } from "../../../../../../state/actions";
import { EventsDispatchContext } from "../../../../../../context/EventsProvider";

const NotificationsPageWithState = () => {
  const { accessToken } = useContext(DataContext);
  const eventDispatch = useContext(EventsDispatchContext);
  const notifyPageState = useContext(NotificationPageStateContext);
  const notifyPageDispatch = useContext(NotificationPageDispatchContext);

  const getSeenArr = (res) => {
    return res.map((noti) => noti.id);
  };

  const {
    isLoading: isLoadingFilters,
    error: filterError,
    data: filterData,
    isFetching: isFetchingFilters,
  } = useQuery({
    queryKey: ["notification-filters"],
    queryFn: async () => {
      const response = user
        .getNotificationFilters(accessToken)
        .then(async (res) => {
          setShouldFetchNotificationFilters(notifyPageDispatch, false);
          return res;
        })
        .catch((err) => err);

      return await response;
    },
    enabled: notifyPageState.shouldFetchNotificationFilters,
  });

  const {
    isLoading: isPendingNotifications,
    error: errorNotifications,
    data: dataNotifications,
    isFetching: isFetchingNotifications,
  } = useQuery({
    queryKey: ["notification-list"],
    queryFn: async () => {
      const response = user
        .getNotifications(
          accessToken,
          GenerateQueryParameterString(
            {
              type: notifyPageState.notifications.api.filters.type,
              ...(notifyPageState.notifications.api.filters.isRead === 1
                ? {}
                : { read: false }),
              resLimit: notifyPageState.notifications.api.filters.resLimit,
              offset: notifyPageState.notifications.api.filters.offset,
              ordering: notifyPageState.notifications.api.filters.ordering,
            },
            true
          )
        )
        .then(async (res) => {
          setNotifications(notifyPageDispatch, res.results);
          setResultCount(notifyPageDispatch, {
            total: res.count,
            current: res.results.length,
          });
          let seenRes = getSeenArr(res.results);
          return ApiServices.postData(
            `api/core/notifications/seen/`,
            seenRes,
            accessToken
          )
            .then((res) => {
              setNotificationCount(eventDispatch, res.unseen_count);
              return res;
            })
            .catch((err) => err);
        })
        .then((res) => res)
        .then((res) => {
          setPageIsSeen(notifyPageDispatch, true);
          return res;
        })
        .catch((err) => err);

      setShouldFetchNotifications(notifyPageDispatch, false);
      return await response;
    },
    enabled: notifyPageState.shouldFetchNotifications,
  });

  return (
    <div
      id="Notifications-Page"
      data-testid="Notifications-Page"
      className="bg-white p-5 min-w-min"
    >
      <div className="w-full h-full">
        <div
          id="Notifications-Page-Header"
          data-testid="Notifications-Page-Content"
          className="flex my-4 items-align"
        >
          <div className="w-[70%] justify-start">
            <h1 className="text-2xl text-left text-purple-600 uppercase font-bold">
              {STRINGS.TITLE}
            </h1>
          </div>
          <div className="w-[30%] justify-end">
            <NotificationReadOnlyToggle />
          </div>
        </div>
        <Divider />
        <div
          id="Notifications-Page-Content"
          data-testid="Notifications-Page-Content"
          className="flex "
        >
          <div className="w-1/5 min-w-min">
            <NotificationsFilter filterData={filterData} />
            {filterError && STRINGS.ERROR_FETCHING_NOTIFICATION_FILTER}
          </div>
          <div className="w-4/5 py-2">
            {isPendingNotifications || isFetchingNotifications ? (
              <CircularProgress color="secondary" />
            ) : (
              <NotificationsList />
            )}
            {errorNotifications && STRINGS.ERROR_FETCHING_NOTIFICATION_LIST}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsPageWithState;
