import { useContext } from "react";
import ButtonCounter from "./ButtonCounter";
import { STRINGS } from "./content/strings";
import { LIST_TYPES } from "./listTypes";
import TextCounter from "./TextCounter";
import SearchContext from "../../../../../../context/SearchContext";

/**
 * Dashboard Card Filter Custom scroll list that returns
 * custom ButtonCounter and TextCounter components
 *
 * @param {{<Array<{name:string, total:number}>, string, string}} { array, type, color }
 * @return {Element}
 */
const ScrollList = ({ array, type, color = "slate" }) => {
  const { selectedStatuses, selectedCaseStatuses } = useContext(SearchContext);

  /**
   * @function getComponent -
   *
   * @param {string} name - returns name or label of list item
   * @param {number} total - returns total or count of list item
   * @return {ButtonCounter | TextCounter}
   */
  const getComponent = (filterType, name, total, isActive, idx) => {
    switch (type) {
      case LIST_TYPES.BUTTON_LIST:
        return (
          <ButtonCounter
            title={name}
            value={total}
            statusType={filterType}
            key={idx}
            color={color}
            isActive={isActive}
          />
        );
      case LIST_TYPES.TEXT_LIST:
        return <TextCounter title={name} value={total} key={idx} />;
      default:
        return <TextCounter title={name} value={total} key={idx} />;
    }
  };
  const convertToArr = (value) => {
    //Since the word 'Active' is in several status option selections, need to normalize data to an Array type sent back from localStorage versus SearchContext
    if (!value || value === "") {
      return "";
    }
    if (typeof value === "string") {
      let tryToMakeArray = value.split(",");
      if (typeof tryToMakeArray === "string") {
        let newArr = [];
        newArr.push(tryToMakeArray);
        return newArr;
      } else {
        return tryToMakeArray;
      }
    }
    return value;
  };
  const getInitialActiveStatus = (filterType, name) => {
    if (filterType === "overall_status") {
      if (selectedCaseStatuses) {
        if (convertToArr(selectedCaseStatuses).includes(name)) {
          return true;
        }
      }
    } if (filterType === "status") {
      if (selectedStatuses) {
        if (convertToArr(selectedStatuses).includes(name)) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div className="flex flex-wrap overflow-y" data-testid="scroll-list">
      {array && array.length > 0 ? (
        array.map((item) =>
          getComponent(
            item.field_name,
            item.label,
            item.total,
            getInitialActiveStatus(item.field_name, item.label),
            item.key
          )
        )
      ) : (
        <div className="text-[0.85rem] p-2">{STRINGS.NO_ROLES_AVAILABLE}</div>
      )}
    </div>
  );
};
export default ScrollList;
