import { useState, useEffect, useCallback } from "react";
import axios from "../../../../../api/axios";

async function sendAPIRequest(url, accessToken) {
  const response = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
  });

  return await response.data;
}

export default function useApiData() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resultCount, setResultCount] = useState({ total: 0, current: 0 });
  const [needFetching, setNeedFetching] = useState(false);

  const getData = useCallback(async (url, accessToken) => {
    setIsLoading(true);

    sendAPIRequest(url, accessToken)
      .then((result) => {
        setData(result);
        setResultCount({
          total: result.count,
          current: result.results.length,
        });
        setError(null);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    if (!data || data.length || needFetching) return;

    setNeedFetching(true);
  }, [data, needFetching]);

  return { data, error, isLoading, getData, resultCount };
}
