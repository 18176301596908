import React from "react";
import { Add, Remove } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";


const CustomAccordionSummary = ({
  accordionItem,
  expandAccordion,
  setExpandAccordion,
  children,
}) => {
  const handleAccordionExpansion = () => {
    setExpandAccordion(!expandAccordion);
  };
  return (
    <div className="w-full p-2">
      <Button
        sx={{ textTransform: "none" }}
        size="small"
        className="w-full flex flex-row justify-between bg-gray-100 rounded-none relative"
        onClick={handleAccordionExpansion}
      >
        {/* <div className="w-full flex flex-row justify-between bg-red-500 rounded-none relative"> */}
        <span className="text-left text-black px-2">
          <Typography className="text-md">
            {accordionItem.group_name}
          </Typography>
        </span>
        {!expandAccordion ? (
          <Remove className="text-sky-600 mr-2" />
        ) : (
          <Add className="text-sky-600 mr-2" />
        )}
        {/* </div> */}
      </Button>
      {!expandAccordion && accordionItem && accordionItem.elements
        ? React.cloneElement(children, {
            accordionItem: accordionItem.elements,
          })
        : ""}
    </div>
  );
};

export default CustomAccordionSummary;
