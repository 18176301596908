import { useState } from "react";
import AccordionDetails from "./AccordionDetails";
import AccordionSummary from "./AccordionSummary";

export default function Accordion({
  accordionItem,
  icons,
  summaryContent,
  expandedContent,
}) {
  const [expandAccordion, setExpandAccordion] = useState(true);

  return (
    <div>
      <AccordionSummary
        accordionItem={accordionItem}
        expandAccordion={expandAccordion}
        setExpandAccordion={setExpandAccordion}
        icons={icons}
        summaryContent={summaryContent}
      >
        <AccordionDetails
          accordionItem={accordionItem}
          isOpen={expandAccordion}
        >
          {expandedContent}
        </AccordionDetails>
      </AccordionSummary>
    </div>
  );
}
