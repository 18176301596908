const ProviderHeaderIcon = () => {
  return (
    <div>
      <svg
        width="65"
        height="58"
        viewBox="0 0 65 58"
        fill="none"
        xmlns="SVG namespace "
      >
        <path
          d="M58.3049 5.93898C56.7449 4.37349 54.8911 3.13136 52.85 2.28382C50.8089 1.43628 48.6205 1 46.4104 1C44.2003 1 42.0119 1.43628 39.9708 2.28382C37.9297 3.13136 36.0759 4.37349 34.5159 5.93898L32.1214 8.36454L29.7269 5.93898C28.1669 4.37349 26.3131 3.13136 24.272 2.28382C22.2309 1.43628 20.0425 1 17.8324 1C15.6223 1 13.4339 1.43628 11.3928 2.28382C9.35168 3.13136 7.49793 4.37349 5.93786 5.93898C-0.654667 12.5315 -1.05893 23.6642 7.24393 32.1225L32.1214 57L56.9989 32.1225C65.3017 23.6642 64.8975 12.5315 58.3049 5.93898Z"
          stroke="#247DF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.1143 8.36133L22.381 18.1257C21.1504 19.3663 20.46 21.0429 20.46 22.7903C20.46 24.5376 21.1504 26.2142 22.381 27.4548C23.6216 28.6853 25.2981 29.3758 27.0455 29.3758C28.7929 29.3758 30.4694 28.6853 31.71 27.4548L38.7379 20.5824C40.4845 18.8543 42.8423 17.885 45.2993 17.885C47.7564 17.885 50.1142 18.8543 51.8608 20.5824L59.324 28.0456"
          stroke="#247DF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.7789 38.3405L44.5596 32.1211"
          stroke="#247DF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.4498 47.6686L35.2305 41.4492"
          stroke="#247DF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ProviderHeaderIcon;
