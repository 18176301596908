import { createContext, useContext, useMemo, useState } from "react";
import DataContext from "./DataContext";
const SearchContext = createContext({});

const checkLocalStorageFirst = (userId, state, emptyDefault) => {
  /* if (!localStorage.getItem("npa") || !userId) {
    return emptyDefault;
  }
  if (JSON.parse(localStorage.getItem("npa"))) {
    let lsData = JSON.parse(localStorage.getItem("npa"));
    if (lsData && lsData.npa) {
      if (lsData.npa.user_settings) {
        if (lsData.npa.user_settings[userId]) {
          if (lsData.npa.user_settings[userId].case_search) {
            if (
              lsData.npa.user_settings[userId].case_search.hasOwnProperty(state)
            ) {
              if (
                lsData.npa.user_settings[userId].case_search[state] !== null
              ) {
                return lsData.npa.user_settings[userId].case_search[state];
              }else{
                return emptyDefault;
              }
            }
          }
        } else {
          return emptyDefault;
        }
      }
    }
  }
 */
  return emptyDefault;
};

export const TOTAL_SETTLEMENT_AMOUNT_MIN = "actual_total_settlement_amount__gt";
export const TOTAL_SETTLEMENT_AMOUNT_MAX = "actual_total_settlement_amount__lt";
export const MEDICAL_LIMITS_AVAILABLE_MIN = "medical_limit__gt";
export const MEDICAL_LIMITS_AVAILABLE_MAX = "medical_limit__lt";
export const PROPERTY_DAMAGE_AMOUNT_MIN = "property_damage_amount__gt";
export const PROPERTY_DAMAGE_AMOUNT_MAX = "property_damage_amount__lt";
export const RUNNING_TOTAL_MIN = "running_total__gt";
export const RUNNING_TOTAL_MAX = "running_total__lt";

export const SearchProvider = ({ children }) => {
  const { loggedInUser } = useContext(DataContext);
  const [search, setSearch] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "search", "")
  );
  const [archived, setArchived] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "archived", "")
  );
  const [progressionFinished, setProgressionFinished] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "progressionFinished", "")
  );
  const [liability, setLiability] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "liability", "")
  );
  const [medDocs, setMedDocs] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "medDocs", null)
  );
  const [settled, setSettled] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "settled", "")
  );
  const [npIntro, setNpIntro] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "npIntro", "")
  );
  const [assignedCm, setAssignedCm] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "assignedCm", "")
  );
  const [onboarded, setOnboarded] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "onboarded", "")
  );
  const [policeReport, setPoliceReport] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "policeReport", "")
  );
  const [dateOfIncident, setDateOfIncident] = useState(null); /// fix Incident Dates
  const [accidentPictures, setAccidentPictures] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "accidentPictures", null)
  );
  const [dateOpened, setDateOpened] = useState(null);
  const [propertyDamage, setPropertyDamage] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "propertyDamage", "")
  );
  const [lostStatus, setLostStatus] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "lostStatus", "")
  );
  const [clientDob, setClientDob] = useState(null); /// fix Dates
  const [involvedIndividuals, setInvolvedIndividuals] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "involvedIndividuals", "")
  );
  const [movingViolation, setMovingViolation] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "movingViolation", "")
  );
  const [clientManager, setClientManager] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "clientManager", false)
  );
  const [caseStatus, setCaseStatus] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "caseStatus", "All")
  );
  const [totalSettledAmount, setTotalSettledAmount] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "totalSettledAmount", "")
  );
  const [selectedPM, setSelectedPM] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedPM", "")
  );
  const [policyLimit, setPolicyLimit] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "policyLimit", "")
  );
  const [dateOpenedFrom, setDateOpenedFrom] = useState(null); /// fix Dates
  const [dateOpenedTo, setDateOpenedTo] = useState(null); /// fix Dates
  const [dateOfIncidentTo, setDateOfIncidentTo] = useState(null); /// fix Dates
  const [dateOfIncidentFrom, setDateOfIncidentFrom] = useState(null); /// fix Dates
  const [sortByLiabilityVal, setSortByLiabilityVal] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "sortByLiabilityVal", "")
  );
  const [selectedUserType, setSelectedUserType] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedUserType", null)
  );
  const [noPnAssigned, setNoPnAssigned] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "noPnAssigned", null)
  );
  const [noLfUserAssigned, setNoLfUserAssigned] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "noLfUserAssigned", null)
  );

  const [hasCallback, setHasCallback] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "hasCallback", null)
  );
  const [notLost, setNotLost] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "notLost", null)
  );
  const [runningTotal, setRunningTotal] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "runningTotal", "")
  );

  const [assignedUsers, setAssignedUsers] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "assignedUsers", [])
  );
  // activeUsers, setActiveUsers - controlls chips for assignedUsers
  const [activeUsers, setActiveUsers] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeUsers", [])
  );

  const [selectedInvolved, setSelectedInvolved] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedInvolved", [])
  );
  // activeInvolved, setActiveInvolved - controlls chips for selectedInvolved
  const [activeInvolved, setActiveInvolved] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeInvolved,", [])
  );

  const [selectedLawfirms, setSelectedLawfirms] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedLawfirms", [])
  );
  // activeLawFirm, setActiveLawFirm - controlls chips for selectedLawfirms
  const [activeLawFirm, setActiveLawFirm] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeLawFirm", [])
  );

  const [selectedStatuses, setSelectedStatuses] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedStatuses", [])
  );
  // activeStatuses, setActiveStatuses - controlls chips for selectedStatuses
  const [activeStatuses, setActiveStatuses] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeStatuses", [])
  );

  const [selectedCaseStatuses, setSelectedCaseStatuses] = useState(
    [
      "Active",
      "Active - Waiting on Limits",
      "Active - Client Unresponsive",
      "Active - Paused (See Note)"
    ]
  );
  // activeCaseStatuses, setActiveCaseStatuses - controlls chips for selectedCaseStatuses
  const [activeCaseStatuses, setActiveCaseStatuses] = useState(
      [
      "Active",
      "Active - Waiting on Limits",
      "Active - Client Unresponsive",
      "Active - Paused (See Note)"
    ]
  );

  const [selectedLegalStatuses, setSelectedLegalStatuses] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedLegalStatuses", [])
  );
  // activeLegalStatuses, setActiveLegalStatuses - controlls chips for selectedLegalStatuses
  const [activeLegalStatuses, setActiveLegalStatuses] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeLegalStatuses", [])
  );

  const [selectedLost, setSelectedLost] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedLost", [])
  );
  // activeLost, setActiveLost - controlls chips for selectedLost
  const [activeLost, setActiveLost] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeLost", [])
  );

  const [selectedRoles, setSelectedRoles] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedRoles", [])
  );
  const [activeRoles, setActiveRoles] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeRoles", [])
  );

  const [selectedUnassignedRoles, setSelectedUnassignedRoles] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedUnassignedRoles", [])
  );
  const [activeUnassignedRoles, setActiveUnassignedRoles] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeUnassignedRoles", [])
  );

  const [selectedSources, setSelectedSources] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedSources", [])
  );
  const [activeSources, setActiveSources] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeSources", [])
  );

  const [selectedDocTypes, setSelectedDocTypes] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedDocTypes", [])
  );
  const [activeDocTypes, setActiveDocTypes] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeDocTypes", [])
  );

  const [selectedNotUpDocTypes, setSelectedNotUpDocTypes] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedNotUpDocTypes", [])
  );
  const [activeNotUpDocTypes, setActiveNotUpDocTypes] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeNotUpDocTypes", [])
  );

  const [selectedEntities, setSelectedEntities] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedEntities", [])
  );
  const [activeEntities, setActiveEntities] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeEntities", [])
  );

  const [selectedLocations, setSelectedLocations] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedLocations", [])
  );
  const [activeLocations, setActiveLocations] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeLocations", [])
  );

  const [selectedNotLocations, setSelectedNotLocations] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "selectedNotLocations", [])
  );
  const [activeNotLocations, setActiveNotLocations] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "activeNotLocations", [])
  );

  const [hasSource, setHasSource] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "hasSource", null)
  );
  const [dateCreatedTo, setDateCreatedTo] = useState(null); /// fix Dates ...maybe be empty string default
  const [dateCreatedFrom, setDateCreatedFrom] = useState(null); /// fix Dates

  const [noteAddedTo, setNoteAddedTo] = useState(null); /// fix Dates
  const [noteAddedFrom, setNoteAddedFrom] = useState(null); /// fix Dates

  const [apptAddedTo, setApptAddedTo] = useState(null); /// fix Dates
  const [apptAddedFrom, setApptAddedFrom] = useState(null); /// fix Dates

  const [caseUpdatedTo, setCaseUpdatedTo] = useState(null); /// fix Dates
  const [caseUpdatedFrom, setCaseUpdatedFrom] = useState(null); /// fix Dates

  const [propertyDmgKey, setPropertyDmgKey] = useState(
    checkLocalStorageFirst(
      loggedInUser?.pk,
      "propertyDmgKey",
      "property_damage_amount"
    )
  );
  const [totalSettledAmtKey, setTotalSettledAmtKey] = useState(
    checkLocalStorageFirst(
      loggedInUser?.pk,
      "totalSettledAmtKey",
      "actual_total_settlement_amount"
    )
  );
  const [policyLimitKey, setPolicyLimitKey] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "policyLimitKey", "policy_limit")
  );
  const [runningTotalKey, setRunningTotalKey] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "runningTotalKey", "running_total")
  );

  const [needsSupIsNull, setNeedsSupIsNull] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "needsSupIsNull", null)
  );
  const [orderingVal, setOrderingVal] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "orderingVal", null)
  );
  const [resLimit, setResLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [paginationUrl, setPaginationUrl] = useState("limit=100");
  const [currentPage, setCurrentPage] = useState(1);

  const [isFirstLoad, setIsFirstLoad] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "isFirstLoad", true)
  );
  const [umStatus, setUmStatus] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "umStatus", "")
  );
  const [pdSeverity, setPdSeverity] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "pdSeverity", "")
  );
  const [pdSeverityIsNull, setPdSeverityIsNull] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "pdSeverityIsNull", null)
  );
  const [incidentLocationIsNull, setIncidentLocationIsNull] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "incidentLocationIsNull", null)
  );
  const [legal, setLegal] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "legal", "")
  );
  const [overall, setOverall] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "overall", "")
  );
  const [liabilityRequest, setLiabilityRequest] = useState(
    checkLocalStorageFirst(loggedInUser?.pk, "liabilityRequest", "")
  );

  // total settlement amount
  const [totalSettlementAmountMin, setTotalSettlementAmountMin] =
    useState(undefined);
  const [totalSettlementAmountMax, setTotalSettlementAmountMax] =
    useState(undefined);
  // medical limits available
  const [medicalLimitsAvailableMin, setMedicalLimitsAvailableMin] =
    useState(undefined);
  const [medicalLimitsAvailableMax, setMedicalLimitsAvailableMax] =
    useState(undefined);
  // property damage amount
  const [propertyDamageAmountMin, setPropertyDamageAmountMin] =
    useState(undefined);
  const [propertyDamageAmountMax, setPropertyDamageAmountMax] =
    useState(undefined);
  // running total
  const [runningTotalMin, setRunningTotalMin] = useState(undefined);
  const [runningTotalMax, setRunningTotalMax] = useState(undefined);

  return (
    <SearchContext.Provider
      value={useMemo(
        () => ({
          search,
          setSearch,
          archived,
          setArchived,
          progressionFinished,
          setProgressionFinished,
          liabilityRequest,
          setLiabilityRequest,
          overall,
          setOverall,
          legal,
          setLegal,
          liability,
          setLiability,
          medDocs,
          setMedDocs,
          settled,
          setSettled,
          npIntro,
          setNpIntro,
          assignedCm,
          setAssignedCm,
          onboarded,
          setOnboarded,
          policeReport,
          setPoliceReport,
          dateOfIncident,
          setDateOfIncident,
          accidentPictures,
          setAccidentPictures,
          dateOpened,
          setDateOpened,
          propertyDamage,
          setPropertyDamage,
          lostStatus,
          setLostStatus,
          clientDob,
          setClientDob,
          involvedIndividuals,
          setInvolvedIndividuals,
          movingViolation,
          setMovingViolation,
          clientManager,
          setClientManager,
          totalSettledAmount,
          setTotalSettledAmount,
          selectedPM,
          setSelectedPM,
          caseStatus,
          setCaseStatus,
          policyLimit,
          setPolicyLimit,
          dateOpenedFrom,
          setDateOpenedFrom,
          dateOpenedTo,
          setDateOpenedTo,
          dateOfIncidentTo,
          setDateOfIncidentTo,
          dateOfIncidentFrom,
          setDateOfIncidentFrom,
          sortByLiabilityVal,
          setSortByLiabilityVal,
          selectedUserType,
          setSelectedUserType,
          noLfUserAssigned,
          setNoLfUserAssigned,
          noPnAssigned,
          setNoPnAssigned,
          assignedUsers,
          setAssignedUsers,
          activeUsers,
          setActiveUsers,
          selectedInvolved,
          setSelectedInvolved,
          activeInvolved,
          setActiveInvolved,
          selectedSources,
          setSelectedSources,
          activeSources,
          setActiveSources,
          runningTotal,
          setRunningTotal,
          dateCreatedTo,
          setDateCreatedTo,
          dateCreatedFrom,
          setDateCreatedFrom,
          hasSource,
          setHasSource,
          selectedLawfirms,
          setSelectedLawfirms,
          activeLawFirm,
          setActiveLawFirm,
          hasCallback,
          setHasCallback,
          selectedLost,
          setSelectedLost,
          activeLost,
          setActiveLost,
          notLost,
          setNotLost,
          policyLimitKey,
          setPolicyLimitKey,
          propertyDmgKey,
          setPropertyDmgKey,
          runningTotalKey,
          setRunningTotalKey,
          totalSettledAmtKey,
          setTotalSettledAmtKey,
          selectedStatuses,
          setSelectedStatuses,
          activeStatuses,
          setActiveStatuses,
          needsSupIsNull,
          setNeedsSupIsNull,
          orderingVal,
          setOrderingVal,
          paginationUrl,
          setPaginationUrl,
          currentPage,
          setCurrentPage,
          isFirstLoad,
          setIsFirstLoad,
          umStatus,
          setUmStatus,
          pdSeverity,
          setPdSeverity,
          selectedRoles,
          setSelectedRoles,
          activeRoles,
          setActiveRoles,
          selectedUnassignedRoles,
          setSelectedUnassignedRoles,
          activeUnassignedRoles,
          setActiveUnassignedRoles,
          pdSeverityIsNull,
          setPdSeverityIsNull,
          selectedDocTypes,
          setSelectedDocTypes,
          activeDocTypes,
          setActiveDocTypes,
          selectedNotUpDocTypes,
          setSelectedNotUpDocTypes,
          activeNotUpDocTypes,
          setActiveNotUpDocTypes,
          caseUpdatedTo,
          setCaseUpdatedTo,
          caseUpdatedFrom,
          setCaseUpdatedFrom,
          selectedEntities,
          setSelectedEntities,
          activeEntities,
          setActiveEntities,
          noteAddedTo,
          setNoteAddedTo,
          noteAddedFrom,
          setNoteAddedFrom,
          apptAddedTo,
          setApptAddedTo,
          apptAddedFrom,
          setApptAddedFrom,
          selectedLocations,
          setSelectedLocations,
          activeLocations,
          setActiveLocations,
          selectedNotLocations,
          setSelectedNotLocations,
          activeNotLocations,
          setActiveNotLocations,
          incidentLocationIsNull,
          setIncidentLocationIsNull,
          // total settlement amount
          totalSettlementAmountMin,
          setTotalSettlementAmountMin,
          totalSettlementAmountMax,
          setTotalSettlementAmountMax,
          // medical limits available
          medicalLimitsAvailableMin,
          setMedicalLimitsAvailableMin,
          medicalLimitsAvailableMax,
          setMedicalLimitsAvailableMax,
          // property damage amount
          propertyDamageAmountMin,
          setPropertyDamageAmountMin,
          propertyDamageAmountMax,
          setPropertyDamageAmountMax,
          // running total
          runningTotalMin,
          setRunningTotalMin,
          runningTotalMax,
          setRunningTotalMax,
          selectedCaseStatuses,
          setSelectedCaseStatuses,
          activeCaseStatuses,
          setActiveCaseStatuses,
          selectedLegalStatuses,
          setSelectedLegalStatuses,
          activeLegalStatuses,
          setActiveLegalStatuses,
          resLimit,
          setResLimit,
          offset,
          setOffset,
        }),
        [
          accidentPictures,
          activeDocTypes,
          activeEntities,
          activeInvolved,
          activeLawFirm,
          activeLocations,
          activeLost,
          activeNotLocations,
          activeNotUpDocTypes,
          activeRoles,
          activeSources,
          activeStatuses,
          activeUnassignedRoles,
          activeUsers,
          apptAddedFrom,
          apptAddedTo,
          archived,
          assignedCm,
          assignedUsers,
          caseStatus,
          caseUpdatedFrom,
          caseUpdatedTo,
          clientDob,
          clientManager,
          currentPage,
          dateCreatedFrom,
          dateCreatedTo,
          dateOfIncident,
          dateOfIncidentFrom,
          dateOfIncidentTo,
          dateOpened,
          dateOpenedFrom,
          dateOpenedTo,
          hasCallback,
          hasSource,
          incidentLocationIsNull,
          involvedIndividuals,
          isFirstLoad,
          legal,
          liability,
          liabilityRequest,
          lostStatus,
          medDocs,
          medicalLimitsAvailableMax,
          medicalLimitsAvailableMin,
          movingViolation,
          needsSupIsNull,
          noLfUserAssigned,
          noPnAssigned,
          notLost,
          noteAddedFrom,
          noteAddedTo,
          npIntro,
          onboarded,
          orderingVal,
          overall,
          paginationUrl,
          pdSeverity,
          pdSeverityIsNull,
          policeReport,
          policyLimit,
          policyLimitKey,
          progressionFinished,
          propertyDamage,
          propertyDamageAmountMax,
          propertyDamageAmountMin,
          propertyDmgKey,
          runningTotal,
          runningTotalKey,
          runningTotalMax,
          runningTotalMin,
          search,
          selectedDocTypes,
          selectedEntities,
          selectedInvolved,
          selectedLawfirms,
          selectedLocations,
          selectedLost,
          selectedNotLocations,
          selectedNotUpDocTypes,
          selectedPM,
          selectedRoles,
          selectedSources,
          selectedStatuses,
          selectedUnassignedRoles,
          selectedUserType,
          settled,
          sortByLiabilityVal,
          totalSettledAmount,
          totalSettledAmtKey,
          totalSettlementAmountMax,
          totalSettlementAmountMin,
          umStatus,
          selectedCaseStatuses,
          setSelectedCaseStatuses,
          activeCaseStatuses,
          setActiveCaseStatuses,
          selectedLegalStatuses,
          setSelectedLegalStatuses,
          activeLegalStatuses,
          setActiveLegalStatuses,
          resLimit,
          offset,
        ]
      )}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
