import { MenuItem, Select } from "@mui/material/";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Editor } from "@tinymce/tinymce-react";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import FormField from "../../../../../global/FormComponents/field/FormField";
import { dateTimeStyles, selectStyles } from "../../constant";
import Modal from "../../../../../global/Modal";
import { CalendarMonth } from "@mui/icons-material";
import PropTypes from "prop-types";
import { format } from "date-fns";

export default function EditApptv2({
  selectedAppt,
  open,
  onClose,
  proCliTrigger,
  setProCliTrigger,
  onExpand,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dateTimeValue, setDateTimeValue] = useState("");
  const [value, setValue] = useState("");
  const [apptStatus, setApptStatus] = useState("");
  const [apptNotes, setApptNotes] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providerTypeList, setProviderTypeList] = useState([]);
  const [selectedProviderType, setSelectedProviderType] = useState("");

  useEffect(() => {
    if (selectedAppt) {
      setDateTimeValue(selectedAppt.scheduled);
      setValue(selectedAppt.scheduled);
      setApptStatus(selectedAppt.status);
      setApptNotes(selectedAppt.notes);
    }
  }, [selectedAppt]);

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setApptStatus("");
    setApptNotes("");
    setTimeout(() => {
      if (typeof setProCliTrigger === "function" && proCliTrigger !== undefined) {
        setProCliTrigger(!proCliTrigger);
      }
      onClose();
    });
  };

  const handleChange = (newValue) => {
    if (newValue === null || newValue === undefined) {
      setValue("");
    } else {
      setDateTimeValue(new Date(newValue).toISOString());
    }
  };

  const handleApptEdit = () => {
    setLoading(true);
    let patchedProcedure = {
      ...(dateTimeValue === "" ? {} : { scheduled: dateTimeValue }),
      ...(apptStatus === "" ? {} : { status: apptStatus }),
      ...(apptNotes === "" ? {} : { notes: apptNotes }),
      ...(selectedProviderType === ""
        ? {}
        : { provider_type: selectedProviderType }),
    };

    axios
      .patch(`/api/appointments/${selectedAppt.pk}/`, patchedProcedure, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
          setOpenError(!openError);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const fetchProviderTypes = async () => {
      try {
        const response = await axios.get(
          `/api/provider/${selectedAppt.provider.pk}/`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        );
        const results = response.data?.provider_types;
        if (results) {
          setProviderTypeList(results);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (selectedAppt) {
      fetchProviderTypes();
    }
    return () => {
      setProviderTypeList([]);
      setSelectedProviderType("");
    };
  }, [selectedAppt, accessToken]);

  useEffect(() => {
    if (providerTypeList.length && selectedAppt?.provider_type?.id) {
      setSelectedProviderType(selectedAppt?.provider_type?.id);
    }
  }, [providerTypeList, selectedAppt]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Edit Appointment Details"
      actions={[
        {
          label: "Update Appointment Details",
          isLoading: loading,
          onClick: handleApptEdit,
        },
      ]}
      onExpand={onExpand}
      maxWidth="md"
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="w-full flex flex-col gap-4">
          {!selectedAppt ? (
            "Please Select an Appointment to Continue"
          ) : (
            <>
              <div>
                <div className="text-lg underline font-bold">
                  APPOINTMENT INFO
                </div>
                <div>
                  <span>Name:</span> <span>{selectedAppt.provider.name}</span>
                </div>
                <div>
                  <span>Type:</span>{" "}
                  <span>{selectedAppt?.provider_type?.name}</span>
                </div>
                <div>
                  <span>Phone:</span> <span>{selectedAppt.provider.phone}</span>
                </div>
                <div>
                  <span>Date/Time:</span> <span>{selectedAppt.scheduled ? format(new Date(selectedAppt.scheduled), "P p") : ""}</span>
                </div>
              </div>

              <div>
                <div className="text-lg underline font-bold">CLIENT INFO</div>
                <div>
                  <span>Name: </span> <span>{selectedAppt.client.name}</span>
                </div>
                <div>
                  <span>Email:</span> <span>{selectedAppt.client.email}</span>
                </div>
                <div>
                  <span>Phone:</span> <span>{selectedAppt.client.phone}</span>
                </div>
              </div>
            </>
          )}
          <div>
            <span className="text-lg font-bold underline">
              EDIT APPOINTMENT DETAILS
            </span>
            <div className="flex flex-col gap-4">
              <FormField label="Provider Type">
                <Select
                  className="w-full"
                  sx={{
                    ...selectStyles,
                    "& fieldset": {
                      borderColor: "#9ca3af",
                    },
                  }}
                  value={selectedProviderType}
                  onChange={(event) =>
                    setSelectedProviderType(event.target.value)
                  }
                  placeholder="Please select a provider type"
                  displayEmpty
                  renderValue={(value) =>
                    !value ? (
                      <span className="text-gray-400">
                        Please select a provider type
                      </span>
                    ) : (
                      providerTypeList.find((p) => p.id === value)?.name
                    )
                  }
                >
                  {providerTypeList?.map((protypes) => {
                    return (
                      <MenuItem value={protypes.id} key={protypes.id}>
                        {protypes.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormField>
              <FormField label="Appointment Date">
                <DesktopDateTimePicker
                  value={!value ? "" : new Date(value)}
                  sx={dateTimeStyles}
                  components={{
                    OpenPickerIcon: CalendarMonth,
                  }}
                  slotProps={{
                    inputAdornment: {
                      position: "start",
                    },
                  }}
                  onChange={handleChange}
                />
              </FormField>
              <FormField label="Appointment Status">
                <Select
                  required
                  className="w-full"
                  sx={selectStyles}
                  value={apptStatus}
                  onChange={(event) => {
                    setApptStatus(event.target.value);
                  }}
                >
                  <MenuItem value={"Assigned"}>Scheduled</MenuItem>
                  <MenuItem value={"Overdue"}>Missed</MenuItem>
                  <MenuItem value={"Done"}>Complete</MenuItem>
                </Select>
              </FormField>

              <FormField label="Appointment Notes">
                <Editor
                  textareaName="content"
                  apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                  value={apptNotes}
                  onEditorChange={(newText) => {
                    setApptNotes(newText);
                  }}
                  init={{
                    menubar: false,
                    branding: false,
                    plugins: ["lists"],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                  }}
                />
              </FormField>
            </div>
          </div>
          <div className="text-sm my-2">
            <span className="text-red-600 font-bold">Warning:</span> Clicking
            the "Update Appointment Details" button below will permanently
            rewrite over the current appointment data, after proceeding previous
            data will be unrecoverable. Please make sure you want the above
            changes to take effect before proceeding.
          </div>
          <ResponseModal
            title="Appointment Details Updated"
            description="Successfully Updated Appointment Details"
            openBool={openSuccess}
            setOpenBool={setOpenSuccess}
            handleCloseFunc={handleOpenSuccess}
          />
          <ResponseModal
            title="Error Updating Appointment Data"
            isError={true}
            openBool={openError}
            setOpenBool={setOpenError}
            errorMessage={errorArray}
          />
        </div>
      </LocalizationProvider>
    </Modal>
  );
}

EditApptv2.propTypes = {
  selectedAppt: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  proCliTrigger: PropTypes.bool,
  setProCliTrigger: PropTypes.func,
  onExpand: PropTypes.func,
};
