export const STRINGS = {
  LOADING:"Loading",
  UPDATING:"Updating",
  ERROR:"An error has occurred",
  TOTAL_CASES: "Total cases",
  VISIBLE_CASES: "Cases Visible To You",
  WITHOUT_ROLE: "Without Role Assignment",
  WITH_ROLE: "With Role Assignment",
  NO_ROLES_AVAILABLE: "No Roles Available",
  NO_AVAILABLE_PAGES: "No Available Pages"
};