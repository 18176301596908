import { createContext, useReducer } from "react";
import { initialState, dashboardReducer } from "../state/dashboardReducer";

export const DashboardFilterDispatchContext = createContext();
export const DashboardFilterStateContext = createContext();

const DashboardFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dashboardReducer, initialState);

  return (
    <DashboardFilterDispatchContext.Provider value={dispatch}>
      <DashboardFilterStateContext.Provider value={state}>
        {children}
      </DashboardFilterStateContext.Provider>
    </DashboardFilterDispatchContext.Provider>
  );
};

export default DashboardFilterProvider;
