import React, { useContext, useEffect } from "react";
import Cookies from "js-cookie";
import DataContext from "../../../../../context/DataContext";
import ClientListSearchv3 from "../../FullPages/ClientSearchFullv3";

export default function GlobalUserDash() {
  const {
    isLoading,
    setLoading,
    caseManagerPk,
    setUserType,
    accessToken,
    setPatchNotifs,
    setRefreshDialog,
    setCurrentPatchV,
    setNeedsUpdate,
    userRoles,
  } = useContext(DataContext);

  /* TODO: Should this be here? Does this need to be moved to App.js? */

  useEffect(() => {
    if (!caseManagerPk) {
      setLoading(true);
    } else {
      setUserType(Cookies.get("userType"));
      setLoading(false);
    }
  }, [
    accessToken,
    setUserType,
    setCurrentPatchV,
    setRefreshDialog,
    setNeedsUpdate,
    setPatchNotifs,
    setLoading,
    caseManagerPk,
    userRoles.permissions,
  ]);

  return (
    <div id="Dashboard" data-testid="Dashboard">
      {!isLoading && <ClientListSearchv3 isDashboardVersion={true} />}
    </div>
  );
}
