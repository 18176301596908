import { ContentCopy } from "@mui/icons-material/";
import {
  CircularProgress,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import PaginationControls from "../../../global/PaginationControls";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import MultiSelectSearchV2 from "../../../global/MultiSelectSearchV2";
import { CustomChip } from "../../../global/CustomQueryChip";
import qs from "query-string";
// import SearchOptionGroup from "../../../global/SearchOptionGroup";

export default function CaseManagerContact() {
  const { accessToken, userRoles, userType } = useContext(DataContext);
  const [caseManagers, setCaseManagers] = useState();
  const [copiedAlert, setCopiedAlert] = useState(`Copy`);
  const [lawfirmNames, setLawfirmNames] = useState();
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [activeEntities, setActiveEntities] = useState([]);
  // const [entityIsNull, setEntityIsNull] = useState(null);

  useEffect(() => {
    if (userRoles.permissions.includes("lawyer.view_lawfirm")) {
      let lfObj = {};

      axios
        .get(`/api/lawfirm/?limit=1000`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;

          for (const item in data) {
            lfObj[data[item].pk] = data[item].name;
          }

          setLawfirmNames(lfObj);
        });
    } else {
      return;
    }
  }, [setLawfirmNames, accessToken, userRoles.permissions]);

  const copyToClipboard = (value, name, type) => {
    navigator.clipboard.writeText(value);
    setCopiedAlert(
      `Copied ${name}'s ${type === "phone" ? "phone number" : "email"}`
    );
  };

  const columnClasses = userType !== "Law Firm" ? "md:w-1/4" : "md:w-1/3";

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  const resetPagination = () => {
    setPaginationUrl("");
    setCurrentPage(1);
  };

  useDebouncedEffect(
    () => {
      if (userRoles.permissions.includes("users.view_user")) {
        const querytest = qs.stringify(
          {
            search: search === "" ? null : search,
            // entity__isnull: entityIsNull === null ? null : entityIsNull,
          },
          {
            skipNull: true,
          }
        );
        const entitiesQuery =
          selectedEntities.length === 0
            ? ""
            : `&entity__in=${selectedEntities}`;
        axios
          .get(
            `/api/users/contact_list/?${paginationUrl}&ordering=${
              selectedEntities.length === 0 ? "name" : "entity"
            }&${querytest}${entitiesQuery}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            setResultCount({
              total: response.data.count,
              current: response.data.results.length,
            });
            setCaseManagers(response.data.results);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setLoading(false);
      }
    },
    [
      paginationUrl,
      setCurrentPage,
      search,
      userRoles.permissions,
      selectedEntities,
    ],
    250
  );

  return (
    <div className="bg-white px-6 py-10">
      <div className="rounded-md border-purple-800 p-4 border-2 mb-4">
        <h2 className="font-bold text-xl text-center">Emails</h2>
        <div className="m-auto w-fit">
          <List>
            <ListItem className="justify-center md:flex-row flex-col">
              <strong>Law Intake :</strong>{" "}
              <span className="hidden md:block">&nbsp;</span>
              <a
                href={`mailto:law.intake@netproapp.com`}
                target="_blank"
                className="hover:underline"
                rel="noreferrer"
              >
                law.intake@netproapp.com
              </a>
              <span className="hidden md:block">&nbsp;</span>
              <Tooltip title={copiedAlert}>
                <ContentCopy
                  onClick={() =>
                    copyToClipboard(
                      "law.intake@netproapp.com",
                      "Law Intake",
                      "email"
                    )
                  }
                  onMouseLeave={() => setCopiedAlert("Copy")}
                />
              </Tooltip>
            </ListItem>
            <ListItem className="justify-center md:flex-row flex-col">
              <strong>Law Admin :</strong>{" "}
              <span className="hidden md:block">&nbsp;</span>
              <a
                href={`mailto:law.admin@netproapp.com
                `}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                law.admin@netproapp.com
              </a>
              <span className="hidden md:block">&nbsp;</span>
              <Tooltip title={copiedAlert}>
                <ContentCopy
                  onClick={() =>
                    copyToClipboard(
                      "law.admin@netproapp.com",
                      "Law Admin",
                      "email"
                    )
                  }
                  onMouseLeave={() => setCopiedAlert("Copy")}
                />
              </Tooltip>
            </ListItem>
            <ListItem className="justify-center md:flex-row flex-col">
              <strong>Information Tech (IT) :</strong>{" "}
              <span className="hidden md:block">&nbsp;</span>
              <a
                href={`mailto:it@netproapp.com

                `}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                it@netproapp.com
              </a>
              <span className="hidden md:block">&nbsp;</span>
              <Tooltip title={copiedAlert}>
                <ContentCopy
                  onClick={() =>
                    copyToClipboard(
                      "it@netproapp.com",
                      "Information Tech (IT)",
                      "email"
                    )
                  }
                  onMouseLeave={() => setCopiedAlert("Copy")}
                />
              </Tooltip>
            </ListItem>
            <ListItem className="justify-center md:flex-row flex-col">
              <strong>Medical Billing/Docs :</strong>{" "}
              <span className="hidden md:block">&nbsp;</span>
              <a
                href={`mailto:med@netproapp.com`}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                med@netproapp.com
              </a>
              <span className="hidden md:block">&nbsp;</span>
              <Tooltip title={copiedAlert}>
                <ContentCopy
                  onClick={() =>
                    copyToClipboard(
                      "med@netproapp.com",
                      "Medical Billing",
                      "email"
                    )
                  }
                  onMouseLeave={() => setCopiedAlert("Copy")}
                />
              </Tooltip>
            </ListItem>
            <ListItem className="justify-center md:flex-row flex-col">
              <strong>Crash reports/PD/accident pics :</strong>
              <span className="hidden md:block">&nbsp;</span>
              <a
                href={`mailto:info@netproapp.com`}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                info@netproapp.com
              </a>
              <span className="hidden md:block">&nbsp;</span>
              <Tooltip title={copiedAlert}>
                <ContentCopy
                  onClick={() =>
                    copyToClipboard(
                      "info@netproapp.com",
                      "Crash reports/PD/accident pic",
                      "email"
                    )
                  }
                  onMouseLeave={() => setCopiedAlert("Copy")}
                />
              </Tooltip>
            </ListItem>
          </List>
        </div>
      </div>
      <div className="mb-4">
        {" "}
        <CustomChip
          setActiveItems={setActiveEntities}
          activeItems={activeEntities}
          selectedItems={selectedEntities}
          setSelectedItems={setSelectedEntities}
          title="Entities"
          resetPagination={resetPagination}
        />
      </div>
      <div className="flex items-center relative pb-2">
        <div className="w-1/2">
          <Tooltip title="Search">
            <TextField
              className="placeholder:text-center rounded-lg w-full text-sm"
              placeholder="Search"
              size="small"
              name="search"
              value={search}
              InputLabelProps={{ shrink: true }}
              label="Search"
              onChange={(e) => {
                setSearch(e.target.value);
                resetPagination();
              }}
            />
          </Tooltip>
        </div>
        {userRoles.permissions.includes("users.view_entity") ? (
          <div className="w-[45%] mx-4 rounded-md shadow-md px-4 py-2 absolute right-0 top-0 z-10 bg-white">
            <MultiSelectSearchV2
              title="Sort by Entities"
              searchTitle="Entities"
              selectedItems={selectedEntities}
              setSelectedItems={setSelectedEntities}
              setActiveItems={setActiveEntities}
              activeItems={activeEntities}
              resetPagination={resetPagination}
              nameKey={"name"}
              valueKey={"pk"}
              apiUrl="/api/entities/contact_list/?"
            />
          </div>
        ) : (
          ""
        )}
        {/* <div className="w-[30%] mx-4 absolute right-[32%] top-0 z-10">
          <SearchOptionGroup
            setValue={setEntityIsNull}
            value={entityIsNull}
            title="Needs Entity"
            resetPagination={resetPagination}
          />
        </div> */}
      </div>
      <List className="grid max-h-[50vh] overflow-y-auto">
        {loading ? (
          <div className="flex justify-center my-24">
            <CircularProgress color="secondary" />
          </div>
        ) : caseManagers.length === 0 ? (
          <div className="flex justify-center my-24">No Results</div>
        ) : (
          <>
            {!caseManagers
              ? ""
              : caseManagers.map((cm, index) => (
                  <ListItem
                    className="py-4 pt-0 items-center md:px-4 px-2"
                    key={index + cm.name}
                  >
                    <div
                      className={
                        "text-black shadow-md py-1 my-1 w-full flex rounded-md justify-between items-center md:flex-row flex-col"
                      }
                    >
                      <div
                        className={`block ${columnClasses} text-left pl-2 text-left pb-2 md:pb-0`}
                      >
                        {!cm.name ? "Unknown" : cm.name}
                      </div>
                      <div
                        className={`${columnClasses} text-left pl-2 text-center flex items-center lg:flex-row flex-col hidden md:block pb-2 md:pb-0`}
                      >
                        {!cm.phone ? (
                          "Unknown"
                        ) : (
                          <>
                            <Tooltip title={copiedAlert}>
                              <button
                                className="bg-none border-0 mr-2"
                                onClick={() => {
                                  copyToClipboard(cm.phone, cm.name, "phone");
                                }}
                                onMouseLeave={() => setCopiedAlert("Copy")}
                              >
                                {!cm ? "" : cm.phone}
                              </button>
                            </Tooltip>
                          </>
                        )}
                      </div>
                      <div
                        className={`${columnClasses} text-left pl-2 text-center flex items-center lg:flex-row flex-col md:hidden pb-2 md:pb-0`}
                      >
                        {!cm.phone ? (
                          "Unknown"
                        ) : (
                          <>
                            <Tooltip title={copiedAlert}>
                              <button
                                className="bg-none border-0 mr-2"
                                onClick={() => {
                                  copyToClipboard(cm.phone, cm.name, "phone");
                                }}
                                onMouseLeave={() => setCopiedAlert("Copy")}
                              >
                                {!cm ? "" : cm.phone}
                              </button>
                            </Tooltip>
                          </>
                        )}
                      </div>
                      <div
                        className={`${columnClasses} text-left text-sm pl-2 text-center ml-2 pb-2 md:pb-0`}
                      >
                        {!cm.email ? (
                          "Unknown"
                        ) : (
                          <>
                            <a
                              href={`mailto:${cm.email}`}
                              target="_blank"
                              rel="noreferrer"
                              className="hover:underline break-words"
                            >
                              {!cm.email ? "" : cm.email}
                            </a>
                            &nbsp;
                            <Tooltip title={copiedAlert}>
                              <ContentCopy
                                onClick={() =>
                                  copyToClipboard(cm.email, cm.name, "email")
                                }
                                onMouseLeave={() => setCopiedAlert("Copy")}
                              />
                            </Tooltip>
                          </>
                        )}
                      </div>
                      {userType !== "Law Firm" ? (
                        <span
                          className={`block md:w-1/5 text-left pl-2 text-center border-none pb-2 md:pb-0`}
                        >
                          {!lawfirmNames
                            ? ""
                            : lawfirmNames[cm.works_for_law_firm_pk]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </ListItem>
                ))}
            <ListItem className={`justify-center py-10`}>
              <PaginationControls
                resultCount={resultCount}
                handleChange={handleChange}
                currentPage={currentPage}
              />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );
}
