import { Button, ListItem, Rating, Tooltip, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import { ControlPoint, ListAltOutlined } from "@mui/icons-material";
import axios from "../../../../../api/axios";
import ResponseModal from "../../../../../global/ResponseModal";
import ProviderDetailv2 from "../../../../../global/ProviderDetailModal/ProviderDetailv2";
import { ENTITY_TYPE_PROVIDER } from "../../../../../../globalConstants";
import PropTypes from 'prop-types';

export default function ProviderListItem({
  provider,
  setTrigger,
  trigger,
  setSelectedProvider
}) {
  const { userRoles, accessToken, loggedInUser } = useContext(DataContext);
  const [providerDetailDisplay, setProviderDetailDisplay] = useState(false);

  const [ratingSuccess, setRatingSuccess] = useState(false);
  const [ratingErr, setRatingError] = useState(false);
  const [ratingErrRes, setRatingErrRes] = useState([]);
  const isProvider = loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;

  const handleRatingChange = (val) => {
    const patchData = {
      rating: val,
    };

    axios
      .patch(
        `/api/provider/${provider.pk}/`,
        patchData,

        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setRatingSuccess(true);
        setTrigger(!trigger);
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setRatingError(true);
          setRatingErrRes(errArr);
        }
      });
  };

  // const handleClick = (provider) => {
  //   setSelectedProvider(provider.provId);
  // };

  return (
    <div>
      <ListItem className={`md:grid ${!isProvider ? "md:grid-cols-5" : "md:grid-cols-4"} flex justify-between content-center shadow-md`}>
        <span className="block text-left">{provider.name}</span>
        <span className="font-normal block text-center">
          {(provider?.provider_types?.map((type) => type?.name) || []).join(", ")}
        </span>
        {!isProvider &&
          userRoles.permissions.includes("provider.view_provider") &&
          <div>
            {provider.rating === null
              ? <Button
                  onClick={() => setProviderDetailDisplay(true)}
                  className="md:block m-auto text-black hidden"
                  disabled={
                    !userRoles.permissions.includes("provider.change_provider")
                  }
                >
                  Add Rating <ControlPoint />
                </Button>
              : <Rating
                  value={+provider.rating}
                  className="md:flex hidden justify-center w-fit m-auto"
                  disabled={
                    !userRoles.permissions.includes("provider.change_provider")
                  }
                  onChange={newValue => {
                    handleRatingChange(newValue);
                  }}
                />}
          </div>}

        <div className="font-bold block text-center">
          {provider.distance} miles
        </div>
        <div className="flex justify-end md:flex-row flex-col">
          <Tooltip title="Provider Details">
            <Button
              onClick={() => setProviderDetailDisplay(true)}
              className="flex rounded-md hover:text-purple-500 text-purple-300 hover:text-white hover:bg-purple-300"
              style={{ color: "#9333ea" }}
            >
              <span>
                <ListAltOutlined />
              </span>
            </Button>
          </Tooltip>
          <Tooltip title="Select Provider">
            <IconButton
              className="self-center w-fit"
              onClick={() => setSelectedProvider(provider)}
            >
              <ControlPoint />
            </IconButton>
          </Tooltip>
        </div>
      </ListItem>
      {!ratingSuccess ? (
        ""
      ) : (
        <ResponseModal
          title="Rating Updated"
          description="Provider Rating has been Updated"
          openBool={ratingSuccess}
          setOpenBool={setRatingSuccess}
        />
      )}
      {!ratingErr ? (
        ""
      ) : (
        <ResponseModal
          title="Update Rating Failed"
          isError={true}
          openBool={ratingErr}
          setOpenBool={setRatingErrRes}
          errorMessage={ratingErrRes}
        />
      )}
      {providerDetailDisplay ? (
        <ProviderDetailv2
          providerDetail={provider}
          open={providerDetailDisplay}
          setOpen={setProviderDetailDisplay}
        />
      ) : (
        ""
      )}
    </div>
  );
}

ProviderListItem.propTypes = {
  provider: PropTypes.object.isRequired,
  setTrigger: PropTypes.func.isRequired,
  trigger: PropTypes.bool.isRequired,
  setSelectedProvider: PropTypes.func.isRequired
};