import React, { useContext } from 'react';
import PermissionWrapper from '../../../../../../../global/PermissionWrapper';
import { StaticBarStateContext } from '../../context/StaticBarContext';
import Liability from '../fields/Liability';
import MaxPainLevel from '../fields/MaxPainLevel/MaxPainLevel';
import MedicalLimits from '../fields/MedicalLimits';
import NoAccessMessage from '../fields/NoAccessMessage';
import ProviderMax from '../fields/ProviderMax/ProviderMax';
import RunningTotal from '../fields/RunningTotal';
import Column from '../ui/Column';
import STRINGS from '../utils/strings';
import CurrentBalance from '../fields/CurrentBalance';

const CoverageSummary = () => {
  const state = useContext(StaticBarStateContext);

  return (
    <Column>
      {state.data?.liability_status && (
        <PermissionWrapper permission={state.data?.liability_status?.can_view}>
          <Liability
            fieldName={state.data?.liability_status?.fieldName}
            value={state.data?.liability_status?.value}
            flag={state.data?.liability_status?.flag_state}
            clock={state.data?.liability_status?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.max_pain_level && (
        <PermissionWrapper
          permission={
            state.data?.max_pain_level?.can_view &&
            state.data?.max_pain_body_part?.can_view
          }
        >
          <MaxPainLevel
            fieldName={state.data?.max_pain_level?.fieldName}
            painLevel={state.data?.max_pain_level?.value}
            bodyPart={state.data?.max_pain_body_part?.value}
            flag={state.data?.max_pain_level?.flag_state}
            clock={state.data?.max_pain_level?.clock_state}
          />
        </PermissionWrapper>
      )}

      <div className="flex space-between">
        {state.data?.medical_limits && (
          <>
            <PermissionWrapper
              permission={state.data?.medical_limits?.can_view}
            >
              <MedicalLimits
                fieldName={state.data?.medical_limits?.fieldName}
                value={state.data?.medical_limits?.value}
                flag={state.data?.medical_limits?.flag_state}
                clock={state.data?.medical_limits?.clock_state}
              />
            </PermissionWrapper>
            <PermissionWrapper
              permission={!state.data?.medical_limits?.can_view}
            >
              <NoAccessMessage value={STRINGS.MEDICAL_LIMITS_NO_PERM} />
            </PermissionWrapper>
          </>
        )}
        {state.data?.provider_max && (
          <PermissionWrapper permission={state.data?.provider_max?.can_view}>
            <ProviderMax
              fieldName={state.data?.provider_max?.fieldName}
              value={state.data?.provider_max?.value}
              flag={state.data?.provider_max?.flag_state}
              clock={state.data?.provider_max?.clock_state}
            />
          </PermissionWrapper>
        )}
      </div>
      <div className="flex justify-between">
      {state.data?.current_balance_total && (
          <>
            <PermissionWrapper permission={state.data?.current_balance_total?.can_view}>
              <CurrentBalance
                fieldName={state.data?.current_balance_total?.fieldName}
                value={state.data?.current_balance_total?.value}
                flag={state.data?.current_balance_total?.flag_state}
                clock={state.data?.current_balance_total?.clock_state}
              />
            </PermissionWrapper>
            <PermissionWrapper
              permission={!state.data?.current_balance_total?.can_view}
            >
              <NoAccessMessage value={STRINGS.CURRENT_BALANCE_NO_PERM} />
            </PermissionWrapper>
          </>
        )}
        {state.data?.running_total && (
          <>
            <PermissionWrapper permission={state.data?.running_total?.can_view}>
              <RunningTotal
                fieldName={state.data?.running_total?.fieldName}
                value={state.data?.running_total?.value}
                flag={state.data?.running_total?.flag_state}
                clock={state.data?.running_total?.clock_state}
              />
            </PermissionWrapper>
            <PermissionWrapper
              permission={!state.data?.running_total?.can_view}
            >
              <NoAccessMessage value={STRINGS.RUNNING_TOTAL_NO_PERM} />
            </PermissionWrapper>
          </>
        )}
      </div>
    </Column>
  );
};

export default CoverageSummary;
