import NotificationAccordion from "./NotificationAccordion";

const NotificationsFilter = ({ filterData }) => {
  return (
    <div>
      {filterData &&
        filterData.map((filterObj, index) => (
          <NotificationAccordion key={index} accordionItem={filterObj} />
        ))}
    </div>
  );
};
export default NotificationsFilter;
