export const caseSearch = {
  path: "search",
  title: "Case Search",
  color: "purple",
  order: 1,
  active:true,
};

export const completeNoBill = {
  path: "completed-appointment-with-no-bill",
  title: "Complete - No Bill",
  color: "purple",
  order: 2,
  active:false,
};

export const actionItems = {
  path: "action-items",
  title: "Action Items",
  color: "orange",
  order: 3,
  active:false,
};

export const followUpCalendar = {
  path: "followup-calendar",
  title: "Follow Up Calendar",
  color: "purple",
  order: 4,
  active:false,
};

export const appointmentCalendar = {
  path: "appointment-calendar",
  title: "Appointment Calendar",
  color: "sky",
  order: 5,
  active:false,
};

export const appointmentsRequiringReview = {
  path: "missed-appts",
  title: "Appointments Requiring Review",
  color: "orange",
  order: 6,
  active:false,
};

export const needsAppointment = {
  path: "needs-appointment",
  title: "Needs Appointment",
  color: "red",
  order: 7,
  active:false,
};

export const providerClientSearch = {
  path: "provider-client-search",
  title: "Provider Client Search",
  color: "purple",
  order: 8,
  active:false,
};

export const inNetworkProviders = {
  path: "in-network-providers",
  title: "In Network Providers",
  color: "green",
  order: 9,
  active:false,
};

export const needsSupervisor = {
  path: "needs-supervisor",
  title: "Needs Supervisor",
  color: "red",
  order: 10,
  active:false,
};

export const liabilityRequested = {
  path: "liability-requested",
  title: "Liability Requested",
  color: "orange",
  order: 11,
  active:false,
};

export const needsPropertyDamageCost = {
  path: "needs-property-damage",
  title: "Needs Property Damage Cost",
  color: "orange",
  order: 12,
  active:false,
};

export const needsPolicyLimit = {
  path: "needs-policy-limit",
  title: "Needs Policy Limit",
  color: "orange",
  order: 13,
  active:false,
};

export const needsPoliceReport = {
  path: "needs-police-report",
  title: "Needs Police Report",
  color: "orange",
  order: 14,
  active:false,
};

export const sortByLiability = {
  path: "liability-sort",
  title: "Sort by Liability",
  color: "purple",
  order: 15,
  active:false,
};

export const documentSearch = {
  path: "document-search",
  title: "Document Search",
  color: "purple",
  order: 16,
  active:false,
};

export const callLog = {
  path: "phone-log",
  title: "Call Log",
  color: "purple",
  order: 17,
  active:false,
};

export const messageLog = {
  path: "sms-log",
  title: "Message Log",
  color: "purple",
  order: 18,
  active:false,
};

export const actionLog = {
  path: "action-log",
  title: "Action Log",
  color: "red",
  order: 19,
  active:false,
};
