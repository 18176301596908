import React, { useContext, useEffect, useState } from "react";
import ProviderListItem from "./ProviderListItem";
import {
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Link,
  TextField,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PaginationControls from "../../../../../global/PaginationControls";
import LoadingIndicator from "../../../../../global/LoadingIndicator";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import { Close, Info } from "@mui/icons-material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";

export default function NearestProvidersList({
  results,
  currentPage,
  handleChange,
  resultCount,
  loading,
  provType,
  setProvType,
  setSelectedProvider,
  outOfNetwork,
  setOutOfNetwork,
  setMaxDistance,
  search,
  setSearch,
  time,
  setTime,
  defaultWeek,
  dayOfWeek,
  setDayOfWeek,
  filterUpdate,
}) {
  const { accessToken } = useContext(DataContext);
  const [provTypeOptions, setProvTypeOptions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/api/provider_types`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setProvTypeOptions(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setProvTypeOptions, accessToken]);

  useDebouncedEffect(
    () => {
      filterUpdate();
    },
    [search, provType, dayOfWeek, time],
    100
  );

  return (
    <div>
      <div className="flex mx-2 mt-4 mb-2">
        <FormControl className="w-1/4" size="small">
          <InputLabel id="provider-type-label">Provider Type</InputLabel>
          <Select
            labelId="provider-type-label"
            id="provider-type"
            value={provType}
            label="Provider Type"
            onChange={(e) => setProvType(e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {!provTypeOptions
              ? ""
              : provTypeOptions.map((opt) => (
                  <MenuItem key={opt.id} value={opt.name}>
                    {opt.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <TextField
          className="placeholder:text-center rounded-lg w-1/3 text-sm pr-4 md:pr-0 ml-1"
          placeholder="Provider Name"
          id="provider-name-search"
          size="small"
          name="search"
          value={search}
          InputLabelProps={{ shrink: true }}
          label="Provider Name"
          onChange={(e) => setSearch(e.target.value)}
        />{" "}
        <FormControl className="w-1/4 ml-1" size="small">
          <InputLabel id="day-of-week-label">Day of Availability</InputLabel>
          <Select
            labelId="day-of-week-label"
            id="day-of-week"
            value={dayOfWeek}
            label="Day of Availability"
            onChange={(e) => {
              setDayOfWeek(e.target.value);
            }}
          >
            {dayOfWeek === "" ? (
              ""
            ) : (
              <MenuItem
                value={""}
                id="remove-day-value"
                className="font-bold text-red-600"
              >
                Remove
              </MenuItem>
            )}
            {!defaultWeek
              ? ""
              : defaultWeek.map((day) => {
                  return (
                    <MenuItem value={day.key} key={day.key}>
                      {day.label}
                    </MenuItem>
                  );
                })}
          </Select>
        </FormControl>
        <div className="flex items-center w-1/4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label="Hour of Availability"
              value={new Date(time)}
              className="w-full mx-auto text-black ml-1"
              slotProps={{
                textField: {
                  variant: "outlined",
                  size: "small",
                  error: false,
                },
              }}
              onChange={(newValue) => {
                setTime(newValue);
              }}
            />
          </LocalizationProvider>
          {!time ? (
            ""
          ) : (
            <IconButton onClick={() => setTime("")}>
              <Close className="text-red-600" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="max-h-[32vh] overflow-y-auto">
        {loading ? <LoadingIndicator isLoading={loading} /> : ""}
        <List className="mx-2">
          {results.length === 0 ? (
            <div className="w-full">
              <p className="text-center my-2">
                <Tooltip title="Try using a custom address or allow Out of Network Providers to find results">
                  <Info className="text-gray-300 text-[16px]" />
                </Tooltip>
                &nbsp; No Results Found
                <br />
                <div className="ml-2">
                  <FormControlLabel
                    label={"Include out of Network Providers"}
                    control={
                      <Checkbox
                        checked={outOfNetwork}
                        onClick={(e) => {
                          setOutOfNetwork(e.target.checked);
                          setMaxDistance("400");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </div>
                <br />
                <Link
                  variant="outlined"
                  className="text-center mx-auto w-fit"
                  onClick={() => {
                    navigate("/create-provider");
                  }}
                >
                  Add Out of Network Provider
                </Link>{" "}
              </p>
            </div>
          ) : (
            results.map((prov) => (
              <ProviderListItem
                provider={prov}
                key={prov.pk}
                setSelectedProvider={setSelectedProvider}
              />
            ))
          )}
        </List>
      </div>
      <div className="w-fit mx-auto my-2">
        {" "}
        <PaginationControls
          resultCount={resultCount}
          handleChange={handleChange}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}
