import { Typography } from "@mui/material";
import FormatISODate from "../../../../../../../../global/FormatISODate";
import { useContext } from "react";
import InNetworkIcon from "../../../../../../../../ui library/icons/InNetworkIcon";
import OutOfNetworkIcon from "../../../../../../../../ui library/icons/OutOfNetworkIcon";
import { ProviderListStateContext } from "../../context/ProviderListProvider";
import Colors from "../../../../../../../../global/Colors";
import { STRINGS } from "../utils/strings";
import FormatCurrency from "../../../../../../../../global/FormatCurrency";

export default function ProviderListSummaryv2({ accordionItem }) {
  const providerListStateContext = useContext(ProviderListStateContext);
  const proCliData = providerListStateContext.providersData.get(
    accordionItem.index
  ).proCliData;
  const createSubTitle = (providerTypes = []) => {
    return providerTypes && providerTypes.length > 0
      ? `(${providerTypes.map((providerType) => providerType.name).join(", ")})`
      : "";
  };

  const getProcliIcon = (provider) => {
    if (provider && provider.in_network) {
      if (provider.receiving_clients) return <InNetworkIcon />;
      else return <OutOfNetworkIcon strokeColor={Colors.yellow[600]} />;
    }
    return <OutOfNetworkIcon strokeColor={Colors.blue[600]} />;
  };

  const icon = getProcliIcon(proCliData?.provider);
  const title = proCliData?.provider?.name;
  const subTitle = createSubTitle(proCliData?.provider?.provider_types);
  const inNetwork =
    proCliData.provider.in_network !== null
      ? proCliData?.provider.in_network
        ? STRINGS.IN_NETWORK
        : STRINGS.OUT_OF_NETWORK
      : "";
  const notes =
    proCliData.provider.in_network && !proCliData.provider.receiving_clients
      ? STRINGS.NOT_ACCEPTING_PATIENTS
      : "";
  const lastAppointment = FormatISODate(
    proCliData?.last_appointment,
    "MM/dd/yyyy"
  );
  const currentBalance = FormatCurrency(proCliData?.current_balance);
  const treatmentStatus = proCliData?.finished_treatment
    ? STRINGS.TREATMENT_STATUS.COMPLETE
    : STRINGS.TREATMENT_STATUS.TREATING;

  return (
    <div className="flex flex-row items-center justify-between normal-case w-full pr-2">
      <div className="flex flex-row items-center px-4 w-3/5">
        <div>{icon}</div>
        <div className="flex flex-col px-4">
          {title && (
            <Typography className="text-md text-black font-bold text-left pb-1 normal-case">
              {title}
              {inNetwork && (
                <span className="font-normal text-sm text-black text-left pb-1 normal-case">{` - ${inNetwork}`}</span>
              )}
            </Typography>
          )}
          {subTitle && (
            <Typography className="text-xs text-gray-500 left-align text-left normal-case">
              {notes && (
                <span className="text-black font-bold">
                  {"Note: "}&nbsp;
                  <span className="font-normal">{notes} </span>
                </span>
              )}
              <span>{subTitle}</span>
            </Typography>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-end gap-2 w-2/5">
        <div className="w-1/2">
          <Typography className="text-sm text-black font-bold text-left pb-1 normal-case">
            {`Current Balance: `}&nbsp;
            <span className="font-normal" style={{ whiteSpace: "pre" }}>
              {currentBalance !== null
                ? currentBalance
                : STRINGS.NA.padEnd(10, " ")}
            </span>
          </Typography>
        </div>
        {treatmentStatus && (
          <div className="w-1/3">
            <Typography className="text-sm text-black font-bold text-left pb-1 normal-case">
              {`Status: `}
              {
                <span className="font-normal">
                  {treatmentStatus.padEnd(15, " ")}
                </span>
              }
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
