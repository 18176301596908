import { createContext, useReducer } from "react";
import {
  initialState,
  providersListReducer,
} from "../state/providersListReducer";

export const ProviderListDispatchContext = createContext();
export const ProviderListStateContext = createContext();

const ProviderListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(providersListReducer, initialState);

  return (
    <ProviderListDispatchContext.Provider value={dispatch}>
      <ProviderListStateContext.Provider value={state}>
        {children}
      </ProviderListStateContext.Provider>
    </ProviderListDispatchContext.Provider>
  );
};

export default ProviderListProvider;
