import { Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

export default function LoadingButton({
  onSubmit,
  isLoading,
  color,
  children
}) {
  return (
    <Button
      size="large"
      onClick={onSubmit}
      variant="outlined"
      color={color}
      className="font-bold"
      disabled={isLoading}
      endIcon={isLoading ? <CircularProgress size="14px" /> : null}
    >
      {children}
    </Button>
  );
}

LoadingButton.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.element
};
