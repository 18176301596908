import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const StyledParagraph = styled("p")({
  fontSize: "10px",
  color: "gray",
  whiteSpace: "normal",
  wordWrap: "break-word",
  overflow: "hidden",
  margin: 0,
  padding: "8px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  backgroundColor: "white",
  width: "100%",
  minHeight: "33px",
});

export const ReadonlyInput = ({ value, ...props }) => {
  return <StyledParagraph {...props}>{value}</StyledParagraph>;
};

ReadonlyInput.propTypes = {
  value: PropTypes.string,
};

