//NOTIFICATIONS
export const SHOULD_FETCH_NOTIFICATIONS = "SHOULD_FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_IS_LOADING = "FETCH_NOTIFICATIONS_IS_LOADING";
export const FETCH_NOTIFICATIONS_IN_PROGRESS = "FETCH_NOTIFICATIONS_IN_PROGRESS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";
export const FETCH_NOTIFICATIONS_DONE = "FETCH_NOTIFICATIONS_DONE";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";

export const setShouldFetchNotifications = (dispatch, bool) =>
  dispatch({ type: SHOULD_FETCH_NOTIFICATIONS, payload: bool });

export const setfetchNotificationsIsLoading = (dispatch, bool) =>
  dispatch({ type: FETCH_NOTIFICATIONS_IS_LOADING, payload: bool });

export const setfetchNotificationsInProgress = (dispatch, obj) =>
  dispatch({ type: FETCH_NOTIFICATIONS_IN_PROGRESS, payload: obj });

export const setfetchNotificationsSuccess = (dispatch, bool) =>
  dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: bool });

export const setfetchNotificationsError = (dispatch, arr) =>
  dispatch({ type: FETCH_NOTIFICATIONS_ERROR, payload: arr });

export const setfetchNotificationsDone = (dispatch, bool) =>
  dispatch({ type: FETCH_NOTIFICATIONS_DONE, payload: bool });

export const setNotificationCount = (dispatch, num) =>
  dispatch({type: SET_NOTIFICATION_COUNT, payload: num });

//ANNOUNCEMENTS BELOW