export const SET_NOTIFICATION_FILTER_TYPE = "SET_NOTIFICATION_FILTER_TYPE";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

export const RESLIMIT_SET = "RESLIMIT_SET";
export const RESULT_COUNT_SET = "RESULT_COUNT_SET";
export const SET_OFFSET = "SET_OFFSET";
export const CURRENT_PAGE_SET = "CURRENT_PAGE_SET";

export const ORDERING_BY_SET = "ORDERING_BY_SET";
export const ORDERING_DIR_SET = "ORDERING_DIR_SET";
export const SET_FILTER_PARAM_LIST = "SET_FILTER_PARAM_LIST";

export const SET_NOTIFICATION_READUNREAD_TOGGLE =
  "SET_NOTIFICATION_READUNREAD_TOGGLE";

export const SET_NOTIFICATION_READUNREAD_BUTTON_TOGGLE =
  "SET_NOTIFICATION_READUNREAD_BUTTON_TOGGLE";

export const SET_SHOULD_FETCH_NOTIFICATIONS = "SET_SHOULD_FETCH_NOTIFICATIONS";
export const SET_SHOULD_FETCH_NOTIFICATIONS_FILTERS = "SET_SHOULD_FETCH_NOTIFICATIONS_FILTERS";

export const SET_PAGE_IS_SEEN = "SET_PAGE_IS_SEEN";

export const setShouldFetchNotifications = (dispatch, bool) =>
  dispatch({ type: SET_SHOULD_FETCH_NOTIFICATIONS, payload: bool });

export const setShouldFetchNotificationFilters = (dispatch, bool) =>
  dispatch({ type: SET_SHOULD_FETCH_NOTIFICATIONS_FILTERS, payload: bool });

export const setPageIsSeen = (dispatch, bool) =>
  dispatch({ type: SET_PAGE_IS_SEEN, payload: bool });

export const setNotificationFilterType = (dispatch, arr) =>
  dispatch({ type: SET_NOTIFICATION_FILTER_TYPE, payload: arr });

export const setNotifications = (dispatch, arr) =>
  dispatch({ type: SET_NOTIFICATIONS, payload: arr });

export const setResetLimit = (dispatch, num) =>
  dispatch({ type: RESLIMIT_SET, payload: num });

export const setResultCount = (dispatch, num) =>
  dispatch({ type: RESULT_COUNT_SET, payload: num });

export const setCurrentPage = (dispatch, num) =>
  dispatch({ type: CURRENT_PAGE_SET, payload: num });

export const setOrderingBy = (dispatch, str) =>
  dispatch({ type: ORDERING_BY_SET, payload: str });

export const setOrderingDirection = (dispatch, str) =>
  dispatch({ type: ORDERING_DIR_SET, payload: str });

export const setFilterParamsList = (dispatch, str) =>
  dispatch({ type: SET_FILTER_PARAM_LIST, payload: str });

export const setOffset = (dispatch, str) =>
  dispatch({ type: SET_OFFSET, payload: str });

export const setGetReadUnReadToggle = (dispatch, bool) =>
  dispatch({ type: SET_NOTIFICATION_READUNREAD_TOGGLE, payload: bool });

export const setGetReadUnReadButtonToggle = (dispatch, bool) =>
  dispatch({ type: SET_NOTIFICATION_READUNREAD_BUTTON_TOGGLE, payload: bool });
