import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import NotificationsItemtype from "./NotificationsItemType";
import NotificationAvatar from "./NotificationAvatar";
import FormatISODateTime from "../../../../../../../global/FormatISODateTime";
import Colors from "../../../../../../../global/Colors";
import NotificationTooltip from "./NotificationTooltip";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import DataContext from "../../../../../../../../context/DataContext";
import { setShouldFetchNotificationFilters, setShouldFetchNotifications } from "../../state/actions";
import { NotificationPageDispatchContext } from "../../context/NotificationsPageProvider";
import ApiServices from "../../../../../../../api/service";

const NotificationsItem = ({ item }) => {
  const dispatch = useContext(NotificationPageDispatchContext);
  const navigate = useNavigate();
  const { accessToken } = useContext(DataContext);
  const [checkedStatus, setCheckedStatus] = useState(
    item.read_time ? false : true
  );

  const getCaseId = (related, itemId) => {
    if (checkedStatus) {
      let caseId = related.filter((item) => item.type === "case");
      if (caseId) {
        ApiServices.postData(
          `api/core/notifications/read/`,
          [itemId],
          accessToken
        )
          .then((res) => {
            setShouldFetchNotifications(dispatch, true);
            return res;
          })
          .catch((err) => console.log(err));
      }
      navigate("/client-detail/" + caseId[0].id);
    } else {
      ApiServices.postData(
        `api/core/notifications/unread/`,
        [itemId],
        accessToken
      )
        .then((res) => {
          setCheckedStatus(true);
          setShouldFetchNotificationFilters(dispatch, true);
          return res;
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <ListItem
      alignItems="flex-start"
      sx={{ borderBottom: "solid #eee 1px" }}
      className={`my-3 ${
        item.seen_time ? "bg-white" : "bg-blue-50"
      } cursor-pointer hover:bg-gray-100 transition-all duration-200`}
      onClick={() => getCaseId(item?.related_to, item.id)}
    >
      <ListItemAvatar className="w-[10%]">
        <NotificationAvatar item={item} />
      </ListItemAvatar>
      <ListItemText
        className="w-[84%]"
        primary={
          <p>
            <span className="font-bold mr-1">{item?.sender?.name}</span>
            <span className="mr-1">{item?.display_text}</span>
            <NotificationTooltip
              title={FormatISODateTime(item?.notification_sent)}
            >
              <Typography
                component="span"
                className="text-gray-500 hover:text-blue-500"
              >
                {item?.relative_notification_sent}
              </Typography>
            </NotificationTooltip>
          </p>
        }
        secondary={
          item?.related_to.length > 0 &&
          item?.related_to.map((related) => (
            <NotificationsItemtype item={related} />
          ))
        }
      />
      <ListItem className="w-[6%] hover:text-blue-50">
        <Radio
          checked={checkedStatus}
          // onChange={handleChange}
          value="read"
          name="radio-buttons"
          inputProps={{ "aria-label": "Mark Read/UnRead" }}
          sx={{
            color: Colors.blue[500],
            "&.Mui-checked": {
              color: Colors.blue[500],
            },
            "& .MuiSvgIcon-root": {
              fontSize: 18,
            },
          }}
        />
      </ListItem>
    </ListItem>
  );
};
export default NotificationsItem;
