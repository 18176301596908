import { Tabs, Box, Tab } from "@mui/material";
import PropTypes from "prop-types";

const DEFAULT_SX = {
  "& button.Mui-selected": {
    color: `#9333EA`,
    backgroundColor: "#FFF",
    borderRadius: "10px 10px 0 0",
    border: `1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
    borderBottom: `none`,
  },
  "& .Mui-selected": {
    color: `#9333EA`,
    backgroundColor: "#FFF",
    borderRadius: "10px 10px 0 0",
  },
  "& .MuiTab-root": {
    borderBottom: `1px solid rgb(203 213 225 / var(--tw-border-opacity, 1))`,
    fontWeight: "700",
  },
  "& .MuiBox-root.div": {
    padding: 0,
    color: "#000",
  },
  "& .MuiTab-textColorPrimary": {
    color: "#000",
  },
  "& .Mui-disabled": {
    color: "#ccc",
  },
};

export default function TabHeader({
  tabList,
  tabValue,
  handleChange,
  boxBackgroundColor = "#f0f0f0",
  tabVariant = "fullWidth",
  tabIndicatorSx = { backgroundColor: "transparent" },
  tabsSx = DEFAULT_SX,
}) {
  return (
    <Box
      className="justify-between rounded-tl-lg rounded-tr-lg"
      sx={{ padding: "0", backgroundColor: boxBackgroundColor }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        className="font-bold"
        variant={tabVariant}
        TabIndicatorProps={{ sx: tabIndicatorSx }}
        sx={tabsSx}
      >
        {tabList.map((tab, index) => {
          return (
            <Tab
              key={tab.id + "_" + index}
              label={tab.name}
              value={index}
              disabled={tab.isDisabled}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

TabHeader.propTypes = {
  tabList: PropTypes.array,
  tabValue: PropTypes.number,
  handleChange: PropTypes.func,
  boxBackgroundColor: PropTypes.string,
  tabVariant: PropTypes.string,
  tabIndicatorSx: PropTypes.object,
  tabsSx: PropTypes.object,
};
