import React from "react";

export default function AccordionSummary({
  accordionItem,
  expandAccordion,
  setExpandAccordion,
  icons,
  summaryContent,
  children,
}) {
  const bgSummaryColor = accordionItem?.bgSummaryColor || "bg-white";
  const SummaryContentVarianat = summaryContent;

  const handleAccordionExpansion = () => {
    setExpandAccordion(!expandAccordion);
  };
  return (
    <div
      className={`${
        expandAccordion ? bgSummaryColor : "bg-white"
      }  w-full shadow-md mb-4 p-4 rounded`}
    >
      <div
        className={`w-full flex flex-row justify-between items-center hover:cursor-pointer`}
        onClick={handleAccordionExpansion}
      >
        <SummaryContentVarianat
          accordionItem={accordionItem}
        ></SummaryContentVarianat>
        <div>{!expandAccordion ? icons?.shrinkIcon : icons?.expandIcon}</div>
      </div>
      {!expandAccordion && accordionItem
        ? React.cloneElement(children, {
            accordionItem: accordionItem,
          })
        : ""}
    </div>
  );
}
