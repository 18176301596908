import { useContext, useState } from "react";
import {
  ProviderListDispatchContext,
  ProviderListStateContext,
} from "../../../context/ProviderListProvider";
import { setProvidersData, setStatuses } from "../../../state/actions";
import TreatmentStatus from "./components/TreatmentStatus";
import FieldInfo from "../../../components/FieldInfo";
import getUpdatedString from "../../../components/utils/getUpdatedString";
import DocumentStatus from "./components/DocumentStatus";

export default function Status({
  currentAccordionItem,
  activateButtons,
  deactivateButtons,
}) {
  const statusDispatch = useContext(ProviderListDispatchContext);
  const index = currentAccordionItem.index;
  const proCliData = currentAccordionItem.proCliData;

  const updateProviderInfo = (updatedData = null) => {
    if (!updatedData) {
      deactivateButtons();
      return;
    }
    if (updatedData.get(index).editableFields === null) {
      deactivateButtons();
    } else {
      activateButtons();
    }
    if (updatedData !== null) {
      setProvidersData(statusDispatch, updatedData);
    }
  };

  return (
    <div id="Provider-Statuses" data-testid="Provider-Statuses">
      <div className="flex flex-row justify-start w-full mt-4 flex-wrap">
        <div className="w-1/2 flex flex-row justify-start p-0 m-0">
          <div>
            <TreatmentStatus
              canEdit={proCliData?.can_edit}
              title={"Treatment"}
              index={index}
              fieldName={"treatmentStatus"}
              initialValue={proCliData?.finished_treatment}
              updateProviderInfo={updateProviderInfo}
            />
            <FieldInfo
              title={"Updated"}
              value={getUpdatedString(
                proCliData?.updated_by_name,
                proCliData?.updated_at
              )}
            />
          </div>
        </div>

        <div className="w-1/2 flex flex-row justify-start p-0 m-0">
          <div>
            <DocumentStatus
              canEdit={proCliData?.can_edit}
              title={"Document"}
              fieldName={"documentStatus"}
              index={index}
              initialValue={proCliData?.medical_documents_status}
              updateProviderInfo={updateProviderInfo}
            />
            <FieldInfo
              title={"Updated"}
              value={getUpdatedString(
                proCliData?.updated_by_name,
                proCliData?.updated_at
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
