import {
  CURRENT_PAGE_SET,
  CURRENT_PAGE_URL_SET,
  RESULT_COUNT_SET,
  SEARCH,
} from "./actions";

const DEFAULT_PAGE_LIMIT = 25;

export const initialState = {
  search: null,
  resLimit: DEFAULT_PAGE_LIMIT,
  currentPage: 1,
  paginationUrl: `limit=${DEFAULT_PAGE_LIMIT}&`,
  resultCount: { total: "", current: "" },
};
export function pmReducer(state, action) {
  switch (action.type) {
    case CURRENT_PAGE_SET: {
      return { ...state, currentPage: action.payload };
    }
    case CURRENT_PAGE_URL_SET: {
      return { ...state, paginationUrl: action.payload };
    }
    case RESULT_COUNT_SET: {
      return { ...state, resultCount: action.payload };
    }
    case SEARCH: {
      return { ...state, search: action.payload };
    }
    default:
      return state;
  }
}
