import { DesktopDatePicker } from "@mui/x-date-pickers";
import { inputStyles, selectStyles } from "../FullPages/constant";
import { CalendarMonth, ClearOutlined } from "@mui/icons-material";
import { IconButton, MenuItem, Select } from "@mui/material";
import { forwardRef, useId, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";

const RescheduleComponent = ({ onDelete, canDeleteRow }, ref) => {
  const scheduledRef = useRef();
  const sessionRef = useRef();
  const id = useId();

  const TIME_TRACKS = [
    {
      key: "morning",
      value: "Morning | 8am - 12pm",
      label: "Morning | 8am - 12pm",
      select: "Morning | 8am - 12pm",
    },
    {
      key: "afternoon",
      value: "Afternoon | 12pm - 3pm",
      label: "Afternoon | 12pm - 3pm",
      select: "Afternoon | 12pm - 3pm",
    },
    {
      key: "evening",
      value: "Evening | 3pm - 6pm",
      label: "Evening | 3pm - 6pm",
      select: "Evening | 3pm - 6pm",
    },
  ];

  const currentDate = new Date();

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      scheduled: scheduledRef.current.value,
      session: sessionRef.current.value,
    }),
  }));

  return (
    <div className="flex gap-2 items-center mt-4 w-full">
      <DesktopDatePicker
        closeOnSelect
        inputRef={scheduledRef}
        inputFormat="DD/MM/YYYY"
        className="w-2/5 md:w-1/2"
        minDate={currentDate}
        sx={{
          ...inputStyles,
          minWidth: "100px",
          width: "100%",
          "& input": {
            marginLeft: "-8px",
            "@media (max-width: 640px)": {
              fontSize: "0.75rem",
            },
          },
          "& input::placeholder": {
            "@media (max-width: 640px)": {
              fontSize: "10px",
            },
          },
          "& input:focus": {
            "@media (max-width: 640px)": {
              fontSize: "10px",
            },
          },
          "& .MuiOutlinedInput-root": {
            ...inputStyles["& .MuiOutlinedInput-root"],
            "& fieldset": {
              borderColor: "#9ca3af",
            },
          },
        }}
        slots={{
          OpenPickerIcon: CalendarMonth,
        }}
        slotProps={{
          textField: { variant: "outlined" },
          inputAdornment: {
            position: "start",
          },
        }}
      />
      <Select
        id={`${id}_status`}
        inputRef={sessionRef}
        defaultValue={TIME_TRACKS[0].value}
        displayEmpty
        className="w-3/5 md:w-1/2"
        renderValue={(value) =>
          value ? (
            TIME_TRACKS.find((s) => s.value === value).label
          ) : (
            <span className="text-gray-400">Appointment Status</span>
          )
        }
        sx={{
          ...selectStyles,
          minWidth: "100px",
          width: "100%",
          "& .MuiSelect-select": {
            textAlign: "left !important",
            "@media (max-width: 640px)": {
              fontSize: "0.75rem",
            },
          },
          "& fieldset": {
            borderColor: "#9ca3af",
          },
        }}
      >
        {TIME_TRACKS.map((status) => (
          <MenuItem key={status.key} value={status.value}>
            {status.select}
          </MenuItem>
        ))}
      </Select>
      {canDeleteRow && (
        <IconButton
          color="error"
          className="h-fit m-auto"
          onClick={onDelete}
          size="small"
        >
          <ClearOutlined />
        </IconButton>
      )}
    </div>
  );
};

RescheduleComponent.propTypes = {
  onDelete: PropTypes.func,
  canDeleteRow: PropTypes.bool,
  ref: PropTypes.object,
};

export default forwardRef(RescheduleComponent);
