import NotificationsPageProvider from "./context/NotificationsPageProvider";
import NotificationsPageWithState from "./NotificationPageWithState";

const NotificationsPage = () => {
  return (
    <NotificationsPageProvider>
      <NotificationsPageWithState />
    </NotificationsPageProvider>
  );
};

export default NotificationsPage;
