import ApiServices from "../service";

export const document = {
  getSourceFieldOptions: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=document&field_name=source&ordering=key`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

  getDocumentTypes: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=document&field_name=document_type&limit=100`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

  getDocumentTypesValidation: (accessToken) =>
    ApiServices.getData(`/api/documents/validate/`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

};
