import TooltipLineItem from "./TooltipLineItem";
import FormatISODateTime from "../../../../../../../global/FormatISODateTime";
import FormatISODate from "../../../../../../../global/FormatISODate";
import TooltipTitle from "./TooltipTitle";

export default function TooltipContentCase({ item }) {
  return (
    <div id={`Tooltip-Case-` + item.id}>
      <TooltipTitle value={item.client_name} />
      <hr />
      <TooltipLineItem
        label={"Case Created:"}
        value={FormatISODateTime(item.created_at, false)}
      />
      <TooltipLineItem
        label={"Date of Accident:"}
        value={FormatISODate(item.date_of_accident, false)}
      />
      <TooltipLineItem label={"Medical Status:"} value={item.status} />
      <TooltipLineItem label={"Running Total:"} value={item.running_total} />
      <TooltipLineItem label={"Case Status:"} value={item.overall_status} />
    </div>
  );
}
