import { createContext, useReducer } from "react";
import {
  initialState,
  notificationPageReducer,
} from "../state/notificationPageReducer";

export const NotificationPageDispatchContext = createContext();
export const NotificationPageStateContext = createContext();

const NotificationsPageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationPageReducer, initialState);

  return (
    <NotificationPageDispatchContext.Provider value={dispatch}>
      <NotificationPageStateContext.Provider value={state}>
        {children}
      </NotificationPageStateContext.Provider>
    </NotificationPageDispatchContext.Provider>
  );
};

export default NotificationsPageProvider;
