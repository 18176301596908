import { Typography } from "@mui/material";

export default function Title({
  text,
  size = "text-xl",
  color = "text-black",
  textCase = "normal-case",
  fontWeight = "font-bold",
  fontStyle = "",
  horizontalPadding = "",
  verticalPadding = "",
}) {
  return (
    <Typography
      className={`${size} ${color} ${textCase} ${fontWeight} ${fontStyle} ${horizontalPadding} ${verticalPadding}`}
    >
      {text}
    </Typography>
  );
}
