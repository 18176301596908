import React from "react";

import ProviderDetailContent from "./ProviderDetail";
import ProviderDetailProvider from "./context/ProviderDetailProvider";

const ProviderDetail = () => {
  return (
    <ProviderDetailProvider>
      <ProviderDetailContent />
    </ProviderDetailProvider>
  );
};

export default ProviderDetail;
