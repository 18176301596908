const STRINGS = {
  NULL: "No Selection",
  EMPTY: "",
  NA: "N/A",
  NO_FOLLOW_UPS_AVAILABLE: "No Follow Ups. Please add",
  DIALOG_HEADER_CREATE_FOLLOWUP: "Create a new Follow Up",
  DIALOG_HEADER_EDIT_FOLLOWUP: "Edit an existing Follow Up",
  DIALOG_HEADER_FOLLOWUP_LIST: "Follow Up List",
  FORM_DUE_DATE_HELPERTEXT:
    "This field is required. Please enter a valid date.",
  FIELD_LABEL_DUE_DATE: "Due Date",
  FIELD_LABEL_REASON: "Follow-up Details",
  FIELD_PLACEHOLDER_REASON: "Please enter follow-up details.",
  FIELD_HELPERTEXT_REASON:
    "This field is required. Please enter a valid check number containing only numbers.",
  FOLLOW_UP_LIST_TITLE: "Follow Up History",
  SCHEDULED_FOR: "Scheduled Follow Up for",
};

export default STRINGS;
