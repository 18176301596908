import useLogout from "../../../../hooks/useLogout";
import SubMenu from "./SubMenu/components/SubMenu";
import { useNavigate } from "react-router-dom";

export default function RenderSubMenu() {
  const navigate = useNavigate();
  const logout = useLogout();
  const subMenuList = [];

  const handleNavigate = (link) => {
    navigate(link);
  };

  subMenuList.push({
    menu: (
      <SubMenu title="My Account" onclick={handleNavigate} link="/my-account" />
    ),
  });

  subMenuList.push({
    menu: <SubMenu title="Logout" onclick={logout} />,
  });

  return subMenuList;
}
